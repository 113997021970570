import React, { useState, useEffect } from "react";

import { useStyles } from "../containers/style.js";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Backdrop,
  Typography,
  DialogActions,
  InputAdornment,
  Divider,
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import {
  Button1,
  IconButton1,
  OutlinedInput1,
  ConfirmContentsHypeDetailDialog,
} from ".";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import {
  onHyypeRenderer,
  onDateCellRenderer,
  onOldDataRenderer,
} from "../containers/utils";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content1: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#565656",
    padding: "20px 0px 20px",
  },
  content2: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    border: "1px solid #999999",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#999999",
  },
  label1: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#292929",
  },
  label2: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#3C8DBC",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#confirm-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ConfirmContentsDialog(props) {
  const header_s = headerStyles();
  const global_s = useStyles();
  const contents_s = contentStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(undefined);

  const [search, setSearch] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const [rows, setRows] = useState([]);
  const columnDef = [
    {
      field: "created_time",
      headerName: "수정일",
      width: 160,
      sortable: true,
      resizable: true,
      cellRenderer: onDateCellRenderer,
    },
    {
      field: "sort",
      headerName: "종류",
      width: 80,
      sortable: true,
      resizable: true,
    },
    {
      field: "title",
      headerName: "제목",
      tooltipField: "title",
      width: 240,
      sortable: true,
      resizable: true,
    },
    {
      field: "contents",
      headerName: "내용",
      tooltipField: "contents",
      width: 640,
      sortable: true,
      resizable: true,
    },
    {
      field: "hype",
      headerName: "과장성",
      width: 100,
      sortable: true,
      resizable: true,
      cellRenderer: onHyypeRenderer,
    },
  ];

  const [dataType, setDataType] = useState(false);
  const [hypeCount, setHypeCount] = useState(0);

  useEffect(() => {
    if (Array.isArray(props.rowData) && props.selected) {
      let hype_count = 0;

      let rowData = [...props.rowData];
      rowData = rowData.map((val) => {
        if (val.user_id === props.selected.user_id) {
          if (val.hype) hype_count++;
          return val;
        }
      });

      rowData = rowData.filter((val) => {
        if (val) return val;
      });

      setDataType(props.dataType);
      setHypeCount(hype_count);
      setRows(rowData);
    }
  }, []);

  useEffect(() => {
    if (selected) setIsOpen(true);
  }, [selected]);

  useEffect(() => {
    if (!isOpen) setSelected(undefined);
  }, [isOpen]);

  const databaseSend = (location, data, callback) => {
    props.databaseSend(location, data, callback);
  };

  const handleCancel = () => {
    props.onCancelConfirmDlg();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    gridApi.setQuickFilter(event.target.value);
  };

  const handleSearchClear = () => {
    setSearch("");
    gridApi.setQuickFilter("");
  };

  const onRowDoubleClicked = (params) => {
    if (params && params.data) setSelected(params.data);
  };

  const onCancelConfirmDlg = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      fullWidth={true}
      open={props.isOpen}
      onClose={handleCancel}
      PaperComponent={PaperComponent}
      aria-labelledby="confirm-dialog-title"
    >
      {isOpen && (
        <ConfirmContentsHypeDetailDialog
          isOpen={isOpen}
          dataType={dataType}
          selected={selected}
          cancelName={"확인"}
          databaseSend={databaseSend}
          onCancelConfirmDlg={onCancelConfirmDlg}
        />
      )}
      <DialogTitle className={header_s.titlebar} id="confirm-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "36px" }}
        >
          <Grid item xs={10} container direction="row" alignItems="center">
            <Typography className={header_s.title}>{props.title}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <IconButton1
              size="small"
              aria-label="close"
              onClick={handleCancel}
              style={{ color: "#ffffff" }}
            >
              <CloseIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: "0px 10px 10px" }}>
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            style={{ margin: "10px 0px 0px" }}
          >
            <Grid item xs container direction="row" alignItems="center">
              <Grid item>
                <Typography className={contents_s.label1}>
                  {"회원번호(이름) : "}
                </Typography>
              </Grid>
              {props.selected ? (
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography className={contents_s.label2}>
                    {props.selected.seller}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Grid
              item
              xs
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Typography className={contents_s.label1}>
                  {"조회 기간 : "}
                </Typography>
              </Grid>
              {props.s_datetime && props.e_datetime ? (
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography className={contents_s.label1}>
                    {`${props.s_datetime} ~ ${props.e_datetime}`}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs style={{ margin: "10px 0px 0px" }}>
            <Divider />
          </Grid>
          <Grid item xs style={{ margin: "10px 0px 0px" }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs container direction="row">
                <Grid item style={{ marginLeft: "20px" }}>
                  <Typography className={contents_s.label2}>
                    {`과장성 : ${hypeCount} 개`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs style={{ marginLeft: "100px", maxWidth: "500px" }}>
                <OutlinedInput1
                  value={search}
                  onChange={handleSearch}
                  placeholder={"조회"}
                  style={{
                    fontSize: 12,
                    padding: "5px",
                    maxWidth: "100%",
                    minWidth: "100%",
                    backgroundColor: "white",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {search.length > 0 ? ( // 입력 값의 길이에 따른 표시
                        <IconButton1 // 입력 값의 길이가 0보다 크다.
                          size="small"
                          onClick={handleSearchClear}
                        >
                          <CloseIcon
                            className={contents_s.intputIcon}
                            style={{ color: "#a9a9a9" }}
                          />
                        </IconButton1>
                      ) : (
                        <SearchIcon // 입력 값의 길이가 0보다 작거나 같다.
                          className={contents_s.intputIcon}
                          style={{ color: "#a9a9a9" }}
                        />
                      )}
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs style={{ margin: "10px 0px 0px" }}>
            <div
              className="ag-theme-alpine"
              style={{ height: "300px", width: "100%" }}
            >
              <AgGridReact
                headerHeight={36}
                rowData={rows}
                rowStyle={{ cursor: "pointer" }}
                suppressMovableColumns={true} // columns 이동 금지
                overlayLoadingTemplate={"<span></span>"} // 데이터 로딩시 표시 화면
                overlayNoRowsTemplate={"<span></span>"} // 데이터 없을 경우 표시 화면
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  wrapText: true,
                  autoHeight: true,
                  cellClass: global_s.defaultGridCell,
                  headerClass: global_s.defaultGridHeader,
                }}
                onGridReady={onGridReady}
                onRowDoubleClicked={onRowDoubleClicked}
              >
                {columnDef.map((columnItem) => {
                  return (
                    <AgGridColumn
                      key={columnItem.field}
                      headerName={columnItem.headerName}
                      field={columnItem.field}
                      hide={columnItem.hide}
                      width={columnItem.width}
                      tooltipField={columnItem.tooltipField}
                      checkboxSelection={columnItem.checkboxSelection}
                      valueGetter={columnItem.valueGetter}
                      cellRenderer={columnItem.cellRenderer}
                    />
                  );
                })}
              </AgGridReact>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      {/* <DialogActions>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button1 className={contents_s.btn} onClick={handleCancel}>
              <Typography className={contents_s.btn_label}>
                {props.cancelName}
              </Typography>
            </Button1>
          </Grid>
        </Grid>
      </DialogActions> */}
    </Dialog>
  );
}

export default ConfirmContentsDialog;
