import React, { useEffect, useState } from "react";
import { useStyles } from "../containers/style.js";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Paper,
  Backdrop,
  Typography,
  DialogActions,
  InputAdornment,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import Draggable from "react-draggable";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import {
  IconButton1,
  OutlinedInput1,
  Button1,
  StyledMenu2,
  StyledMenuItem,
} from ".";

import PanelGroup from "../lib/react-panelgroup/src";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import {
  onSentenceRenderer,
  onCellRenderer,
  onHyypeRenderer,
  onSentenceOrderRenderer,
} from "../containers/utils";

import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content1: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#565656",
    padding: "20px 0px 20px",
  },
  content2: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  defaultSize: {
    width: "100%",
    height: "100%",
  },
  btn: {
    width: "97px",
    height: "33px",
    border: "1px solid #999999",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#999999",
  },
  label1: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#292929",
  },
  label2: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#3C8DBC",
  },
  label3: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#3C8DBC",
  },
  back_caution: {
    padding: "5px",
    marginLeft: "20px",
    borderRadius: "10px",
    backgroundColor: "#FCFFB1",
  },
  back_combination: {
    padding: "5px",
    marginLeft: "20px",
    borderRadius: "10px",
    backgroundColor: "#FFDA84",
  },
  back_forbiden: {
    padding: "5px",
    marginLeft: "20px",
    borderRadius: "10px",
    backgroundColor: "#F6B2B2",
  },
  back_old_data: {
    padding: "5px",
    marginLeft: "20px",
    borderRadius: "10px",
    backgroundColor: "#BCBCBC",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  comboBoxRoot: {
    minHeight: "28px",
    width: "260px",
    margin: "0px",
    padding: "0px",
    background: "#ffffff",
    borderRadius: "3px",
    border: "1px solid #BCBCBC",
    justifyContent: "flex-start",
  },
  iconSize: {
    fontSize: "18px",
  },
  comboBoxLabel: {
    fontSize: "13px",
    fontFamily: "Noto Sans",
    color: "#656565",
    textAlign: "left",
    padding: "0px 10px 0px",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#confirm-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ConfirmContentsListDialog(props) {
  const header_s = headerStyles();
  const global_s = useStyles();
  const contents_s = contentStyles();

  let p_GridViewerHeight = 334;
  const [panelWidths, setPanelWidths] = useState([
    {
      maxSize: 1000,
      minSize: 200,
      resize: "dynamic",
      size: p_GridViewerHeight,
      snap: [],
      style: {},
    },
    {
      maxSize: 1000,
      minSize: 200,
      resize: "dynamic",
      size: 334,
      snap: [],
      style: {},
    },
  ]);
  p_GridViewerHeight = panelWidths[0].size;

  const [bannedSearch, setBannedSearch] = useState("");
  const [bannedRows, setBannedRows] = useState([]);
  const [bannedGridApi, setBannedGridApi] = useState(null);
  const bannedColumnDef = [
    {
      field: "sortLabel",
      headerName: "종류",
      width: 120,
      sortable: true,
      resizable: true,
    },
    {
      field: "banned",
      headerName: "단어",
      width: 980,
      sortable: true,
      resizable: true,
    },
    {
      field: "count",
      headerName: "횟수(개)",
      width: 120,
      sortable: true,
      resizable: true,
    },
  ];

  const [sentenceSearch, setSentenceSearch] = useState("");
  const [sentenceGridApi, setSentenceGridApi] = useState(null);
  const [sentenceRows, setSentenceRows] = useState([]);
  const sentenceColumnDef = [
    {
      field: "sentence_order",
      headerName: "No.",
      width: 80,
      sortable: true,
      resizable: true,
      cellRenderer: onSentenceOrderRenderer,
    },
    {
      field: "type",
      headerName: "위치",
      width: 120,
      sortable: true,
      resizable: true,
    },

    {
      field: "sentence",
      headerName: "문장",
      width: 780,
      sortable: true,
      resizable: true,
      cellRenderer: onSentenceRenderer,
    },
    {
      field: "hype",
      headerName: "과장성",
      width: 120,
      sortable: true,
      resizable: true,
      cellRenderer: onHyypeRenderer,
    },
    {
      field: "hype_value",
      headerName: "과장성 확률",
      width: 120,
      sortable: true,
      resizable: true,
    },
  ];

  const [isBackdrop, setIsBackdrop] = useState(false);
  const [contentsInfo, setContentsInfo] = useState(undefined);
  const [sentenceInfo, setSentenceInfo] = useState([]);
  const [bannedInfo, setBannedInfo] = useState([]);

  const [forbiddenCount, setForbiddenCount] = useState(0);
  const [cautionCount, setCautionCount] = useState(0);
  const [combinationCount, setCombinationCount] = useState(0);
  const [hypeCount, setHypeCount] = useState(0);

  const [updatedTime, setUpdatedTime] = useState();
  const [updatedTimeMenuRef, setUpdatedTimeMenuRef] = useState(null);
  const updatedTimeMenuOpen = Boolean(updatedTimeMenuRef);
  const [updatedTimeList, setUpdatedTimeList] = useState([]);

  useEffect(() => {
    if (props.selected) setContentsInfo(props.selected);
  }, []);

  useEffect(() => {
    if (contentsInfo) {
      if (!props.databaseSend) return;
      search_sentence();
    }
  }, [contentsInfo]);

  useEffect(() => {
    if (updatedTime) selected_data();
  }, [updatedTime]);

  const search_sentence = () => {
    setIsBackdrop(true);
    props.databaseSend(
      "api/dashboard/sentence",
      {
        isTest: props.dataType,
        contents_info: contentsInfo,
      },
      (res) => {
        if (!res || !res.sentence_list) return setIsBackdrop(false);

        if (!Array.isArray(res.sentence_list)) return setIsBackdrop(false);

        let sentence_info = [];
        let banned_info = [];
        let updated_time_list = [];
        res.sentence_list.forEach((sentence) => {
          let updated_time = moment
            .utc(sentence.updated_time)
            .local()
            .format("YYYY-MM-DD HH:mm:ss");

          let idx = updated_time_list.findIndex((val) => {
            if (val === updated_time) return val;
          });

          if (idx === -1) updated_time_list.push(updated_time);

          idx = sentence_info.findIndex((val) => {
            if (val.pkey === sentence.pkey) return val;
          });

          if (idx === -1) {
            sentence_info.push({
              pkey: sentence.pkey,
              type:
                contentsInfo.type !== 2
                  ? sentence.type !== 0
                    ? "게시글 > 내용"
                    : "게시글 > 제목"
                  : "댓글",
              sentence: sentence.sentence,
              sentence_order: sentence.sentence_order + 1,
              sentence_html: sentence.sentence_html,
              hype: sentence.hype,
              hype_value: sentence.hype_value ? sentence.hype_value + " %" : "",
              hype_standard: sentence.hype_standard,
              updated_time: updated_time,
            });
          }

          if (sentence.ca_word) {
            idx = banned_info.findIndex((val) => {
              if (
                val.updated_time === sentence.updated_time &&
                val.word1 === sentence.ca_word
              )
                return idx;
            });

            if (idx !== -1) banned_info[idx].count += sentence.ca_count;
            else
              banned_info.push({
                updated_time: updated_time,
                sort: 2,
                sortLabel: "주의어",
                standard: 0,
                word1: sentence.ca_word,
                banned: sentence.ca_word,
                count: sentence.ca_count,
              });
          }

          if (sentence.count && sentence.count > 0) {
            idx = banned_info.findIndex((val) => {
              if (
                val.updated_time === sentence.updated_time &&
                val.standard === sentence.standard &&
                val.word1 === sentence.word1 &&
                val.word2 === sentence.word2 &&
                val.word3 === sentence.word3 &&
                val.word4 === sentence.word4 &&
                val.word5 === sentence.word5
              )
                return idx;
            });

            if (idx !== -1) banned_info[idx].count += sentence.count;
            else {
              let banned = "";
              if (sentence.word1) banned += sentence.word1;
              if (sentence.word2)
                banned +=
                  banned.length > 0 ? `, ${sentence.word2}` : sentence.word2;
              if (sentence.word3)
                banned +=
                  banned.length > 0 ? `, ${sentence.word3}` : sentence.word3;
              if (sentence.word4)
                banned +=
                  banned.length > 0 ? `, ${sentence.word4}` : sentence.word4;
              if (sentence.word5)
                banned +=
                  banned.length > 0 ? `, ${sentence.word5}` : sentence.word5;

              banned_info.push({
                updated_time: updated_time,
                sort: 3,
                sortLabel: "조합어",
                standard: 0,
                word1: sentence.word1,
                word2: sentence.word2,
                word3: sentence.word3,
                word4: sentence.word4,
                word5: sentence.word5,
                banned: banned,
                count: sentence.count,
              });
            }
          }

          if (sentence.fo_word) {
            idx = banned_info.findIndex((val) => {
              if (
                val.updated_time === sentence.updated_time &&
                val.word1 === sentence.fo_word
              )
                return idx;
            });

            if (idx !== -1) banned_info[idx].count += sentence.fo_count;
            else
              banned_info.push({
                updated_time: updated_time,
                sort: 1,
                sortLabel: "금지어",
                standard: 0,
                word1: sentence.fo_word,
                banned: sentence.fo_word,
                count: sentence.fo_count,
              });
          }
        });

        updated_time_list = updated_time_list.sort().reverse();

        setSentenceInfo(sentence_info);
        setBannedInfo(banned_info);
        setUpdatedTimeList(updated_time_list);
        if (!updatedTime) setUpdatedTime(updated_time_list[0]);

        return setIsBackdrop(false);
      }
    );
  };

  const selected_data = () => {
    let hype_count = 0;
    let forbidden = 0;
    let caution = 0;
    let combination = 0;

    let banned_data = bannedInfo.filter((banned) => {
      if (banned.updated_time === updatedTime) {
        switch (banned.sort) {
          case 1:
            forbidden += banned.count;
            return banned;
          case 2:
            caution += banned.count;
            return banned;
          case 3:
            combination += banned.count;
            return banned;
          default:
            return banned;
        }
      }
    });

    let sentence_data = sentenceInfo.filter((sentence) => {
      if (sentence.updated_time === updatedTime) {
        if (sentence.hype) hype_count++;
        return sentence;
      }
    });

    setBannedRows(banned_data);
    setSentenceRows(sentence_data);
    setForbiddenCount(forbidden);
    setCautionCount(caution);
    setCombinationCount(combination);
    setHypeCount(hype_count);
  };

  const onResizeEnd = (panels) => {
    setPanelWidths(panels);
  };

  const onBannedGridReady = (params) => {
    setBannedGridApi(params.api);
  };

  const handleBannedSearch = (event) => {
    setBannedSearch(event.target.value);
    bannedGridApi.setQuickFilter(event.target.value);
  };

  const handleBannedSearchClear = () => {
    setBannedSearch("");
    bannedGridApi.setQuickFilter("");
  };

  const onSentenceGridReady = (params) => {
    setSentenceGridApi(params.api);
  };

  const handleSentenceSearch = (event) => {
    setSentenceSearch(event.target.value);
    sentenceGridApi.setQuickFilter(event.target.value);
  };

  const handleSentenceSearchClear = () => {
    setSentenceSearch("");
    sentenceGridApi.setQuickFilter("");
  };

  const handleCancel = () => {
    props.handleCancel();
  };

  const onSelectUpdatedTime = (updated_time) => {
    setUpdatedTimeMenuRef(undefined);
    setUpdatedTime(updated_time);
  };

  const handleUpdatedTime = (event) => {
    if (Array.isArray(updatedTimeList) && updatedTimeList.length > 0)
      setUpdatedTimeMenuRef(event.currentTarget);
  };

  const onCloseMenu = () => {
    setUpdatedTimeMenuRef(null);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      fullWidth={true}
      open={props.isOpen}
      onClose={handleCancel}
      PaperComponent={PaperComponent}
      aria-labelledby="confirm-dialog-title"
    >
      {isBackdrop && (
        <Backdrop className={contents_s.backdrop} open={isBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {updatedTimeMenuOpen && (
        <StyledMenu2
          anchorEl={updatedTimeMenuRef}
          keepMounted
          open={updatedTimeMenuOpen}
          onClose={() => onCloseMenu()}
          disableScrollLock={true}
          style={{ padding: 0, margin: 0, maxHeight: "250px" }}
        >
          {updatedTimeList.map((updated_time) => {
            return (
              <StyledMenuItem
                key={updated_time}
                selected={updatedTime === updated_time}
                onClick={() => onSelectUpdatedTime(updated_time)}
                style={{
                  minWidth: `${updatedTimeList.length < 6 ? "250px" : "220px"}`,
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <Typography className={contents_s.comboBoxLabel}>
                  {updated_time}
                </Typography>
              </StyledMenuItem>
            );
          })}
        </StyledMenu2>
      )}
      <DialogTitle className={header_s.titlebar} id="confirm-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "36px" }}
        >
          <Grid item xs={10} container direction="row" alignItems="center">
            <Typography className={header_s.title}>{props.title}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <IconButton1
              size="small"
              aria-label="close"
              onClick={handleCancel}
              style={{ color: "#ffffff" }}
            >
              <CloseIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: "0px 0px 10px" }}>
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ margin: "10px 0px 0px" }}
          >
            <Grid item xs container direction="row" alignItems="center">
              <Grid item style={{ marginLeft: "10px" }}>
                <Typography className={contents_s.label1}>
                  {"회원번호(이름) : "}
                </Typography>
              </Grid>
              {contentsInfo ? (
                <Grid item style={{ paddingLeft: "5px", marginRight: "10px" }}>
                  <Typography className={contents_s.label2}>
                    {`${contentsInfo.user_id}(${
                      contentsInfo.name ? contentsInfo.name : "-"
                    })`}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Grid
              item
              xs
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid item style={{ paddingLeft: "5px", marginRight: "10px" }}>
                <Button1
                  id="updated-time-combo"
                  className={contents_s.comboBoxRoot}
                  aria-controls="updated-time-menu"
                  aria-haspopup="true"
                  onClick={handleUpdatedTime}
                  endIcon={
                    <ArrowDropDownIcon className={contents_s.iconSize} />
                  }
                >
                  <Typography
                    className={contents_s.comboBoxLabel}
                    noWrap={true}
                    style={{ width: "85%" }}
                  >
                    {`반영 일자 : ${updatedTime ? updatedTime : ""}`}
                  </Typography>
                </Button1>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs style={{ margin: "10px 0px 0px" }}>
            <Divider />
          </Grid>
          <div className={contents_s.defaultSize}>
            <PanelGroup
              direction="column"
              panelWidths={panelWidths}
              onResizeEnd={onResizeEnd}
            >
              <div
                className={contents_s.defaultSize}
                style={{
                  overflow: "auto",
                  borderBottom: "1px solid #e9e9e9",
                }}
              >
                <Grid
                  className={contents_s.defaultSize}
                  container
                  direction="column"
                  style={{ padding: "10px" }}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    style={{ paddingBottom: "5px" }}
                  >
                    <Grid item xs container direction="row" alignItems="center">
                      <Grid item>
                        <Typography className={contents_s.label1}>
                          {"금칙어 정보"}
                        </Typography>
                      </Grid>
                      <Grid item className={contents_s.back_forbiden}>
                        <Typography className={contents_s.label3}>
                          {`금지어 : ${forbiddenCount} 개`}
                        </Typography>
                      </Grid>
                      <Grid item className={contents_s.back_caution}>
                        <Typography className={contents_s.label3}>
                          {`주의어 : ${cautionCount} 개`}
                        </Typography>
                      </Grid>
                      <Grid item className={contents_s.back_combination}>
                        <Typography className={contents_s.label3}>
                          {`조합어 : ${combinationCount} 개`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs
                      style={{ marginLeft: "100px", maxWidth: "500px" }}
                    >
                      <OutlinedInput1
                        value={bannedSearch}
                        onChange={handleBannedSearch}
                        placeholder={"조회"}
                        style={{
                          fontSize: 12,
                          padding: "5px",
                          maxWidth: "100%",
                          minWidth: "100%",
                          backgroundColor: "white",
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            {bannedSearch.length > 0 ? ( // 입력 값의 길이에 따른 표시
                              <IconButton1 // 입력 값의 길이가 0보다 크다.
                                size="small"
                                onClick={handleBannedSearchClear}
                              >
                                <CloseIcon
                                  className={contents_s.intputIcon}
                                  style={{ color: "#a9a9a9" }}
                                />
                              </IconButton1>
                            ) : (
                              <SearchIcon // 입력 값의 길이가 0보다 작거나 같다.
                                className={contents_s.intputIcon}
                                style={{ color: "#a9a9a9" }}
                              />
                            )}
                          </InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs style={{ marginTop: "5px" }}>
                    <div
                      className="ag-theme-alpine"
                      style={{ height: "100%", width: "100%" }}
                    >
                      <AgGridReact
                        headerHeight={36}
                        rowData={bannedRows}
                        suppressMovableColumns={true} // columns 이동 금지
                        overlayLoadingTemplate={"<span></span>"} // 데이터 로딩시 표시 화면
                        overlayNoRowsTemplate={"<span></span>"} // 데이터 없을 경우 표시 화면
                        defaultColDef={{
                          sortable: true,
                          resizable: true,
                          wrapText: true,
                          autoHeight: true,
                          cellClass: global_s.defaultGridCell,
                          headerClass: global_s.defaultGridHeader,
                        }}
                        onGridReady={onBannedGridReady}
                      >
                        {bannedColumnDef.map((columnItem) => {
                          return (
                            <AgGridColumn
                              key={columnItem.field}
                              headerName={columnItem.headerName}
                              field={columnItem.field}
                              hide={columnItem.hide}
                              width={columnItem.width}
                              tooltipField={columnItem.tooltipField}
                              checkboxSelection={columnItem.checkboxSelection}
                              valueGetter={columnItem.valueGetter}
                              cellRenderer={columnItem.cellRenderer}
                            />
                          );
                        })}
                      </AgGridReact>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div
                className={contents_s.defaultSize}
                style={{
                  overflow: "auto",
                }}
              >
                <Grid
                  className={contents_s.defaultSize}
                  container
                  direction="column"
                  style={{ padding: "10px 10px 0px" }}
                >
                  <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    style={{ paddingBottom: "5px" }}
                  >
                    <Grid item xs container direction="row" alignItems="center">
                      <Grid item>
                        <Typography className={contents_s.label1}>
                          {"문장 정보"}
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginLeft: "10px" }}>
                        <Typography className={contents_s.label2}>
                          {"과장성 기준 : "}
                        </Typography>
                      </Grid>
                      <Grid item style={{ paddingLeft: "5px" }}>
                        <Typography className={contents_s.label2}>
                          {Array.isArray(sentenceRows) &&
                          sentenceRows.length > 0
                            ? `${sentenceRows[0].hype_standard} %`
                            : "0 %"}
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginLeft: "20px" }}>
                        <Typography className={contents_s.label2}>
                          {`과장성 : ${hypeCount} 개`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs
                      style={{ marginLeft: "100px", maxWidth: "500px" }}
                    >
                      <OutlinedInput1
                        value={sentenceSearch}
                        onChange={handleSentenceSearch}
                        placeholder={"조회"}
                        style={{
                          fontSize: 12,
                          padding: "5px",
                          maxWidth: "100%",
                          minWidth: "100%",
                          backgroundColor: "white",
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            {sentenceSearch.length > 0 ? ( // 입력 값의 길이에 따른 표시
                              <IconButton1 // 입력 값의 길이가 0보다 크다.
                                size="small"
                                onClick={handleSentenceSearchClear}
                              >
                                <CloseIcon
                                  className={contents_s.intputIcon}
                                  style={{ color: "#a9a9a9" }}
                                />
                              </IconButton1>
                            ) : (
                              <SearchIcon // 입력 값의 길이가 0보다 작거나 같다.
                                className={contents_s.intputIcon}
                                style={{ color: "#a9a9a9" }}
                              />
                            )}
                          </InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs style={{ marginTop: "5px" }}>
                    <div
                      className="ag-theme-alpine"
                      style={{ height: "100%", width: "100%" }}
                    >
                      <AgGridReact
                        headerHeight={36}
                        rowData={sentenceRows}
                        suppressMovableColumns={true} // columns 이동 금지
                        overlayLoadingTemplate={"<span></span>"} // 데이터 로딩시 표시 화면
                        overlayNoRowsTemplate={"<span></span>"} // 데이터 없을 경우 표시 화면
                        defaultColDef={{
                          sortable: true,
                          resizable: true,
                          wrapText: true,
                          autoHeight: true,
                          cellClass: global_s.defaultGridCell,
                          headerClass: global_s.defaultGridHeader,
                        }}
                        onGridReady={onSentenceGridReady}
                        onColumnResized={(params) => {
                          params.api.resetRowHeights();
                        }}
                      >
                        {sentenceColumnDef.map((columnItem) => {
                          return (
                            <AgGridColumn
                              key={columnItem.field}
                              headerName={columnItem.headerName}
                              field={columnItem.field}
                              hide={columnItem.hide}
                              width={columnItem.width}
                              tooltipField={columnItem.tooltipField}
                              sortable={columnItem.sortable}
                              resizable={columnItem.resizable}
                              checkboxSelection={columnItem.checkboxSelection}
                              valueGetter={columnItem.valueGetter}
                              cellRenderer={columnItem.cellRenderer}
                            />
                          );
                        })}
                      </AgGridReact>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </PanelGroup>
          </div>
        </Grid>
      </DialogContent>
      {/* <DialogActions>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button1 className={contents_s.btn} onClick={handleCancel}>
              <Typography className={contents_s.btn_label}>
                {props.cancelName}
              </Typography>
            </Button1>
          </Grid>
        </Grid>
      </DialogActions> */}
    </Dialog>
  );
}

export default ConfirmContentsListDialog;
