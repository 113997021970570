import produce from "immer";

const auth = {
  user_info: {
    user_id: "",
    name: "",
    email: "",
    auth_type: -1,
    description: "",
  },
  alarm_info: [],
  designated_info: [],
  isLogin: false,
  isDesignated: false,
};

export default function header(state = auth, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "AUTH_LOGOUT_SUCCESS":
        draft.isLogin = false;
        draft.user_info = action.user_info;
        return draft;
      case "AUTH_LOGIN_SUCCESS":
        draft.isLogin = true;
        draft.user_info = action.user_info;
        return draft;
      case "AUTH_DESIGNATED":
        draft.designated_info = action.designated_info;
        return draft;
      case "AUTH_IS_DESIGNATED":
        draft.isDesignated = action.status;
        return draft;
      case "AUTH_ALARM":
        draft.alarm_info = action.alarm_info;
        return draft;
      default:
        break;
    }
  });
}
