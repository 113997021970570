import { request } from "../utils/axios";

export const SendQuery = (url, user, data, callback) => {
  return request("post", url, { user, data })
    .then((res) => {
      return callback(null, res);
    })
    .catch((err) => {
      return callback(err);
    });
};

