import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "../../../style.js";

import { Grid, Backdrop, Typography, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button1,
  IconButton1,
  OutlinedInput1,
  ConfirmDialog,
} from "../../../../components";

import { handleSearch, wordValidator, beforeChange } from "../../../utils";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.css";

import moment from "moment-timezone";
import { SendQuery } from "../../../../actions/send";
import { LogOut } from "../../../../actions/auth";

import "../index.css";
import * as _ from "lodash";
import * as excel from "exceljs";

const contentStyles = makeStyles((theme) => ({
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#999999",
    margin: "3px 8px",
  },
  refreshLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  menuBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#62A4BC",
    "&:hover": {
      backgroundColor: "#62A4BC",
    },
    "&:disabled": {
      backgroundColor: "#999999",
    },
  },
  csvLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  editLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  editBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#62A4BC",
    "&:hover": {
      backgroundColor: "#62A4BC",
    },
  },
  saveBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#00CC00",
    "&:hover": {
      backgroundColor: "#009C00",
    },
  },
  disableCell: {
    color: "#ffffff",
    backgroundColor: "#a9a9a9",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let hotTableRef = undefined;
let hiddenFileInput = undefined;
function ForbiddenManagement(props) {
  const dispatch = useDispatch();
  const global_s = useStyles();
  const contents_s = contentStyles();

  const bannedURL = "api/administer/banned";
  const [csvLabel, setCSVLabel] = useState("내보내기");
  const [editLabel, setEditLabel] = useState("편집 시작");
  const [isEdit, setIsEdit] = useState(false);
  const [search, setSearch] = useState("");

  const tableHeaders = [
    "고유키",
    "등록일",
    "등록 아이디",
    "수정일",
    "수정 아이디",
    "단어",
  ];
  const [tableData, setTableData] = useState([]);
  const [tableSettings, setTableSettings] = useState({
    colHeaders: tableHeaders,
    colWidths: 170,
    autoWrapRow: true,
    columnSorting: true,
    manualRowResize: true,
    manualColumnResize: true,
    stretchH: "last",
    className: "htCenter htMiddle",
    licenseKey: "f828d-946f3-731a2-84125-6903b",
    hiddenColumns: {
      columns: [0],
    },
    hiddenRows: {
      copyPasteEnabled: true,
    },
    search: {
      callback: handleSearch,
    },
    cells: (row, col) => {
      const cellProperties = {};
      if (col < 5) cellProperties.className = "disable-cell";
      return cellProperties;
    },
  });

  const [isOkCancelOpen, setIsOkCancelOpen] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);

  if (!hotTableRef) hotTableRef = React.createRef();

  if (!hiddenFileInput) hiddenFileInput = React.createRef();

  if (props.getSidebarState() !== openSidebar)
    setOpenSidebar(props.getSidebarState());

  useEffect(() => {
    databaseSearch();
  }, []);

  useEffect(() => {
    onRefreshTable(400);
  }, [openSidebar]);

  const onRefreshTable = (delayTime) => {
    setTimeout(() => {
      if (hotTableRef && hotTableRef.current) {
        hotTableRef.current.hotInstance.getPlugin("search").query(search);
        hotTableRef.current.hotInstance.render();
      }
    }, delayTime);
  };

  const range = (size, start) => {
    return Array(size)
      .fill(start)
      .map((x, y) => x + y);
  };

  useEffect(() => {
    if (hotTableRef) {
      hotTableRef.current.hotInstance.getPlugin("search").query(search);
      hotTableRef.current.hotInstance.render();
    }
  }, [search]);

  const updateTable = (data) => {
    data = JSON.parse(JSON.stringify(data));
    if (isEdit) data.push({});
    setTableSettings((prevState) => ({
      ...prevState,
      ["data"]: data,
      ["columns"]: [
        {
          data: "pkey",
          editor: false,
          readOnly: true,
        },
        {
          data: "created_time",
          editor: false,
          readOnly: true,
        },
        {
          data: "created_user",
          editor: false,
          readOnly: true,
        },
        {
          data: "updated_time",
          editor: false,
          readOnly: true,
        },
        {
          data: "updated_user",
          editor: false,
          readOnly: true,
        },
        {
          data: "word1",
          validator: wordValidator,
          readOnly: isEdit ? false : true,
        },
      ],
      ["beforeChange"]: beforeChange,
      ["minSpareRows"]: isEdit ? 1 : null,
    }));
  };

  const databaseSend = (location, data, callback) => {
    SendQuery(
      `${bannedURL}/${location}`,
      props.auth.user_info,
      data,
      (err, res) => {
        if (err || !res) return callback(null);

        if (res.data && res.data.code === 500) {
          return dispatch(LogOut());
        }

        switch (data.type_of_information) {
          case "search":
          case "update":
            return callback(res.data);
        }
      }
    );
  };

  const databaseSearch = () => {
    setIsBackdrop(true);
    databaseSend(
      "search",
      { type_of_information: "search", sort: 1 },
      // sort : 0 - 전체 / 1 - 금지어 / 2 - 주의어 / 3 - 조합어
      (res) => {
        setIsBackdrop(false);
        if (!res) {
          return;
        }

        let new_words = res.words.map((word) => {
          if (word.created_time)
            word.created_time = moment
              .utc(word.created_time)
              .local()
              .format("YYYY-MM-DD HH:mm:ss");
          if (word.updated_time)
            word.updated_time = moment
              .utc(word.updated_time)
              .local()
              .format("YYYY-MM-DD HH:mm:ss");

          return word;
        });

        new_words = new_words.sort((a, b) => {
          return (
            moment(a.created_time).valueOf() - moment(b.created_time).valueOf()
          );
        });

        setTableData(new_words);
        updateTable(new_words.length > 0 ? new_words : []);
        setIsEdit(false);
      }
    );
  };

  // 중복 값 제거
  const remove_overlap = (origin, key) => {
    origin = origin.map((data) => {
      let obj = {};
      Object.assign(obj, data);
      return obj;
    });

    origin = _.uniqBy(origin, key);
    return origin;
  };

  const databaseUpdate = (callback) => {
    if (!hotTableRef) return callback();

    let total_data = hotTableRef.current.hotInstance.getData();
    let origin_data = JSON.parse(JSON.stringify(tableData));
    let insert_word = [];
    let modify_word = [];
    let remove_word = [];
    if (!Array.isArray(total_data)) return;
    total_data = remove_overlap(total_data, "5");
    total_data.forEach((data) => {
      if (!data[0]) {
        if (data[5]) insert_word.push({ word1: data[5] });
      } else {
        if (origin_data && origin_data.length > 0) {
          let origin = origin_data.find((origin) => {
            if (origin.pkey === data[0]) return origin;
          });
          if (origin) {
            if (data[5]) {
              if (origin.word1 !== data[5])
                modify_word.push({
                  pkey: origin.pkey,
                  word1: data[5],
                });
              origin_data.splice(origin_data.indexOf(origin), 1);
            }
          }
        }
      }
    });
    remove_word = origin_data.map((data) => {
      return {
        pkey: data.pkey,
      };
    });
    databaseSend(
      "update",
      {
        type_of_information: "update",
        sort: 1,
        insert_word,
        remove_word,
        modify_word,
      },
      (res) => {
        if (!res) {
          return callback();
        }
        return callback();
      }
    );
  };

  useEffect(() => {
    setCSVLabel(isEdit ? "가져오기" : "내보내기");
    setEditLabel(isEdit ? "편집 완료" : "편집 시작");

    handleSearchClear();
    updateTable(tableData.length > 0 ? tableData : []);
  }, [isEdit]);

  const handleEdit = () => {
    if (isEdit) {
      setIsOkCancelOpen(true);
    } else setIsEdit(!isEdit);
  };

  const handleSearchChange = (event) => {
    if (hotTableRef) {
      if (event.target.value && event.target.value.length > 0)
        setTableSettings({
          ...tableSettings,
          ["hiddenRows"]: {
            copyPasteEnabled: true,
            rows: range(
              isEdit
                ? hotTableRef.current.hotInstance.countRows() - 1
                : hotTableRef.current.hotInstance.countRows(),
              0
            ),
          },
        });
      else
        setTableSettings({
          ...tableSettings,
          ["hiddenRows"]: {
            copyPasteEnabled: true,
            rows: [],
          },
        });
    }
    setSearch(event.target.value);
  };

  const handleSearchClear = () => {
    if (hotTableRef) {
      setTableSettings({
        ...tableSettings,
        ["hiddenRows"]: {
          copyPasteEnabled: true,
          rows: [],
        },
      });
    }
    setSearch("");
  };

  const onOkConfirmDlg = () => {
    setIsOkCancelOpen(false);
    setIsBackdrop(true);
    databaseUpdate(() => {
      setIsBackdrop(false);
      databaseSearch();
    });
  };

  const onCancelConfirmDlg = () => {
    setIsEdit(false);
    setIsOkCancelOpen(false);
  };

  const handleCSV = () => {
    if (isEdit) {
      if (hiddenFileInput) hiddenFileInput.current.click();
    } else {
      setIsBackdrop(true);
      let workbook = new excel.Workbook();
      let sheet = workbook.addWorksheet("금지어");

      sheet.columns = [{ header: "단어", key: "word1" }];
      hotTableRef.current.hotInstance.getData().forEach((val) => {
        if (val[5]) sheet.addRow({ word1: val[5] });
      });

      workbook.xlsx
        .writeBuffer()
        .then((data) => {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${moment().local().format("YYYY-MM-DD HH:mm:ss")}_금지어.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          setIsBackdrop(false);
        })
        .catch((e) => {
          setIsBackdrop(false);
        });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    let reader = new FileReader();
    let workbook = new excel.Workbook();

    reader.onloadend = (e) => {
      setIsBackdrop(true);
      let data = e.target.result;
      workbook.xlsx
        .load(data)
        .then((xlsx) => {
          let file_data = [];
          xlsx.eachSheet((sheet) => {
            sheet.eachRow((row, row_num) => {
              if (row_num <= 1) return;

              let cell_data = {};
              row.eachCell((cell, cell_num) => {
                switch (cell_num) {
                  case 1:
                    cell_data.word1 = cell.text.trim();
                    break;
                  default:
                    return;
                }
              });

              if (cell_data.word1) file_data.push(cell_data);
            });
          });

          data = [];
          hotTableRef.current.hotInstance.getData().forEach((val) => {
            if (val[5]) {
              data.push({
                pkey: val[0],
                created_time: val[1],
                created_user: val[2],
                updated_time: val[3],
                updated_user: val[4],
                word1: val[5],
              });
            }
          });
          updateTable([...data, ...file_data]);
          setIsBackdrop(false);
        })
        .catch(() => {
          setIsBackdrop(false);
        });
    };
    reader.readAsArrayBuffer(file);
    hiddenFileInput.current.value = "";
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      style={{ padding: "70px" }}
    >
      {isOkCancelOpen && (
        <ConfirmDialog
          isOpen={isOkCancelOpen}
          okName={"예"}
          cancelName={"아니요"}
          content={"편집 내용을 저장하시겠습니까?"}
          onOkConfirmDlg={onOkConfirmDlg}
          onCancelConfirmDlg={onCancelConfirmDlg}
        />
      )}
      {isBackdrop && (
        <Backdrop className={contents_s.backdrop} open={isBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid
        item
        xs
        container
        alignItems="center"
        style={{ margin: "0px 0px 5px" }}
      >
        <Grid item>
          <Typography
            className={global_s.defaultText}
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#3c8dbc",
              marginRight: "10px",
            }}
          >
            {"금칙어 관리"}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={global_s.defaultText}
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "#77BBD4",
            }}
          >
            {"금지어"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ margin: "0px 0px 5px" }}
      >
        <Grid item xs container direction="row" alignItems="center">
          <Grid item>
            <Button1 className={contents_s.menuBtn} onClick={handleCSV}>
              <Typography className={contents_s.csvLabel}>
                {csvLabel}
              </Typography>
            </Button1>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <Button1
              className={isEdit ? contents_s.saveBtn : contents_s.editBtn}
              onClick={handleEdit}
            >
              <Typography className={contents_s.editLabel}>
                {editLabel}
              </Typography>
            </Button1>
          </Grid>
          <Grid item>
            <input
              type="file"
              name="importExcel"
              accept=".xlsx"
              ref={hiddenFileInput}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item xs style={{ maxWidth: "500px" }}>
            <OutlinedInput1
              value={search}
              onChange={handleSearchChange}
              placeholder={"조회"}
              style={{
                fontSize: 12,
                backgroundColor: "white",
                padding: "5px",
                height: "32px",
                borderRadius: "0px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  {search.length > 0 ? (
                    <IconButton1 size="small" onClick={handleSearchClear}>
                      <CloseIcon style={{ color: "#a9a9a9" }} />
                    </IconButton1>
                  ) : (
                    <SearchIcon style={{ color: "#a9a9a9" }} />
                  )}
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs style={{ margin: "2px 0px" }}>
        <HotTable
          id="banned-total-table"
          ref={hotTableRef}
          settings={tableSettings}
          width={"100%"}
          height={662}
          style={{ border: "1px solid #a9a9a9", backgroundColor: "#ffffff" }}
        />
      </Grid>
    </Grid>
  );
}

export default ForbiddenManagement;
