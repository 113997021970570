import React, { useState, useEffect } from "react";

import { useStyles } from "../containers/style.js";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, Typography, DialogActions, Divider } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import {
  Button1,
  IconButton1,
  OutlinedInput1,
  ColorCheckbox,
  StyledMenu,
} from ".";

import moment from "moment-timezone";

import CloseIcon from "@material-ui/icons/Close";

import {
  onSentenceRenderer,
  onCellRenderer,
  onHyypeRenderer,
  onBannedRenderer,
  onOrderRenderer,
} from "../containers/utils";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content1: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#565656",
    padding: "20px 0px 20px",
  },
  content2: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    border: "1px solid #999999",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#999999",
  },
  label1: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#292929",
  },
  label2: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#3C8DBC",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#confirm-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ConfirmEventDialog(props) {
  const header_s = headerStyles();
  const global_s = useStyles();
  const content_s = contentStyles();

  const [bannedSearch, setBannedSearch] = useState("");
  const [sentenceSearch, setSentenceSearch] = useState("");
  const [bannedGridApi, setBannedGridApi] = useState(null);
  const [sentenceGridApi, setSentenceGridApi] = useState(null);
  const [bannedRows, setBannedRows] = useState([]);
  const [sentenceRows, setSentenceRows] = useState([]);
  const bannedColumnDef = [
    {
      field: "sort",
      headerName: "종류",
      width: 120,
      sortable: true,
      resizable: true,
    },
    {
      field: "standard",
      headerName: "순서 여부",
      width: 120,
      sortable: true,
      resizable: true,
      cellRenderer: onOrderRenderer,
    },
    {
      field: "banned",
      headerName: "단어",
      width: 550,
      sortable: true,
      resizable: true,
    },
    {
      field: "count",
      headerName: "횟수(개)",
      width: 120,
      sortable: true,
      resizable: true,
    },
  ];
  const sentenceColumnDef = [
    {
      field: "type",
      headerName: "위치",
      width: 240,
      sortable: true,
      resizable: true,
    },
    {
      field: "sentence",
      headerName: "문장",
      width: 550,
      sortable: true,
      resizable: true,
      cellRenderer: onSentenceRenderer,
    },
    {
      field: "hype",
      headerName: "과장성",
      width: 120,
      sortable: true,
      resizable: true,
      cellRenderer: onHyypeRenderer,
    },
  ];

  useEffect(() => {
    if (props.selectData) {
      props.databaseSend(
        "detail",
        {
          selected: props.selectData,
          type_of_information: "detail",
        },
        (res) => {
          if (!res) return;

          let banned_data = [];
          let sentence_data = [];
          let contents_info = res.contents_info.shift();
          if (Array.isArray(contents_info.sentence_info)) {
            contents_info.sentence_info.forEach((sentence) => {
              sentence_data.push({
                type:
                  contents_info.type !== 2
                    ? sentence.type !== 0
                      ? "게시글 > 내용"
                      : "게시글 > 제목"
                    : "댓글",
                sentence: sentence.sentence,
                sentence_html: sentence.sentence_html,
                caution_info: sentence.caution_info,
                combination_info: sentence.combination_info,
                forbidden_info: sentence.forbidden_info,
                hype: sentence.hype,
              });
              sentence.caution_info.forEach((caution) => {
                let idx = banned_data.findIndex((val) => {
                  if (val.sort === 1 && val.word1 === caution.word) return val;
                });

                if (idx !== -1) banned_data[idx].count += caution.count;
                else
                  banned_data.push({
                    sort: 1,
                    standard: 0,
                    word1: caution.word,
                    banned: caution.word,
                    count: 1,
                  });
              });
              sentence.combination_info.forEach((combination) => {
                let idx = banned_data.findIndex((val) => {
                  if (
                    val.sort === 1 &&
                    val.standard === combination.standard &&
                    val.word1 === combination.word1 &&
                    val.word2 === combination.word2 &&
                    val.word3 === combination.word3 &&
                    val.word4 === combination.word4 &&
                    val.word5 === combination.word5
                  )
                    return val;
                });

                if (idx !== -1) banned_data[idx].count += combination.count;
                else {
                  let banned = "";
                  if (combination.word1) banned += combination.word1;
                  if (combination.word2)
                    banned +=
                      banned.length > 0
                        ? `, ${combination.word2}`
                        : combination.word2;
                  if (combination.word3)
                    banned +=
                      banned.length > 0
                        ? `, ${combination.word3}`
                        : combination.word3;
                  if (combination.word4)
                    banned +=
                      banned.length > 0
                        ? `, ${combination.word4}`
                        : combination.word4;
                  if (combination.word5)
                    banned +=
                      banned.length > 0
                        ? `, ${combination.word5}`
                        : combination.word5;

                  banned_data.push({
                    sort: 3,
                    standard: 0,
                    word1: combination.word,
                    banned: banned,
                    count: 1,
                  });
                }
              });
              sentence.forbidden_info.forEach((forbidden) => {
                let idx = banned_data.findIndex((val) => {
                  if (val.sort === 1 && val.word1 === forbidden.word)
                    return val;
                });

                if (idx !== -1) banned_data[idx].count += forbidden.count;
                else
                  banned_data.push({
                    sort: 2,
                    standard: 0,
                    word1: forbidden.word,
                    banned: forbidden.word,
                    count: 1,
                  });
              });
            });

            setBannedRows(banned_data);
            setSentenceRows(sentence_data);
          }
        }
      );
    }
  }, []);

  const handleCancel = () => {
    props.onCancelConfirmDlg();
  };

  const onBannedGridReady = (params) => {
    setBannedGridApi(params.api);
  };

  const onSentenceGridReady = (params) => {
    setSentenceGridApi(params.api);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth={true}
      open={props.isOpen}
      onClose={handleCancel}
      PaperComponent={PaperComponent}
      aria-labelledby="confirm-dialog-title"
    >
      <DialogTitle className={header_s.titlebar} id="confirm-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "36px" }}
        >
          <Grid item xs={10} container direction="row" alignItems="center">
            <Typography className={header_s.title}>{props.title}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <IconButton1
              size="small"
              aria-label="close"
              onClick={handleCancel}
              style={{ color: "#ffffff" }}
            >
              <CloseIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: "0px 10px 10px" }}>
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            style={{ margin: "10px 0px 0px" }}
          >
            <Grid item style={{ marginLeft: "10px" }}>
              <Typography className={content_s.label1}>
                {"등록 일자 : "}
              </Typography>
            </Grid>
            {props.selectData ? (
              <Grid item style={{ paddingLeft: "5px" }}>
                <Typography className={content_s.label1}>
                  {`${moment
                    .utc(props.selectData.created_time)
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss")}`}
                </Typography>
              </Grid>
            ) : null}
            <Grid item style={{ marginLeft: "10px" }}>
              <Typography className={content_s.label2}>
                {"판매자 : "}
              </Typography>
            </Grid>
            {props.selectData ? (
              <Grid item style={{ paddingLeft: "5px", marginRight: "10px" }}>
                <Typography className={content_s.label2}>
                  {props.selectData.user_id}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs style={{ margin: "10px 0px 0px" }}>
            <Divider />
          </Grid>
          <Grid item xs style={{ margin: "10px 10px 0px" }}>
            <Typography className={content_s.label1}>
              {"금칙어 정보"}
            </Typography>
          </Grid>
          <Grid item xs style={{ margin: "5px 0px 0px" }}>
            <div
              className="ag-theme-alpine"
              style={{ height: "300px", width: "100%" }}
            >
              <AgGridReact
                headerHeight={36}
                rowData={bannedRows}
                suppressMovableColumns={true} // columns 이동 금지
                overlayLoadingTemplate={"<span></span>"} // 데이터 로딩시 표시 화면
                overlayNoRowsTemplate={"<span></span>"} // 데이터 없을 경우 표시 화면
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  wrapText: true,
                  autoHeight: true,
                  cellClass: global_s.defaultGridCell,
                  headerClass: global_s.defaultGridHeader,
                }}
                onGridReady={onBannedGridReady}
              >
                {bannedColumnDef.map((columnItem) => {
                  return (
                    <AgGridColumn
                      key={columnItem.field}
                      headerName={columnItem.headerName}
                      field={columnItem.field}
                      hide={columnItem.hide}
                      width={columnItem.width}
                      tooltipField={columnItem.tooltipField}
                      checkboxSelection={columnItem.checkboxSelection}
                      valueGetter={columnItem.valueGetter}
                      cellRenderer={columnItem.cellRenderer}
                    />
                  );
                })}
              </AgGridReact>
            </div>
          </Grid>
          <Grid item xs style={{ margin: "10px 10px 0px" }}>
            <Typography className={content_s.label1}>{"문장 정보"}</Typography>
          </Grid>
          <Grid item xs style={{ margin: "5px 0px 0px" }}>
            <div
              className="ag-theme-alpine"
              style={{ height: "300px", width: "100%" }}
            >
              <AgGridReact
                headerHeight={36}
                rowData={sentenceRows}
                enableCellTextSelection={true}
                suppressMovableColumns={true} // columns 이동 금지
                overlayLoadingTemplate={"<span></span>"} // 데이터 로딩시 표시 화면
                overlayNoRowsTemplate={"<span></span>"} // 데이터 없을 경우 표시 화면
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  wrapText: true,
                  autoHeight: true,
                  cellClass: global_s.defaultGridCell,
                  headerClass: global_s.defaultGridHeader,
                }}
                onGridReady={onSentenceGridReady}
                onColumnResized={(params) => {
                  params.api.resetRowHeights();
                }}
              >
                {sentenceColumnDef.map((columnItem) => {
                  return (
                    <AgGridColumn
                      key={columnItem.field}
                      headerName={columnItem.headerName}
                      field={columnItem.field}
                      hide={columnItem.hide}
                      width={columnItem.width}
                      tooltipField={columnItem.tooltipField}
                      sortable={columnItem.sortable}
                      resizable={columnItem.resizable}
                      checkboxSelection={columnItem.checkboxSelection}
                      valueGetter={columnItem.valueGetter}
                      cellRenderer={columnItem.cellRenderer}
                    />
                  );
                })}
              </AgGridReact>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button1 className={content_s.btn} onClick={handleCancel}>
              <Typography className={content_s.btn_label}>
                {props.cancelName}
              </Typography>
            </Button1>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmEventDialog;
