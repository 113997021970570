import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import { NotFound } from "../../../containers";
import DetailManagement from "./Detail";
import DesignatedManagement from "./Designated";

function SellerAnalyzer(props) {
  const { match } = props;
  const auth = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (auth.isLogin) {
      props.handleHeader();
      props.handleSidebar();
    } else {
      return <Redirect to="/login" />;
    }
  }, []);

  if (!auth.isLogin) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={(props) => <DetailManagement {...props} auth={auth} />}
        />
        <Route
          path={`${match.url}/detail`}
          render={(props) => <DetailManagement {...props} auth={auth} />}
        />
        <Route
          path={`${match.url}/designated`}
          render={(props) => <DesignatedManagement {...props} auth={auth} />}
        />
        <Route path="*" render={(props) => <NotFound {...props} />} />
      </Switch>
    </>
  );
}

export default SellerAnalyzer;
