import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "../style.js";

function PingManagement(props) {
  const global_s = useStyles();
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      style={{ padding: "70px" }}
    >
      <Typography
        className={global_s.defaultText}
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          color: "#3c8dbc",
          marginRight: "10px",
        }}
      >
        {"Ping Test"}
      </Typography>
    </Grid>
  );
}

export default PingManagement;
