import { createStore, applyMiddleware } from "redux";
import reducers from "../reducers";
import reduxThunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

// 개발자 도구 사용.. 추후 제거 필요
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
  key: "hwayInc.AMP.Client.Secret.Key",
  storage,
};

// 상태값 변경 확인용.. 추후 제거 필요
// let prevState;
const enhancedReducer = persistReducer(persistConfig, reducers);
export default function configureStore() {
  const store = createStore(
    enhancedReducer,
    composeWithDevTools(applyMiddleware(reduxThunk))
  );
  // store.subscribe(() => {
  //   const state = store.getState();
  //   if (state === prevState) {
  //     console.log("state values is same");
  //   } else {
  //     console.log("state values changed");
  //   }
  //   prevState = state;
  // });
  const persistor = persistStore(store);
  return { store, persistor };
}
