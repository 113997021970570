import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  Header,
  Login,
  NotFound,
  Dashboard,
  Administer,
  Console,
  Ping,
} from "./containers";

import "./App.css";
import clsx from "clsx";
import { makeStyles, CssBaseline } from "@material-ui/core";
import { Sidebar, Sidebar_s } from "./components";
import { Grid } from "@material-ui/core";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `100%`,
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function App() {
  const classes = useStyles();

  const auth = useSelector((state) => state.auth, shallowEqual);
  const [showHeader, setShowHeader] = useState(auth.isLogin);
  const [openSidebar, setOpenSidebar] = useState(auth.isLogin);
  const [isSmall, setIsSmall] = useState(false);
  const [consoleSize, setConsoleSize] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (showHeader && consoleSize === 0) setConsoleSize(85);
    if (!showHeader) setConsoleSize(0);
  }, [showHeader]);

  useEffect(() => {
    if (windowDimensions.width > 500) return setIsSmall(false);
    else return setIsSmall(true);
  }, [windowDimensions]);

  const handleHeader = (state) => {
    setShowHeader(state);
  };

  const handleSidebar = (state) => {
    setOpenSidebar(state);
  };

  const getSidebarState = () => {
    return openSidebar;
  };

  const handleConsole = (state) => {
    if (state) setConsoleSize(85);
    else setConsoleSize(285);
  };

  return (
    <Grid container direction="column" alignItems="stretch">
      <Grid item style={{ height: showHeader ? 49 : 0 }}>
        {showHeader && (
          <Header
            auth={auth}
            openSidebar={openSidebar}
            isSmall={isSmall}
            handleSidebar={handleSidebar}
          />
        )}
      </Grid>
      <Grid item>
        <CssBaseline />
      </Grid>
      <Grid item container direction="column" alignItems="stretch">
        <div
          className="App"
          style={{
            backgroundImage: "url(/img/background.png)",
          }}
        >
          {!isSmall && <Sidebar isOpen={openSidebar} auth={auth} />}
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: !isSmall && openSidebar,
            })}
          >
            <Grid
              container
              direction="column-reverse"
              alignItems="stretch"
              style={{
                maxHeight: showHeader ? `calc(100vh - 49px)` : "100vh",
                minHeight: showHeader ? `calc(100vh - 49px)` : "100vh",
              }}
            >
              <Grid item>
                {showHeader && (
                  <Console auth={auth} handleConsole={handleConsole} />
                )}
              </Grid>
              <Grid
                item
                container
                style={{
                  overflow: "auto",
                  maxHeight: showHeader
                    ? `calc(100vh - ${consoleSize}px)`
                    : "100%",
                  minHeight: showHeader
                    ? `calc(100vh - ${consoleSize}px)`
                    : "100%",
                }}
              >
                <Grid item xs>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={(props) => (
                        <Dashboard
                          {...props}
                          handleHeader={handleHeader}
                          handleSidebar={handleSidebar}
                          getSidebarState={getSidebarState}
                        />
                      )}
                    />
                    <Route
                      path="/administer"
                      render={(props) => (
                        <Administer
                          {...props}
                          handleHeader={handleHeader}
                          handleSidebar={handleSidebar}
                          getSidebarState={getSidebarState}
                        />
                      )}
                    />
                    <Route
                      path="/dashboard"
                      render={(props) => (
                        <Dashboard
                          {...props}
                          handleHeader={handleHeader}
                          handleSidebar={handleSidebar}
                          getSidebarState={getSidebarState}
                        />
                      )}
                    />
                    <Route
                      path="/login"
                      render={(props) => (
                        <Login
                          {...props}
                          handleHeader={handleHeader}
                          handleSidebar={handleSidebar}
                        />
                      )}
                    />
                    <Route
                      path="/ping"
                      render={(props) => <Ping {...props} />}
                    />
                    <Route
                      path="*"
                      render={(props) => <NotFound {...props} />}
                    />
                  </Switch>
                </Grid>
              </Grid>
            </Grid>
          </main>
        </div>
      </Grid>
    </Grid>
  );
}

export default App;
