import { withStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export const OutlinedInput2 = withStyles((theme) => ({
  root: {
    "&:hover $notchedOutline": {
      borderColor: "#7ec5f8",
    },
    "&$focused $notchedOutline": {
      borderColor: "#7ec5f8",
    },
    "& input": {
      padding: "0px 5px",
      height: "32px",
      color: "#696969",
    },
    fontFamily: "Noto Sans",
    width: "100%",
  },
  notchedOutline: {},
  focused: {},
  input: {},
}))(OutlinedInput);
