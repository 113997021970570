import React, { useEffect, useState } from "react";
import { useStyles } from "../containers/style.js";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Paper,
  Backdrop,
  Typography,
  DialogActions,
  InputAdornment,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import Draggable from "react-draggable";

import {
  Button1,
  IconButton1,
  OutlinedInput1,
  ColorCheckbox,
  StyledMenu,
  ConfirmContentsListDetailDialog,
} from ".";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import {
  onHyypeRenderer,
  onBannedRenderer,
  onDateCellRenderer,
  setResetTimeVal,
} from "../containers/utils";

import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content1: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#565656",
    padding: "20px 0px 20px",
  },
  content2: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    border: "1px solid #999999",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#999999",
  },
  label1: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#292929",
  },
  label2: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#3C8DBC",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#confirm-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ConfirmSellerDialog(props) {
  const header_s = headerStyles();
  const global_s = useStyles();
  const contents_s = contentStyles();

  const [isBackdrop, setIsBackdrop] = useState(false);
  const [designated_list, setDesignatedList] = useState(undefined);

  const [search, setSearch] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const [rows, setRows] = useState([]);
  const columnDef = [
    {
      field: "Check",
      headerName: "",
      width: 50,
      sortable: false,
      resizable: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: "seller",
      headerName: "회원번호(이름)",
      tooltipField: "seller",
      width: 290,
      sortable: true,
      resizable: true,
    },
    {
      field: "totalCount",
      headerName: "등록글(개)",
      tooltipField: "totalCount",
      width: 290,
      sortable: true,
      resizable: true,
    },
    {
      field: "hypeCount",
      headerName: "과장성 글(개)",
      tooltipField: "hypeCount",
      width: 290,
      sortable: true,
      resizable: true,
    },
    {
      field: "bannedCount",
      headerName: "금칙어 글(개)",
      tooltipField: "bannedCount",
      width: 290,
      sortable: true,
      resizable: true,
    },
  ];

  useEffect(() => {
    if (!props.databaseSend) return;
    databaseSearch();
  }, []);

  const databaseSearch = () => {
    setIsBackdrop(true);
    props.databaseSend(
      "api/dashboard/designated",
      { isDesignated: false },
      (res) => {
        setIsBackdrop(false);
        if (!res || !Array.isArray(res.designated_list)) return;

        let designated_info = [];
        res.designated_list.forEach((designated) => {
          designated.seller = `${designated.user_id}(${
            designated.name ? designated.name : "-"
          })`;
          designated.designated_time = moment
            .utc(designated.designated_time)
            .local();
          designated_info.push(designated);
        });

        designated_info = designated_info.sort(
          (a, b) => b.designated_time.valueOf() - a.designated_time.valueOf()
        );

        setRows(designated_info);
      }
    );
  };

  const handleOk = () => {
    props.handleEnd(designated_list);
  };

  const handleCancel = () => {
    props.handleEnd();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    gridApi.setQuickFilter(event.target.value);
  };

  const handleSearchClear = () => {
    setSearch("");
    gridApi.setQuickFilter("");
  };

  const onSelectionChanged = () => {
    setDesignatedList(gridApi.getSelectedRows());
  };

  const onCellClicked = (event) => {
    event.node.setSelected(!event.node.selected);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      fullWidth={true}
      open={props.isOpen}
      onClose={handleCancel}
      PaperComponent={PaperComponent}
      aria-labelledby="confirm-dialog-title"
    >
      {isBackdrop && (
        <Backdrop className={contents_s.backdrop} open={isBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <DialogTitle className={header_s.titlebar} id="confirm-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "36px" }}
        >
          <Grid item xs={10} container direction="row" alignItems="center">
            <Typography className={header_s.title}>{props.title}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <IconButton1
              size="small"
              aria-label="close"
              onClick={handleCancel}
              style={{ color: "#ffffff" }}
            >
              <CloseIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: "0px 10px 10px" }}>
        <Grid container direction="column">
          <Grid item xs style={{ margin: "10px 0px 5px 0px" }}>
            <OutlinedInput1
              value={search}
              onChange={handleSearch}
              placeholder={"조회"}
              style={{
                fontSize: 12,
                padding: "5px",
                maxWidth: "100%",
                minWidth: "100%",
                backgroundColor: "white",
              }}
              endAdornment={
                <InputAdornment position="end">
                  {search.length > 0 ? ( // 입력 값의 길이에 따른 표시
                    <IconButton1 // 입력 값의 길이가 0보다 크다.
                      size="small"
                      onClick={handleSearchClear}
                    >
                      <CloseIcon
                        className={contents_s.intputIcon}
                        style={{ color: "#a9a9a9" }}
                      />
                    </IconButton1>
                  ) : (
                    <SearchIcon // 입력 값의 길이가 0보다 작거나 같다.
                      className={contents_s.intputIcon}
                      style={{ color: "#a9a9a9" }}
                    />
                  )}
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs style={{ margin: "5px 0px 0px" }}>
            <div
              className="ag-theme-alpine"
              style={{ height: "300px", width: "100%" }}
            >
              <AgGridReact
                headerHeight={36}
                rowData={rows}
                rowSelection="multiple"
                rowStyle={{ cursor: "pointer" }}
                suppressMovableColumns={true} // columns 이동 금지
                suppressRowClickSelection={true}
                overlayLoadingTemplate={"<span></span>"} // 데이터 로딩시 표시 화면
                overlayNoRowsTemplate={"<span></span>"} // 데이터 없을 경우 표시 화면
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  wrapText: true,
                  autoHeight: true,
                  cellClass: global_s.defaultGridCell,
                  headerClass: global_s.defaultGridHeader,
                }}
                onGridReady={onGridReady}
                onSelectionChanged={onSelectionChanged}
                onCellClicked={onCellClicked}
              >
                {columnDef.map((columnItem) => {
                  return (
                    <AgGridColumn
                      key={columnItem.field}
                      headerName={columnItem.headerName}
                      field={columnItem.field}
                      hide={columnItem.hide}
                      width={columnItem.width}
                      sortable={columnItem.sortable}
                      resizable={columnItem.resizable}
                      tooltipField={columnItem.tooltipField}
                      checkboxSelection={columnItem.checkboxSelection}
                      valueGetter={columnItem.valueGetter}
                      cellRenderer={columnItem.cellRenderer}
                      headerCheckboxSelection={
                        columnItem.headerCheckboxSelection
                      }
                    />
                  );
                })}
              </AgGridReact>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button1 className={contents_s.btn} onClick={handleOk}>
              <Typography className={contents_s.btn_label}>
                {props.okName}
              </Typography>
            </Button1>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <Button1 className={contents_s.btn} onClick={handleCancel}>
              <Typography className={contents_s.btn_label}>
                {props.cancelName}
              </Typography>
            </Button1>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmSellerDialog;
