import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import { Button1, IconButton1 } from "../components";
import { DialogActions } from "@material-ui/core";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content1: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#565656",
    padding: "20px 0px 20px",
  },
  content2: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    border: "1px solid #999999",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#999999",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#confirm-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ConfirmDialog(props) {
  const s_header = headerStyles();
  const s_content = contentStyles();

  const handleCancel = () => {
    props.onCancelConfirmDlg();
  };

  const handleOk = () => {
    props.onOkConfirmDlg();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth={true}
      open={props.isOpen}
      onClose={handleCancel}
      PaperComponent={PaperComponent}
      aria-labelledby="confirm-dialog-title"
    >
      <DialogTitle className={s_header.titlebar} id="confirm-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "36px" }}
        >
          <Grid item xs={10} container direction="row" alignItems="center">
            <Typography className={s_header.title}>{props.title}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <IconButton1
              size="small"
              aria-label="close"
              onClick={handleCancel}
              style={{ color: "#ffffff" }}
            >
              <CloseIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justify="center">
          <Grid item>
            <Typography
              className={s_header.content1}
              style={{ marginRight: "5px" }}
            >
              {props.itemName ? "'" + props.itemName + "'" : null}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={s_header.content2}>
              {props.content}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button1
              className={s_content.btn}
              onClick={handleOk}
              style={{ marginRight: "8px" }}
            >
              <Typography className={s_content.btn_label}>
                {props.okName}
              </Typography>
            </Button1>
          </Grid>
          <Grid item>
            <Button1 className={s_content.btn} onClick={handleCancel}>
              <Typography className={s_content.btn_label}>
                {props.cancelName}
              </Typography>
            </Button1>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
