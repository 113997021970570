import {
  Grid,
  Button,
  Typography,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Backdrop,
  CircularProgress,
  Paper,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Icon,
  makeStyles,
} from "@material-ui/core";

import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";

import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import RemoveIcon from "@material-ui/icons/Remove";
import SearchIcon from "@material-ui/icons/Search";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Drawer from "@material-ui/core/Drawer";

import {
  Button1,
  ConfirmDialog,
  MessageDialog,
  IconButton1,
  ConfirmGroupSelectDialog,
  OutlinedInput1,
  OutlinedInput2,
  ColorCheckbox,
} from "../../../components";

import _ from "lodash";

const ColorRadio = withStyles({
  root: {
    color: "#00b5ef",
    "&$checked": {
      color: "#00b5ef",
    },
    width: "22px",
    height: "22px",
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const headerStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Noto Sans",
    fontSize: "18px",
    color: "#ffffff",
    fontWeight: "bold",
  },
  paper: {
    width: "600px",
    height: "100%",
    overflow: "auto",
  },
  titlebar: {
    backgroundColor: "#7ec5f8",
    minHeight: "50px",
    maxHeight: "50px",
    padding: "0 15px 0",
  },
}));

const contentStyles = makeStyles((theme) => ({
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#818181",
  },
  radioLabel: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#707070",
    margin: "0px 0px 0px 5px",
  },
  label: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#818181",
    width: "100%",
    textAlign: "center",
  },
  inputText: {
    fontFamily: "Noto Sans",
    height: "28px",
    width: "100%",
    color: "#262626",
    backgroundColor: "white",
  },
  input: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    height: "30px",
    width: "100%",
    color: "#262626",
  },
  resetBtn: {
    color: "#ffffff",
    backgroundColor: "#3fa9f5",
    "&:hover": {
      backgroundColor: "#3f8ef5",
    },
    height: "30px",
  },
  resetBtnLabel: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  gridCell: {
    "justify-content": "flex-start",
    "align-items": "center",
    border: "none !important",
    fontFamily: "Noto Sans",
    fontSize: "13px",
  },
  gridHeader: {
    backgroundColor: "#ffffff",
  },
  btn: {
    outline: "none !important",
    outlineOffset: "none !important",
    boxShadow: "none",
  },
  btn_icon: {
    color: "#7ec5f8",
    marginRight: 5,
  },
  btn_label: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#707070",
  },
  btn_save: {
    width: "97px",
    height: "33px",
    color: "#ffffff",
    backgroundColor: "#3fa9f5",
    "&:hover": {
      backgroundColor: "#3f8ef5",
    },
  },
  btn_cancel: {
    width: "97px",
    height: "33px",
    color: "#ffffff",
    backgroundColor: "#999999",
    "&:hover": {
      backgroundColor: "#565656",
    },
  },
  btn_action_label: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  outline: {
    fontSize: 12,
    backgroundColor: "white",
    padding: "5px",
    height: "32px",
    borderRadius: "5px",
  },
  outline1: {
    fontSize: 14,
    backgroundColor: "white",
    padding: "5px",
    height: "32px",
    borderRadius: "5px",
  },
}));

function UserEditDrawer(props) {
  const s_header = headerStyles();
  const contents_s = contentStyles();

  const [ai_auth, setAIAuth] = useState(false);
  const [banned_auth, setBannedAuth] = useState(false);
  const [user_auth, setUserAuth] = useState(false);
  const [user_id, setUserID] = useState("");
  const [user_password, setUserPassword] = useState("");
  const [user_email, setUserEmail] = useState("");
  const [user_name, setUserName] = useState("");
  const [user_department, setUserDepartment] = useState("");
  const [user_description, setUserDescription] = useState("");

  useEffect(() => {
    if (props.user_info && Object.keys(props.user_info).length > 0) {
      switch (props.user_info.auth_type) {
        case 1:
          setUserAuth(true);
          break;
        case 2:
          setBannedAuth(true);
          break;
        case 3:
          setUserAuth(true);
          setBannedAuth(true);
          break;
        case 4:
          setAIAuth(true);
          break;
        case 5:
          setUserAuth(true);
          setAIAuth(true);
          break;
        case 6:
          setBannedAuth(true);
          setAIAuth(true);
          break;
        case 7:
          setUserAuth(true);
          setBannedAuth(true);
          setAIAuth(true);
          break;
        default:
          break;
      }

      setUserID(props.user_info.user_id);
      setUserEmail(props.user_info.email);
      setUserName(props.user_info.name);
      setUserDepartment(props.user_info.department);
      setUserDescription(props.user_info.description);
    }
  }, []);

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] = useState("");
  const messageDlgCloseName = "확인";

  const confirmDlgTitle = "";
  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [confirmDlgItem, setConfirmDlgItem] = useState("");
  const [confirmDlgContent, setConfirmDlgContent] = useState("");
  const [confirmDlg, setConfirmDlg] = useState("예");
  const [confirmDlgCancel, setConfirmDlgCancel] = useState("아니요");

  const randomString = () => {
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    let string_length = 5;
    let random_string = "";
    for (var i = 0; i < string_length; i++) {
      let rnum = Math.floor(Math.random() * chars.length);
      random_string += chars.substring(rnum, rnum + 1);
    }
    return random_string;
  };

  const handlePasswordReset = () => {
    setUserPassword(randomString());
  };

  const actionInsertErr1 = "비밀번호를 입력 바랍니다.";
  const actionInsertErr2 = "필수 항목( * )을 입력 바랍니다.";
  const actionCheckErr = "아이디 중복 확인을 실패했습니다.";
  const actionCheckErr1 = "아이디가 존재합니다. 계속 진행 하시겠습니까?";
  const actionCheckMessage = "계속 진행 하시겠습니까?";

  const isEmail = (asValue) => {
    var regExp =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return regExp.test(asValue);
  };

  const handleSave = () => {
    if (props.title === "사용자 추가") {
      if (user_password.length < 4) {
        setMessageDlgTitle("");
        setMessageDlgContent(actionInsertErr1);
        setOpenMessageDlg(true);
        return;
      }
    }

    if (user_id.length < 1 || user_name.length < 1) {
      setMessageDlgTitle("");
      setMessageDlgContent(actionInsertErr2);
      setOpenMessageDlg(true);
      return;
    }

    if (user_email.length > 0 && !isEmail(user_email)) {
      setMessageDlgTitle("");
      setMessageDlgContent("이메일 형식을 확인 바랍니다.");
      setOpenMessageDlg(true);
      return;
    }

    let auth_type = 0;
    if (user_auth) auth_type += 1;
    if (banned_auth) auth_type += 2;
    if (ai_auth) auth_type += 4;

    props.handleSave({
      auth_type: auth_type,
      user_id: user_id,
      password: user_password,
      name: user_name,
      email: user_email,
      department: user_department,
      description: user_description,
    });
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const handleAuth = (id) => {
    if (!id) return;
    switch (id) {
      case "user_auth":
        return setUserAuth(!user_auth);
      case "banned_auth":
        return setBannedAuth(!banned_auth);
      case "ai_auth":
        return setAIAuth(!ai_auth);
      default:
        return;
    }
  };

  const handleUserID = (event) => {
    setUserID(event.target.value);
  };

  const handleUserPassword = (event) => {
    setUserPassword(event.target.value);
  };

  const handleUserName = (event) => {
    setUserName(event.target.value);
  };

  const handleUserEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const handleUserDepartment = (event) => {
    setUserDepartment(event.target.value);
  };

  const handleUserDescription = (event) => {
    setUserDescription(event.target.value);
  };

  const onOkConfirmDlg = () => {
    if (!props.handleSave) return;
  };

  const onCancelConfirmDlg = () => {
    setOpenConfirmDlg(false);
  };

  const onCloseMessageDlg = () => {
    setOpenMessageDlg(false);
    setMessageDlgTitle("");
    setMessageDlgContent("");
  };

  return (
    <Drawer
      anchor="right"
      open={props.isOpen}
      classes={{
        paper: s_header.paper,
      }}
      style={{ overflow: "auto" }}
      onClose={handleCancel}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirm-dialog-title"
    >
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}
      {openConfirmDlg && (
        <ConfirmDialog
          isOpen={openConfirmDlg}
          title={confirmDlgTitle}
          content={confirmDlgContent}
          itemName={confirmDlgItem}
          okName={confirmDlg}
          cancelName={confirmDlgCancel}
          onOkConfirmDlg={onOkConfirmDlg}
          onCancelConfirmDlg={onCancelConfirmDlg}
        />
      )}
      <DialogTitle className={s_header.titlebar} id="confirm-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "50px" }}
        >
          <Grid item xs={10} container direction="row" alignItems="center">
            <Typography className={s_header.title}>{props.title}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <IconButton1
              size="small"
              aria-label="close"
              onClick={handleCancel}
              style={{ color: "#ffffff" }}
            >
              <CloseIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: "0px" }}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          style={{ width: "100%", height: "100%", padding: "10px 10px 5px" }}
        >
          <Grid item>
            <Typography className={contents_s.title}>
              {"사용자 정보"}
            </Typography>
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            style={{
              border: "1px solid #c1c1c1",
              borderRadius: "5px",
              marginTop: "5px",
            }}
          >
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Grid item>
                <Typography
                  className={contents_s.label}
                  style={{ textAlign: "left" }}
                >
                  {`관리 권한 (*)`}
                </Typography>
              </Grid>
              <Grid item xs style={{ padding: "0px 20px" }}>
                <Typography
                  className={contents_s.label}
                  style={{ textAlign: "left" }}
                >
                  {`※ 선택하지 않으면 읽기 전용 권한입니다.`}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justify="center"
              style={{ padding: "10px 20px 0px" }}
            >
              <Grid item xs container justify="center" direction="row">
                <Grid item style={{ padding: "0px 10px" }}>
                  <ColorCheckbox
                    edge="start"
                    checked={user_auth ? true : false}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": "default-column-check-all",
                    }}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onClick={() => handleAuth("user_auth")}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    className={contents_s.label}
                    style={{ textAlign: "left" }}
                  >
                    {`사용자`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs container justify="center" direction="row">
                <Grid item style={{ padding: "0px 10px" }}>
                  <ColorCheckbox
                    edge="start"
                    checked={banned_auth ? true : false}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": "default-column-check-all",
                    }}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onClick={() => handleAuth("banned_auth")}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    className={contents_s.label}
                    style={{ textAlign: "left" }}
                  >
                    {`금칙어`}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Grid item xs container justify="center" direction="row">
                <Grid item style={{ padding: "0px 10px" }}>
                  <ColorCheckbox
                    edge="start"
                    checked={ai_auth ? true : false}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": "default-column-check-all",
                    }}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onClick={() => handleAuth("ai_auth")}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    className={contents_s.label}
                    style={{ textAlign: "left" }}
                  >
                    {`AI`}
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>
            <Grid
              item
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Typography
                className={contents_s.label}
                style={{ textAlign: "left" }}
              >
                {`아이디 (*)`}
              </Typography>
            </Grid>
            <Grid item style={{ padding: "10px 20px 0px" }}>
              <OutlinedInput1
                className={contents_s.outline}
                value={user_id}
                onChange={handleUserID}
              />
            </Grid>
            <Grid
              item
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Typography
                className={contents_s.label}
                style={{ textAlign: "left" }}
              >
                {`비밀번호 (*)`}
              </Typography>
            </Grid>
            <Grid item style={{ padding: "10px 20px 0px" }}>
              <OutlinedInput1
                className={contents_s.outline}
                value={user_password}
                onChange={handleUserPassword}
              />
            </Grid>
            <Grid
              item
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Typography
                className={contents_s.label}
                style={{ textAlign: "left" }}
              >{`이름 (*)`}</Typography>
            </Grid>
            <Grid item style={{ padding: "10px 20px 0px" }}>
              <OutlinedInput1
                className={contents_s.outline}
                value={user_name}
                placeholder={"홍길동"}
                onChange={handleUserName}
              />
            </Grid>
            <Grid
              item
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Typography
                className={contents_s.label}
                style={{ textAlign: "left" }}
              >
                {`이메일`}
              </Typography>
            </Grid>
            <Grid item style={{ padding: "10px 20px 0px" }}>
              <OutlinedInput1
                className={contents_s.outline}
                value={user_email}
                placeholder={"example@example.com"}
                onChange={handleUserEmail}
              />
            </Grid>
            {/* <Grid
              item
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Typography
                className={contents_s.label}
                style={{ textAlign: "left" }}
              >{`부서`}</Typography>
            </Grid>
            <Grid item style={{ padding: "10px 20px 0px" }}>
              <OutlinedInput1
                className={contents_s.outline}
                value={user_department}
                onChange={handleUserDepartment}
              />
            </Grid> */}
            <Grid
              item
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Typography
                className={contents_s.label}
                style={{ textAlign: "left" }}
              >{`설명`}</Typography>
            </Grid>
            <Grid item style={{ padding: "10px 20px 0px" }}>
              <OutlinedInput2
                className={contents_s.outline1}
                value={user_description}
                onChange={handleUserDescription}
                multiline={true}
                // rows={13}
                rows={20}
                style={{
                  // height: "250px",
                  height: "350px",
                  padding: "0px 10px",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button1 className={contents_s.btn_save} onClick={handleSave}>
            <Typography className={contents_s.btn_action_label}>
              {"저장"}
            </Typography>
          </Button1>
          <Button1
            className={contents_s.btn_cancel}
            style={{ marginLeft: "10px" }}
            onClick={props.handleClose}
          >
            <Typography className={contents_s.btn_action_label}>
              {"취소"}
            </Typography>
          </Button1>
        </Grid>
      </DialogActions>
    </Drawer>
  );
}

export default UserEditDrawer;
