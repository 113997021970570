import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Draggable from "react-draggable";
import {
  Grid,
  Paper,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  OutlinedInput,
  InputAdornment,
  DialogActions,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { Button1, IconButton1, MessageDialog } from "../components";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content1: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#565656",
    padding: "20px 0px 20px",
  },
  content2: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
    marginLeft: "10px",
  },
  password: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#565656",
  },
}));

const materialTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&.Mui-focused $notchedOutline": {
          "border-color": "#7ec5f8",
          "border-width": "2px",
        },
      },
    },
  },
});

const contentStyles = makeStyles((theme) => ({
  bt1n: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
    outlineOffset: "none !important",
    boxShadow: "none",
  },
  btn: {
    width: "97px",
    height: "33px",
    border: "1px solid #999999",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#999999",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#confirm-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ConfirmDialog(props) {
  const s_header = headerStyles();
  const contents_s = contentStyles();

  const [visibility, setVisibility] = useState(false);
  const [password, setPassword] = useState("");

  const [isOpenMessage, setIsOpenMessage] = useState(false);
  const [messageContents, setMessageContents] = useState("");

  const actionInsertErr1 = "비밀번호를 입력 바랍니다.";

  const handleOk = () => {
    if (password.length < 4) {
      setMessageContents(actionInsertErr1);
      setIsOpenMessage(true);
      return;
    }

    props.databaseSend(
      "api/header/password",
      { type_of_information: "password", user_id: props.user_id, password },
      (res) => {
        if (!res) return;
        props.onOkConfirmPasswordDlg();
      }
    );
  };

  const handleCancel = () => {
    props.onCancelConfirmPasswordDlg();
  };

  const handleChange = (event) => {
    setPassword(event.target.value.substr(0, 50));
  };

  const handleVisibility = () => {
    setVisibility(!visibility);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onCloseMessageDlg = () => {
    setIsOpenMessage(false);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth={true}
      open={props.isOpen}
      onClose={handleCancel}
      PaperComponent={PaperComponent}
      aria-labelledby="confirm-dialog-title"
    >
      {isOpenMessage && (
        <MessageDialog
          isOpen={isOpenMessage}
          title={""}
          content={messageContents}
          closeName={"확인"}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}
      <DialogTitle className={s_header.titlebar} id="confirm-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "36px" }}
        >
          <Grid item xs={11}>
            <Typography className={s_header.title}>{props.title}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Grid container justify="flex-end" alignItems="center">
              <IconButton1
                size="small"
                aria-label="close"
                onClick={handleCancel}
                style={{ color: "#ffffff" }}
              >
                <CloseIcon />
              </IconButton1>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid container direction="row">
                <Typography className={s_header.content1}>
                  {props.itemName}
                </Typography>
                <Typography className={s_header.content2}>
                  {props.itemName1}
                </Typography>
              </Grid>
              <Grid container justify="flex-start">
                <ThemeProvider theme={materialTheme}>
                  <OutlinedInput
                    className={
                      visibility
                        ? s_header.password
                        : password.length < 1
                        ? s_header.password
                        : null
                    }
                    type={visibility ? "text" : "password"}
                    fullWidth
                    placeholder={"password"}
                    value={password}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          className={contents_s.bt1n}
                          onClick={handleVisibility}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {visibility ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button1 className={contents_s.btn} onClick={handleOk}>
              <Typography className={contents_s.btn_label}>
                {props.okName}
              </Typography>
            </Button1>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <Button1 className={contents_s.btn} onClick={handleCancel}>
              <Typography className={contents_s.btn_label}>
                {props.cancelName}
              </Typography>
            </Button1>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
