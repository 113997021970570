import moment from "moment-timezone";
import "moment/min/locales.min";

export const PieLabel = (params) => {
  const RADIAN = Math.PI / 180;
  const radius =
    params.innerRadius + (params.outerRadius - params.innerRadius) * 0.4;
  const x = params.cx + radius * Math.cos(-params.midAngle * RADIAN);
  const y = params.cy + radius * Math.sin(-params.midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={"center"}
      dominantBaseline="central"
      style={{
        fontFamily: "Noto Sans",
        fontSize: "16px",
      }}
    >
      {`${(params.percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const PieTooltip = (params) => {
  const RADIAN = Math.PI / 180;
  const radius =
    params.event.innerRadius +
    (params.event.outerRadius - params.event.innerRadius) * 0.4;
  const x =
    params.event.cx + radius * Math.cos(-params.event.midAngle * RADIAN);
  const y =
    params.event.cy + radius * Math.sin(-params.event.midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > params.event.cx ? "start" : "end"}
      dominantBaseline="central"
      style={{
        fontFamily: "Noto Sans",
        fontSize: "16px",
      }}
    >
      {`${(params.event.percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const onCellRenderer = (params) => {
  let value = params.getValue();
  let eGui = document.createElement("div");
  eGui.innerHTML = value;
  return eGui;
};

export const onWordsCellRenderer = (params) => {
  let eGui = document.createElement("div");

  if (!params.data) return eGui;
  if (params.data.word1) eGui.innerHTML += `${params.data.word1}; `;
  if (params.data.word2) eGui.innerHTML += `${params.data.word2}; `;
  if (params.data.word3) eGui.innerHTML += `${params.data.word3}; `;
  if (params.data.word4) eGui.innerHTML += `${params.data.word4}; `;
  if (params.data.word5) eGui.innerHTML += `${params.data.word5}; `;

  eGui.innerHTML.trim();
  return eGui;
};

export const onSortBannedCellRenderer = (params) => {
  let value = params.getValue();
  let eGui = document.createElement("div");
  switch (value) {
    case 1:
      eGui.innerHTML = "금지어";
      break;
    case 2:
      eGui.innerHTML = "주의어";
      break;
    case 3:
      eGui.innerHTML = "조합어";
      break;
    default:
      break;
  }

  return eGui;
};

export const onSortCellRenderer = (params) => {
  let value = params.getValue();
  let eGui = document.createElement("div");
  if (value) eGui.innerHTML = "댓글";
  else eGui.innerHTML = "게시글";
  return eGui;
};

export const onDateCellRenderer = (params) => {
  let value = params.getValue();
  if (!value) return "-";
  else if (typeof value === "string") value = moment.utc(value).local();
  let eGui = document.createElement("div");
  eGui.innerHTML = value.format("YYYY-MM-DD HH:mm:ss");
  if (eGui.innerHTML.includes("Invalid date")) {
    return "-";
  }
  return eGui;
};

export const onOldDataRenderer = (params) => {
  let value = params.getValue();
  let eGui = document.createElement("div");
  if (value) eGui.innerHTML = "O";
  return eGui;
};

export const onBannedRenderer = (params) => {
  let value = params.getValue();
  let eGui = document.createElement("div");
  if (value) eGui.innerHTML = "O";
  return eGui;
};

export const onOrderRenderer = (params) => {
  let value = params.getValue();
  let eGui = document.createElement("div");
  if (value) eGui.innerHTML = "O";
  else eGui.innerHTML = "X";
  return eGui;
};

export const onHyypeRenderer = (params) => {
  let value = params.getValue();
  let eGui = document.createElement("div");
  if (value) eGui.innerHTML = "O";
  else eGui.innerHTML = "";
  return eGui;
};

export const onEventRenderer = (params) => {
  let eGui = document.createElement("div");
  let updated_time = undefined;
  if (params.data.updated_time)
    updated_time = params.data.updated_time.format("YYYY-MM-DD HH:mm:ss");

  eGui.innerHTML = `[${updated_time}] ${params.data.user_id}(${
    params.data.name ? params.data.name : "-"
  })님이 작성한 ${params.data.parents_pkey ? "댓글" : "게시글"}에서 `;

  if (params.data.t_hype)
    eGui.innerHTML += `<span style="background:#F6B2B2; border: 1px solid #F6B2B2;">과장성</span>`;
  if (params.data.t_banned) {
    if (params.data.t_hype) eGui.innerHTML += "과 ";
    eGui.innerHTML += `<span style="background:#FCFFB1; border: 1px solid #FCFFB1;">금칙어</span>`;
    eGui.innerHTML += "가";
  } else eGui.innerHTML += "이";

  eGui.innerHTML += " 발견되었습니다.";
  return eGui;
};

export const onSentenceOrderRenderer = (params) => {
  let eGui = document.createElement("div");
  if (params.data.sentence_order < 10) eGui.innerHTML = "0";
  eGui.innerHTML = eGui.innerHTML + params.data.sentence_order;
  return eGui;
};

export const onSentenceRenderer = (params) => {
  let eGui = document.createElement("div");
  if (params.data.sentence_html) eGui.innerHTML = params.data.sentence_html;
  else eGui.innerHTML = params.data.sentence;
  return eGui;
};

export const handleSearch = (instance, row, col, value, result) => {
  const plugin = instance.getPlugin("hiddenRows");

  if (result) {
    plugin.showRow(row);
  }
};

export const beforeChange = (changes, source) => {
  for (let i = changes.length - 1; i >= 0; i--) {
    if (changes[i][3]) {
      changes[i][3] = changes[i][3].replace(
        /[^ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9|]/gi,
        ""
      );
      if (changes[i][3] && changes[i][3].length > 50)
        changes[i][3] = changes[i][3].substr(0, 50);
    }
  }
};

export const wordValidator = (value, callback) => {
  if (value && value.length > 50) {
    return callback(false);
  }
  return callback(true);
};

export const range = (size, start) => {
  return Array(size)
    .fill(start)
    .map((x, y) => x + y);
};

export const setResetTimeVal = (date, isEnd = 0) => {
  let val = moment(date);
  val.set("hour", 0);
  val.set("minute", 0);
  val.set("second", 0);
  val.set("millisecond", 0);
  switch (isEnd) {
    case -1:
      val.add(1, "days");
      break;
    case 1:
      val.add(1, "months");
      val.subtract(1, "days");
      break;
    case 2:
      val.add(1, "years");
      val.subtract(1, "days");
      break;
    default:
      break;
  }
  return val;
};

export const getLocale = () => {
  const defaultLocale = navigator.language || navigator.userLanguage;
  if (isEmpty(defaultLocale)) {
    defaultLocale = "en";
  }
  return defaultLocale;
};

export const isEmpty = (val) => {
  if (val === null) return true;
  if (val === undefined) return true;
  if (val === "") return true;
  if (val !== null && typeof val === "object" && !Object.keys(val).length)
    return true;
  return false;
};

export const resetEventGraph = (graph_option) => {
  let graph_data = [];
  let custom = false;
  if (graph_option.s_datetime && graph_option.e_datetime) {
    custom = true;
    graph_option.start = 0;
    graph_option.end =
      moment(graph_option.e_datetime)
        .startOf("day")
        .diff(moment(graph_option.s_datetime).startOf("day"), "days") + 1;

    if (graph_option.end !== 1) graph_option.label = "일";
    else {
      graph_option.start = 0;
      graph_option.end = 25;
      graph_option.label = "시";
    }
  }

  for (let i = graph_option.start; i < graph_option.end; i++) {
    let obj = {
      name: custom
        ? graph_option.label !== "시"
          ? moment(graph_option.s_datetime).add(i, "days").format("YYYY-MM-DD")
          : `${i}${graph_option.label}`.padStart(3, 0)
        : graph_option.label !== "주"
        ? `${i}${graph_option.label}`.padStart(3, 0)
        : `${i}${graph_option.label}`,
    };

    graph_option.labels.forEach((label) => {
      obj[label] = 0;
    });
    graph_data.push(obj);
  }
  return graph_data;
};
