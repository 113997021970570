import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useStyles } from "../../style.js";

import { Grid, Backdrop, Typography, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button1,
  IconButton1,
  OutlinedInput1,
  ConfirmDialog,
  MessageDialog,
} from "../../../components";

import { handleSearch, onDateCellRenderer } from "../../utils";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import moment from "moment-timezone";
import { SendQuery } from "../../../actions/send";
import { LogOut } from "../../../actions/auth";

import "./index.css";
import * as _ from "lodash";
import * as excel from "exceljs";

import UserEditDrawer from "./UserEditDrawer";
import { RowNodeSorter } from "ag-grid-community";

const contentStyles = makeStyles((theme) => ({
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#999999",
    margin: "3px 8px",
  },
  refreshLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  menuBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#62A4BC",
    "&:hover": {
      backgroundColor: "#62A4BC",
    },
    "&:disabled": {
      backgroundColor: "#999999",
    },
  },
  csvLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  editLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  editBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#62A4BC",
    "&:hover": {
      backgroundColor: "#62A4BC",
    },
  },
  saveBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#00CC00",
    "&:hover": {
      backgroundColor: "#009C00",
    },
  },
  disableCell: {
    color: "#ffffff",
    backgroundColor: "#a9a9a9",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let hiddenFileInput = undefined;
function UserManagement(props) {
  const dispatch = useDispatch();
  const global_s = useStyles();
  const contents_s = contentStyles();

  const userURL = "api/administer/user";
  const [csvLabel, setCSVLabel] = useState("내보내기");
  const [search, setSearch] = useState("");
  const [isSelected, setIsSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [gridApi, setGridApi] = useState(undefined);
  const [gridColumnApi, setGridColumnApi] = useState(undefined);
  const columnDef = [
    {
      field: "Check",
      headerName: "",
      width: 50,
      sortable: false,
      resizable: false,
      checkboxSelection: true,
    },
    {
      field: "created_time",
      headerName: "등록일",
      width: 150,
      sortable: true,
      resizable: true,
      cellRenderer: onDateCellRenderer,
    },
    {
      field: "user_id",
      headerName: "아이디",
      tooltipField: "user_id",
      width: 150,
      sortable: true,
      resizable: true,
    },
    {
      field: "auth",
      headerName: "권한",
      tooltipField: "auth",
      width: 210,
      sortable: true,
      resizable: true,
    },
    {
      field: "name",
      headerName: "이름",
      tooltipField: "name",
      width: 150,
      sortable: true,
      resizable: true,
    },
    {
      field: "email",
      headerName: "이메일",
      tooltipField: "email",
      width: 200,
      sortable: true,
      resizable: true,
    },
    // {
    //   field: "department",
    //   headerName: "부서",
    //   tooltipField: "department",
    //   width: 150,
    //   sortable: true,
    //   resizable: true,
    // },
    {
      field: "description",
      headerName: "설명",
      tooltipField: "description",
      width: 600,
      sortable: true,
      resizable: true,
    },
    // {
    //   field: "last_login_time",
    //   headerName: "접속일",
    //   tooltipField: "last_login_time",
    //   width: 150,
    //   sortable: true,
    //   resizable: true,
    //   cellRenderer: onDateCellRenderer,
    // },
  ];

  const modifyMessage = "한명만 선택바랍니다.";
  const [messageContents, setMessageContents] = useState(modifyMessage);
  const [isOpenMessage, setIsOpenMessage] = useState(false);

  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmContents, setConfirmContents] = useState("");
  const [isOkCancelOpen, setIsOkCancelOpen] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [userInfo, setUserInfo] = useState(undefined);

  if (!hiddenFileInput) hiddenFileInput = React.createRef();

  useEffect(() => {
    databaseSearch();
  }, []);

  if (
    props.auth &&
    props.auth.user_info &&
    props.auth.user_info.auth_type % 2 === 0
  ) {
    return <Redirect to="/dashboard" />;
  }

  const range = (size, start) => {
    return Array(size)
      .fill(start)
      .map((x, y) => x + y);
  };

  const databaseSend = (location, data, callback) => {
    SendQuery(
      `${userURL}/${location}`,
      props.auth.user_info,
      data,
      (err, res) => {
        if (err || !res) return callback(null);

        if (res.data && res.data.code === 500) {
          return dispatch(LogOut());
        }

        switch (data.type_of_information) {
          case "search":
          case "check":
          case "update":
          case "remove":
            return callback(res.data);
        }
      }
    );
  };

  const databaseSearch = () => {
    setIsBackdrop(true);
    databaseSend("search", { type_of_information: "search" }, (res) => {
      setIsBackdrop(false);
      if (!res || !res.users) {
        return;
      }

      let new_user = res.users.map((user) => {
        switch (user.auth_type) {
          case 1:
            user.auth = "사용자";
            break;
          case 2:
            user.auth = "금칙어";
            break;
          case 3:
            user.auth = "사용자, 금칙어";
            break;
          case 4:
            user.auth = "AI";
            break;
          case 5:
            user.auth = "사용자, AI";
            break;
          case 6:
            user.auth = "금칙어, AI";
            break;
          case 7:
            user.auth = "사용자, 금칙어, AI";
            break;
          default:
            user.auth = "읽기";
            break;
        }
        if (user.created_time)
          user.created_time = moment.utc(user.created_time).local();
        if (user.updated_time)
          user.updated_time = moment.utc(user.updated_time).local();
        if (user.last_login_time)
          user.last_login_time = moment.utc(user.last_login_time).local();
        return user;
      });

      new_user = new_user.sort((a, b) => {
        return b.created_time.valueOf() - a.created_time.valueOf();
      });

      setRows(new_user);
    });
  };

  const databaseCheck = (user_info) => {
    setIsBackdrop(true);
    databaseSend(
      "check",
      {
        type_of_information: "check",
        user_info: user_info,
      },
      (res) => {
        setIsBackdrop(false);
        if (!res) return;

        if (res.isExist)
          setConfirmContents("아이디가 이미 존재합니다.\n저장하시겠습니까?");
        else setConfirmContents("저장하시겠습니까?");
        setIsOkCancelOpen(true);
      }
    );
  };

  const databaseUpdate = () => {
    databaseSend(
      "update",
      {
        type_of_information: "update",
        user_info: userInfo,
      },
      (res) => {
        setIsBackdrop(false);
        if (!res) return;

        databaseSearch();
      }
    );
  };

  const databaseRemove = () => {
    databaseSend(
      "remove",
      {
        type_of_information: "remove",
        user_list: isSelected,
      },
      (res) => {
        setIsBackdrop(false);
        if (!res) return;

        databaseSearch();
      }
    );
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    databaseSearch();
  };

  const onSelectionChanged = () => {
    setIsSelected(gridApi.getSelectedRows());
  };

  const onRowDoubleClicked = (params) => {
    if (!params) return;
    handleModify(params.data);
  };

  const onCellClicked = (event) => {
    event.node.setSelected(!event.node.selected);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    gridApi.setQuickFilter(event.target.value);
  };

  const handleSearchClear = () => {
    setSearch("");
    gridApi.setQuickFilter("");
  };

  const onOkConfirmDlg = () => {
    setIsOkCancelOpen(false);
    switch (confirmTitle) {
      case "사용자 추가":
        setIsBackdrop(true);
        databaseUpdate();
        break;
      case "사용자 수정":
        setIsBackdrop(true);
        databaseUpdate();
        break;
      case "사용자 삭제":
        setIsBackdrop(true);
        databaseRemove();
        break;
      default:
        return;
    }
    setIsDrawerOpen(false);
  };

  const onCancelConfirmDlg = () => {
    setIsOkCancelOpen(false);
  };

  const onCloseMessageDlg = () => {
    setIsOpenMessage(false);
  };

  const handleCSV = () => {
    setIsBackdrop(true);
    let workbook = new excel.Workbook();
    let sheet = workbook.addWorksheet("사용자");

    sheet.columns = [
      { header: "아이디", key: "user_id" },
      { header: "이름", key: "name" },
      { header: "권한", key: "auth_type" },
      { header: "이메일", key: "email" },
      { header: "부서", key: "department" },
      { header: "설명", key: "description" },
    ];
    rows.forEach((val) => {
      if (val[5] && val[6])
        sheet.addRow({
          user_id: val[2],
          name: val[4],
          auth_type: val[5],
          email: val[6],
          department: val[7],
          description: val[8],
        });
    });

    workbook.xlsx
      .writeBuffer()
      .then((data) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${moment().local().format("YYYY-MM-DD HH:mm:ss")}_사용자.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        setIsBackdrop(false);
      })
      .catch((e) => {
        setIsBackdrop(false);
      });
  };

  const handleInsert = () => {
    gridApi.deselectAll();
    setDrawerTitle("사용자 추가");
    setUserInfo(undefined);
    setIsDrawerOpen(true);
  };

  const handleModify = (info = undefined) => {
    if (info) setUserInfo(info);
    else if (isSelected.length > 1) {
      setMessageContents("한명만 선택바랍니다.");
      return setIsOpenMessage(true);
    } else if (isSelected.length > 0) setUserInfo(isSelected.shift());
    else {
      setMessageContents("사용자를 선택바랍니다.");
      return setIsOpenMessage(true);
    }

    gridApi.deselectAll();
    setDrawerTitle("사용자 수정");
    setIsDrawerOpen(true);
  };

  const handleRemove = () => {
    if (isSelected.length < 1) return;

    setConfirmTitle("사용자 삭제");
    setConfirmContents("삭제하시겠습니까?");
    setIsOkCancelOpen(true);
  };

  const handleSave = (user_info) => {
    setUserInfo(user_info);
    setConfirmTitle(drawerTitle);
    if (drawerTitle === "사용자 추가") databaseCheck(user_info);
    else {
      setConfirmContents("저장하시겠습니까?");
      setIsOkCancelOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setUserInfo(undefined);
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      style={{ padding: "70px" }}
    >
      {isOpenMessage && (
        <MessageDialog
          isOpen={isOpenMessage}
          closeName={"확인"}
          content={messageContents}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}
      {isOkCancelOpen && (
        <ConfirmDialog
          isOpen={isOkCancelOpen}
          title={confirmTitle}
          okName={"예"}
          cancelName={"아니요"}
          content={confirmContents}
          onOkConfirmDlg={onOkConfirmDlg}
          onCancelConfirmDlg={onCancelConfirmDlg}
        />
      )}
      {isDrawerOpen && (
        <UserEditDrawer
          isOpen={isDrawerOpen}
          title={drawerTitle}
          user_info={userInfo}
          handleSave={handleSave}
          handleClose={handleDrawerClose}
        />
      )}
      {isBackdrop && (
        <Backdrop className={contents_s.backdrop} open={isBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid item style={{ margin: "0px 0px 5px" }}>
        <Typography
          className={global_s.defaultText}
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#3c8dbc",
            marginRight: "10px",
          }}
        >
          {"사용자 관리"}
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ margin: "0px 0px 5px" }}
      >
        <Grid
          item
          xs
          container
          direction="row"
          alignItems="center"
          style={{ minWidth: "300px" }}
        >
          {/* <Grid item style={{ marginRight: "5px" }}>
            <Button1 className={contents_s.menuBtn} onClick={databaseSearch}>
              <Typography className={contents_s.refreshLabel}>
                {"새로고침"}
              </Typography>
            </Button1>
          </Grid> */}
          <Grid item style={{ marginRight: "5px" }}>
            <Button1 className={contents_s.menuBtn} onClick={handleInsert}>
              <Typography className={contents_s.refreshLabel}>
                {"추가"}
              </Typography>
            </Button1>
          </Grid>
          <Grid item style={{ margin: "0px 5px" }}>
            <Button1
              className={contents_s.menuBtn}
              onClick={() => handleModify(undefined)}
            >
              <Typography className={contents_s.refreshLabel}>
                {"수정"}
              </Typography>
            </Button1>
          </Grid>
          <Grid item style={{ margin: "0px 5px" }}>
            <Button1 className={contents_s.menuBtn} onClick={handleRemove}>
              <Typography className={contents_s.refreshLabel}>
                {"삭제"}
              </Typography>
            </Button1>
          </Grid>
          {/* <Grid item style={{ marginLeft: "10px" }}>
              <Button1 className={contents_s.menuBtn} onClick={handleCSV}>
                <Typography className={contents_s.csvLabel}>
                  {csvLabel}
                </Typography>
              </Button1>
            </Grid> */}
          {/* <Grid item>
              <input
                type="file"
                name="importExcel"
                accept=".xlsx"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </Grid> */}
        </Grid>
        <Grid
          item
          xs
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item xs style={{ maxWidth: "500px" }}>
            <OutlinedInput1
              value={search}
              onChange={handleSearchChange}
              placeholder={"조회"}
              style={{
                fontSize: 12,
                backgroundColor: "white",
                padding: "5px",
                height: "32px",
                borderRadius: "0px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  {search.length > 0 ? (
                    <IconButton1 size="small" onClick={handleSearchClear}>
                      <CloseIcon style={{ color: "#a9a9a9" }} />
                    </IconButton1>
                  ) : (
                    <SearchIcon style={{ color: "#a9a9a9" }} />
                  )}
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs style={{ margin: "2px 0px" }}>
        <div
          className="ag-theme-alpine"
          style={{ height: "662px", width: "100%" }}
        >
          <AgGridReact
            rowData={rows}
            rowStyle={{ cursor: "pointer" }}
            rowSelection="multiple"
            defaultColDef={{
              sortable: true,
              resizable: true,
              cellClass: global_s.defaultGridCell,
              headerClass: global_s.defaultGridHeader,
            }}
            overlayLoadingTemplate={"<span></span>"}
            overlayNoRowsTemplate={"<span></span>"}
            headerHeight={36}
            suppressMovableColumns={true} // columns 이동 금지
            suppressRowClickSelection={true}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            onRowDoubleClicked={onRowDoubleClicked}
            onCellClicked={onCellClicked}
          >
            {columnDef.map((columnItem) => {
              return (
                <AgGridColumn
                  key={columnItem.headerName}
                  headerName={columnItem.headerName}
                  field={columnItem.field}
                  hide={columnItem.hide}
                  type={columnItem.type}
                  width={columnItem.width}
                  tooltipField={columnItem.tooltipField}
                  sortable={columnItem.sortable}
                  resizable={columnItem.resizable}
                  checkboxSelection={columnItem.checkboxSelection}
                  valueGetter={columnItem.valueGetter}
                  cellRenderer={columnItem.cellRenderer}
                />
              );
            })}
          </AgGridReact>
        </div>
      </Grid>
    </Grid>
  );
}

export default UserManagement;
