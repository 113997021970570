import React, { useEffect, useState } from "react";
import { useStyles } from "../containers/style.js";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Paper,
  Backdrop,
  Typography,
  DialogActions,
  InputAdornment,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import Draggable from "react-draggable";

import {
  Button1,
  IconButton1,
  OutlinedInput1,
  ColorCheckbox,
  StyledMenu,
  ConfirmContentsListDetailDialog,
} from ".";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import {
  onHyypeRenderer,
  onBannedRenderer,
  onDateCellRenderer,
  onOldDataRenderer,
  setResetTimeVal,
} from "../containers/utils";

import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content1: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#565656",
    padding: "20px 0px 20px",
  },
  content2: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    border: "1px solid #999999",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#999999",
  },
  label1: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#292929",
  },
  label2: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#3C8DBC",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#confirm-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ConfirmContentsListDialog(props) {
  const header_s = headerStyles();
  const global_s = useStyles();
  const contents_s = contentStyles();

  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selected, setSelected] = useState(undefined);

  const [isBackdrop, setIsBackdrop] = useState(false);
  const [contentsInfo, setContentsInfo] = useState(undefined);

  const [search, setSearch] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const [rows, setRows] = useState([]);
  const columnDef = [
    {
      field: "updated_time",
      headerName: "수정일",
      width: 160,
      sortable: true,
      resizable: true,
      cellRenderer: onDateCellRenderer,
    },
    {
      field: "sort",
      headerName: "종류",
      width: 80,
      sortable: true,
      resizable: true,
    },
    {
      field: "title",
      headerName: "제목",
      tooltipField: "title",
      width: 240,
      sortable: true,
      resizable: true,
    },
    {
      field: "contents",
      headerName: "내용",
      tooltipField: "contents",
      width: 520,
      sortable: true,
      resizable: true,
    },
    {
      field: "hype",
      headerName: "과장성",
      width: 100,
      sortable: true,
      resizable: true,
      cellRenderer: onHyypeRenderer,
    },
    {
      field: "banned",
      headerName: "금칙어",
      width: 100,
      sortable: true,
      resizable: true,
      cellRenderer: onBannedRenderer,
    },
  ];

  const [dataType, setDataType] = useState(false);
  const [bannedCount, setBannedCount] = useState(0);
  const [hypeCount, setHypeCount] = useState(0);

  useEffect(() => {
    if (props.selected) setContentsInfo(props.selected);
    setDataType(props.dataType);
  }, []);

  useEffect(() => {
    if (!props.databaseSend || !contentsInfo) return;
    search_contents(props.s_datetime, props.e_datetime);
  }, [contentsInfo]);

  useEffect(() => {
    if (selected) setIsDetailOpen(true);
    else setIsDetailOpen(false);
  }, [selected]);

  const databaseSend = (location, data, callback) => {
    props.databaseSend(location, data, callback);
  };

  const search_contents = (s_datetime, e_datetime) => {
    setIsBackdrop(true);
    databaseSend(
      "api/dashboard/contents",
      {
        isTest: dataType,
        user_id: contentsInfo.user_id,
        start_date: setResetTimeVal(s_datetime)
          .tz("UTC")
          .format("YYYY-MM-DD HH:mm:ss.SSS"),
        end_date: setResetTimeVal(e_datetime, -1)
          .tz("UTC")
          .format("YYYY-MM-DD HH:mm:ss.SSS"),
      },
      (res) => {
        if (!res || !Array.isArray(res.contents_info))
          return setIsBackdrop(false);

        let banned_count = 0;
        let hype_count = 0;
        let contents_row = [];
        res.contents_info.forEach((info) => {
          if (info.hype_t) {
            hype_count++;
            info.hype = true;
          }
          if (info.banned_t) {
            banned_count++;
            info.banned = true;
          }

          info.sort = info.parents_pkey ? "댓글" : "게시글";
          info.type = info.parents_pkey ? 2 : 1;
          info.created_time = moment.utc(info.created_time).local();

          contents_row.push(info);
        });

        contents_row = contents_row.sort(
          (a, b) => b.created_time.valueOf() - a.created_time.valueOf()
        );

        setBannedCount(banned_count);
        setHypeCount(hype_count);
        setRows(contents_row);

        return setIsBackdrop(false);
      }
    );
  };

  const handleCancel = () => {
    props.handleCancel();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onRowDoubleClicked = (params) => {
    if (params && params.data) setSelected(params.data);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    gridApi.setQuickFilter(event.target.value);
  };

  const handleSearchClear = () => {
    setSearch("");
    gridApi.setQuickFilter("");
  };

  const handleDetailCancel = () => {
    setSelected(undefined);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      fullWidth={true}
      open={props.isOpen}
      onClose={handleCancel}
      PaperComponent={PaperComponent}
      aria-labelledby="confirm-dialog-title"
    >
      {isDetailOpen && (
        <ConfirmContentsListDetailDialog
          cancelName="확인"
          isOpen={isDetailOpen}
          dataType={dataType}
          selected={selected}
          databaseSend={databaseSend}
          handleCancel={handleDetailCancel}
        />
      )}
      {isBackdrop && (
        <Backdrop className={contents_s.backdrop} open={isBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <DialogTitle className={header_s.titlebar} id="confirm-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "36px" }}
        >
          <Grid item xs={10} container direction="row" alignItems="center">
            <Typography className={header_s.title}>{props.title}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <IconButton1
              size="small"
              aria-label="close"
              onClick={handleCancel}
              style={{ color: "#ffffff" }}
            >
              <CloseIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: "0px 10px 10px" }}>
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            style={{ margin: "10px 0px 0px" }}
          >
            <Grid item xs container direction="row" alignItems="center">
              <Grid item>
                <Typography className={contents_s.label1}>
                  {"회원번호(이름) : "}
                </Typography>
              </Grid>
              {contentsInfo ? (
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography className={contents_s.label2}>
                    {contentsInfo.seller}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Grid
              item
              xs
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Typography className={contents_s.label1}>
                  {"조회 기간 : "}
                </Typography>
              </Grid>
              {props.s_datetime && props.e_datetime ? (
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography className={contents_s.label1}>
                    {`[${props.s_datetime}] ~ [${props.e_datetime}]`}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs style={{ margin: "10px 0px 0px" }}>
            <Divider />
          </Grid>
          <Grid item xs style={{ margin: "10px 0px 0px 0px" }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs container direction="row">
                <Grid item style={{ marginLeft: "20px" }}>
                  <Typography className={contents_s.label2}>
                    {`과장성 : ${hypeCount} 개`}
                  </Typography>
                </Grid>
                <Grid item style={{ marginLeft: "20px" }}>
                  <Typography className={contents_s.label2}>
                    {`금칙어 : ${bannedCount} 개`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs style={{ marginLeft: "100px", maxWidth: "500px" }}>
                <OutlinedInput1
                  value={search}
                  onChange={handleSearch}
                  placeholder={"조회"}
                  style={{
                    fontSize: 12,
                    padding: "5px",
                    maxWidth: "100%",
                    minWidth: "100%",
                    backgroundColor: "white",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {search.length > 0 ? ( // 입력 값의 길이에 따른 표시
                        <IconButton1 // 입력 값의 길이가 0보다 크다.
                          size="small"
                          onClick={handleSearchClear}
                        >
                          <CloseIcon
                            className={contents_s.intputIcon}
                            style={{ color: "#a9a9a9" }}
                          />
                        </IconButton1>
                      ) : (
                        <SearchIcon // 입력 값의 길이가 0보다 작거나 같다.
                          className={contents_s.intputIcon}
                          style={{ color: "#a9a9a9" }}
                        />
                      )}
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <div
              className="ag-theme-alpine"
              style={{ height: "300px", width: "100%" }}
            >
              <AgGridReact
                headerHeight={36}
                rowData={rows}
                rowStyle={{ cursor: "pointer" }}
                suppressMovableColumns={true} // columns 이동 금지
                overlayLoadingTemplate={"<span></span>"} // 데이터 로딩시 표시 화면
                overlayNoRowsTemplate={"<span></span>"} // 데이터 없을 경우 표시 화면
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  wrapText: true,
                  autoHeight: true,
                  cellClass: global_s.defaultGridCell,
                  headerClass: global_s.defaultGridHeader,
                }}
                onGridReady={onGridReady}
                onRowDoubleClicked={onRowDoubleClicked}
              >
                {columnDef.map((columnItem) => {
                  return (
                    <AgGridColumn
                      key={columnItem.field}
                      headerName={columnItem.headerName}
                      field={columnItem.field}
                      hide={columnItem.hide}
                      width={columnItem.width}
                      tooltipField={columnItem.tooltipField}
                      checkboxSelection={columnItem.checkboxSelection}
                      valueGetter={columnItem.valueGetter}
                      cellRenderer={columnItem.cellRenderer}
                    />
                  );
                })}
              </AgGridReact>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      {/* <DialogActions>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button1 className={contents_s.btn} onClick={handleCancel}>
              <Typography className={contents_s.btn_label}>
                {props.cancelName}
              </Typography>
            </Button1>
          </Grid>
        </Grid>
      </DialogActions> */}
    </Dialog>
  );
}

export default ConfirmContentsListDialog;
