import React from "react";
import { Link } from "react-router-dom";

function NotFound(props) {
  return (
    <div>
      <p>NotFound</p>
      <p style={{ textAlign: "center" }}>
      NotFound
        <Link to="/">Go to Home </Link>
      </p>
    </div>
  );
}

export default NotFound;
