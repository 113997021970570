import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "../../style.js";
import { global_theme } from "../../theme.js";
import {
  Grid,
  List,
  Backdrop,
  Divider,
  Typography,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

import {
  Button1,
  IconButton1,
  OutlinedInput1,
  ColorCheckbox,
  StyledMenu,
  ColorRadio,
  ConfirmContentsListDialog,
} from "../../../components";

import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import StopIcon from "@material-ui/icons/Stop";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  PieChart,
  Pie,
  Line,
  Label,
} from "recharts";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import {
  PieLabel,
  setResetTimeVal,
  resetEventGraph,
  onWordsCellRenderer,
} from "../../utils";

import { SendQuery } from "../../../actions/send";
import { LogOut, IsDesignated } from "../../../actions/auth";
import { getLocale } from "../../utils";
moment.locale(getLocale());

const contentStyles = makeStyles((theme) => ({
  defaultDate: {
    color: "#3c8dbc",
    backgroundColor: "white",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    fontSize: "14px",
    height: "30px",
    margin: "5px",
    //
  },
  selectDate: {
    "&:hover": {
      backgroundColor: "#3cadbc",
    },
    color: "white",
    backgroundColor: "#3c8dbc",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    fontSize: "14px",
    height: "30px",
    margin: "5px",
    //
  },
  searchBtn: {
    "&:hover": {
      backgroundColor: "#3c8dbc",
    },
    color: "white",
    backgroundColor: "#3c8dbc",
    height: "30px",
    marginLeft: "10px",
  },
  shortcutsBtn: {
    color: "#b9b9b9",
    backgroundColor: "white",
    height: "30px",
    marginLeft: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  searchText: {
    fontSize: 12,
    padding: "5px",
    maxWidth: "220px",
    minWidth: "210px",
    backgroundColor: "white",
  },
  cardRoot: {
    backgroundColor: "#ffffff",
    marginRight: "10px",
    marginBottom: "10px",
    borderRadius: "15px",
    maxWidth: "650px",
  },
}));

let old_date = 0;
let date_type = false;
let selectedDate = 0;
function TotalDashboard(props) {
  const dispatch = useDispatch();
  const global_s = useStyles();
  const global_t = global_theme;
  const contents_s = contentStyles();

  const [selectDate, setSelectDate] = useState(1);
  const [startDate, setStartDate] = useState(
    setResetTimeVal(moment().local().format())
  );
  const [endDate, setEndDate] = useState(
    setResetTimeVal(moment().local().format())
  );

  // dataType 0 - real / 1 - test
  const [dataType, setDataType] = useState(0);

  const [originGraph, setOriginGraph] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [eventCount, setEventCount] = useState(0);
  const [eventRatio, setEventRatio] = useState(0);
  const [originPie_E, setOriginPie_E] = useState([]);
  const [originPie_H, setOriginPie_H] = useState([]);
  const [originHype, setOriginHype] = useState([]);
  const [checkedHype, setCheckedHype] = useState([]);

  const normalLabel = "정상";
  const eventLabel = "이벤트";
  const bannedLabel = "금칙어";
  const hypeLabel = "과장성";
  const forbiddenLabel = "금지어";
  const cautionLabel = "주의어";
  const combinationLabel = "조합어";

  const [bannedSearch, setBannedSearch] = useState("");
  const [bannedGridApi, setBannedGridApi] = useState(null);
  const [bannedRows, setBannedRows] = useState([]);
  const bannedColumnDef = [
    {
      field: "word1",
      hide: true,
    },
    {
      field: "word2",
      hide: true,
    },
    {
      field: "word3",
      hide: true,
    },
    {
      field: "word4",
      hide: true,
    },
    {
      field: "word5",
      hide: true,
    },
    {
      field: "words",
      headerName: "단어",
      tooltipField: "words",
      width: 580,
      sortable: true,
      resizable: true,
    },
    {
      field: "sortLabel",
      headerName: "종류",
      width: 150,
      sortable: true,
      resizable: true,
    },
    {
      field: "count",
      headerName: "횟수(개)",
      width: 150,
      sortable: true,
      resizable: true,
    },
  ];

  const [sellerSearch, setSellerSearch] = useState("");
  const [sellerGridApi, setSellerGridApi] = useState(null);
  const [sellerRows, setSellerRows] = useState([]);
  const sellerColumnDef = [
    {
      field: "seller",
      headerName: "회원번호(이름)",
      tooltipField: "seller",
      width: 200,
      sortable: true,
      resizable: true,
    },
    {
      field: "sentenceHypeCount",
      headerName: "과장성 문장(개)",
      width: 150,
      sortable: true,
      resizable: true,
    },
    {
      field: "sentenceBannedCount",
      headerName: "금칙어 문장(개)",
      width: 150,
      sortable: true,
      resizable: true,
    },
    {
      field: "eventCount",
      headerName: "이벤트 발생 글(개)",
      width: 150,
      sortable: true,
      resizable: true,
    },
  ];

  const originEventCols = [
    { name: normalLabel, color: "#638BEC" },
    { name: eventLabel, color: "#69D2BC" },
  ];
  const originHypeCols = [
    { name: normalLabel, color: "#80BFEA" },
    { name: hypeLabel, color: "#7FD7D5" },
  ];
  const [checkedHypeCols, setCheckedHypeCols] = useState([]);
  const [defaultColMenuRef, setDefaultColMenuRef] = useState(null);
  const defaultColMenuOpen = Boolean(defaultColMenuRef);

  const [isOpenContentsList, setIsOpenContentsList] = useState(false);
  const [contentsInfo, setContentsInfo] = useState(undefined);

  const [isBackdrop, setIsBackdrop] = useState(false);
  const [backTimer, setBackTimer] = useState(1000);

  useEffect(() => {
    onCheckedAllDefaultCols();
    dispatch(IsDesignated(false));
  }, []);

  useEffect(() => {
    let data = originHype.map((item) => {
      let obj = { name: item.name };
      checkedHypeCols.forEach((item1) => {
        obj[item1.name] = item[item1.name];
      });
      return obj;
    });
    setCheckedHype(data);
  }, [checkedHypeCols, originHype]);

  useEffect(() => {
    const start = setInterval(() => {
      if (parseInt(backTimer) > 0) {
        setBackTimer(parseInt(backTimer) - 1);
      }
      if (parseInt(backTimer) === 0) {
        setBackTimer(1000);
      }

      if (!check_today()) return;

      if (selectDate === -1) return setSelectDate(old_date);

      if (selectDate > 0 && selectDate < 5) search_statistics(false);
    }, 10000);
    return () => clearInterval(start);
  }, [backTimer]);

  useEffect(() => {
    if (contentsInfo) setIsOpenContentsList(true);
    else setIsOpenContentsList(false);
  }, [contentsInfo]);

  useEffect(() => {
    if (selectDate !== -1 && selectDate > 0) {
      date_type = dataType;
      selectedDate = selectDate;
      search_statistics(true);
    }
  }, [dataType]);

  useEffect(() => {
    if (selectDate !== -1 && selectDate > 0) {
      date_type = dataType;
      selectedDate = selectDate;
      search_statistics(true);
    }
  }, [selectDate]);

  const check_today = () => {
    let datetime = moment(startDate);
    let s_datetime = undefined;
    let e_datetime = undefined;

    switch (selectDate) {
      case 1:
        if (datetime.format("dd") === moment().local().format("dd"))
          return true;

        s_datetime = setResetTimeVal(moment().local().format());
        e_datetime = setResetTimeVal(moment().local().format());
        break;
      case 2:
        if (datetime.format("mm") !== moment().local().format("mm"))
          return true;

        s_datetime = setResetTimeVal(moment().format("YYYY-MM"));
        e_datetime = setResetTimeVal(moment().format("YYYY-MM"), 1);
        break;
      case 3:
        if (datetime.format("mm") !== moment().local().format("mm"))
          return true;

        s_datetime = setResetTimeVal(moment().format("YYYY-MM"));
        e_datetime = setResetTimeVal(moment().format("YYYY-MM"), 1);
        break;
      case 4:
        if (datetime.format("yyyy") !== moment().local().format("yyyy"))
          return true;

        s_datetime = setResetTimeVal(moment().format("YYYY"));
        e_datetime = setResetTimeVal(moment().format("YYYY"), 2);
        break;
      default:
        return true;
    }

    old_date = selectDate;
    setStartDate(s_datetime);
    setEndDate(e_datetime);
    setSelectDate(-1);
    return false;
  };

  const handleStartDateChange = (datetime) => {
    if (datetime > endDate) {
      datetime = setResetTimeVal(datetime);
      setEndDate(datetime);
    } else datetime = setResetTimeVal(datetime);

    setStartDate(datetime);
  };

  const handleEndDateChange = (datetime) => {
    if (startDate > datetime) {
      datetime = setResetTimeVal(datetime);
      setStartDate(datetime);
    } else datetime = setResetTimeVal(datetime);
    setEndDate(datetime);
  };

  const databaseSend = (location, data, callback) => {
    SendQuery(location, props.auth.user_info, data, (err, res) => {
      if (err || !res) return callback(null);

      if (res.data && res.data.code === 500) {
        return dispatch(LogOut());
      }

      return callback(res.data);
    });
  };

  const search_statistics = (selected = false) => {
    if (selected) {
      setIsBackdrop(true);
    }

    SendQuery(
      "api/dashboard/statistics",
      props.auth.user_info,
      {
        isTest: dataType,
        isBanned: true,
        start_date: setResetTimeVal(startDate)
          .tz("UTC")
          .format("YYYY-MM-DD HH:mm:ss.SSS"),
        end_date: setResetTimeVal(endDate, -1)
          .tz("UTC")
          .format("YYYY-MM-DD HH:mm:ss.SSS"),
      },
      (err, res) => {
        if (err || !res) return setIsBackdrop(false);

        if (res.data && res.data.code === 500) {
          setIsBackdrop(false);
          return dispatch(LogOut());
        }

        if (selectedDate !== selectDate || date_type !== dataType)
          return setIsBackdrop(false);

        let custom_time = false;
        let graph_option = {};
        switch (selectDate) {
          case 1:
            graph_option.start = 0;
            graph_option.end = 24;
            graph_option.label = "시";
            break;
          case 2:
            graph_option.start = 1;
            graph_option.end =
              parseInt(setResetTimeVal(endDate, true).tz("UTC").format("D")) +
              2;
            graph_option.label = "일";
            break;
          case 3:
            graph_option.start = 1;
            graph_option.end =
              parseInt(setResetTimeVal(endDate, true).tz("UTC").format("D")) +
              2;
            graph_option.end =
              graph_option.end % 7 > 0
                ? graph_option.end / 7 + 1
                : graph_option.end / 7;
            graph_option.label = "주";
            break;
          case 4:
            graph_option.start = 1;
            graph_option.end = 13;
            graph_option.label = "월";
            break;
          case 5:
            graph_option.s_datetime = startDate;
            graph_option.e_datetime = endDate;
            if (
              moment(endDate)
                .startOf("day")
                .diff(moment(startDate).startOf("day"), "days") !== 0
            )
              custom_time = true;
            break;
          default:
            return;
        }

        graph_option.labels = [normalLabel, eventLabel];
        let event_graph = resetEventGraph(graph_option);
        graph_option.labels = [normalLabel, hypeLabel];
        let hype_graph = resetEventGraph(graph_option);

        let total_count = 0;
        let total_event = 0;
        let total_sentence = 0;
        let total_sentence_hype = 0;
        let banned_row = [];
        let seller_row = [];

        res.data.statistics_list.forEach((statistics) => {
          total_count += statistics.totalCount;
          total_event += statistics.eventCount;
          total_sentence += statistics.sentenceCount;
          total_sentence_hype += statistics.sentenceHypeCount;

          let s_idx = seller_row.findIndex((s_val) => {
            if (s_val.user_id === statistics.user_id) return s_val;
          });

          if (s_idx !== -1) {
            seller_row[s_idx].sentenceHypeCount += statistics.sentenceHypeCount;
            seller_row[s_idx].sentenceBannedCount +=
              statistics.sentenceBannedCount;
            seller_row[s_idx].eventCount += statistics.eventCount;
          } else
            seller_row.push({
              user_id: statistics.user_id,
              seller: `${statistics.user_id}(${
                statistics.name ? statistics.name : "-"
              })`,
              sentenceHypeCount: statistics.sentenceHypeCount,
              sentenceBannedCount: statistics.sentenceBannedCount,
              eventCount: statistics.eventCount,
            });

          let idx = 0;
          let datetime = moment.utc(statistics.created_time).local();
          switch (selectDate) {
            case 1:
              idx = datetime.format("H");
              break;
            case 2:
              idx = datetime.format("D") - 1;
              break;
            case 3:
              idx = datetime.format("D") - 1;
              idx = idx !== 0 ? parseInt(idx / 7) : 0;
              break;
            case 4:
              idx = datetime.format("M") - 1;
              break;
            case 5:
              if (!custom_time) idx = datetime.format("H");
              else idx = datetime.diff(moment(startDate), "days");
              break;
            default:
              return;
          }

          event_graph[idx][normalLabel] +=
            statistics.totalCount - statistics.eventCount;
          event_graph[idx][eventLabel] += statistics.eventCount;

          hype_graph[idx][normalLabel] +=
            statistics.sentenceCount - statistics.sentenceHypeCount;
          hype_graph[idx][hypeLabel] += statistics.sentenceHypeCount;
        });

        res.data.statistics_banned_list.forEach((banned) => {
          let idx = banned_row.findIndex((val) => {
            if (
              val.sort === banned.sort &&
              val.standard === banned.standard &&
              val.word1 === banned.word1 &&
              val.word2 === banned.word2 &&
              val.word3 === banned.word3 &&
              val.word4 === banned.word4 &&
              val.word5 === banned.word5
            )
              return val;
          });

          if (idx !== -1) banned_row[idx].count += banned.count;
          else {
            let words = "";
            if (banned.word1) words += `${banned.word1}; `;
            if (banned.word2) words += `${banned.word2}; `;
            if (banned.word3) words += `${banned.word3}; `;
            if (banned.word4) words += `${banned.word4}; `;
            if (banned.word5) words += `${banned.word5}; `;

            banned_row.push({
              sort: banned.sort,
              sortLabel:
                banned.sort === 1
                  ? forbiddenLabel
                  : banned.sort === 2
                  ? cautionLabel
                  : combinationLabel,
              standard: banned.standard,
              words: words,
              word1: banned.word1,
              word2: banned.word2,
              word3: banned.word3,
              word4: banned.word4,
              word5: banned.word5,
              count: banned.count,
            });
          }
        });

        setTotalCount(total_count);
        setEventCount(total_event);
        setOriginGraph(event_graph);
        setOriginHype(hype_graph);

        setEventRatio(
          total_count > 0
            ? total_event > 0
              ? ((total_event / total_count) * 100).toFixed(2)
              : (0).toFixed(2)
            : (0).toFixed(2)
        );
        setSellerRows(seller_row);
        setBannedRows(banned_row);
        setOriginPie_E([
          {
            name: normalLabel,
            value: total_count - total_event,
            color: "#638BEC",
          },
          {
            name: eventLabel,
            value: total_event,
            color: "#69D2BC",
          },
        ]);
        setOriginPie_H([
          {
            name: normalLabel,
            value: total_sentence - total_sentence_hype,
            color: "#80BFEA",
          },
          {
            name: hypeLabel,
            value: total_sentence_hype,
            color: "#7FD7D5",
          },
        ]);

        setIsBackdrop(false);
      }
    );
  };

  const handleDateChange = (event) => {
    let id = event && event.currentTarget ? event.currentTarget.id : undefined;
    let s_datetime = undefined;
    let e_datetime = undefined;
    let selected = undefined;
    switch (id) {
      case "today":
        s_datetime = setResetTimeVal(moment().local().format());
        e_datetime = setResetTimeVal(moment().local().format());
        selected = 1;
        break;
      case "daily":
        s_datetime = setResetTimeVal(moment().format("YYYY-MM"));
        e_datetime = setResetTimeVal(moment().format("YYYY-MM"), 1);
        selected = 2;
        break;
      case "weekly":
        s_datetime = setResetTimeVal(moment().format("YYYY-MM"));
        e_datetime = setResetTimeVal(moment().format("YYYY-MM"), 1);
        selected = 3;
        break;
      case "monthly":
        s_datetime = setResetTimeVal(moment().format("YYYY"));
        e_datetime = setResetTimeVal(moment().format("YYYY"), 2);
        selected = 4;
        break;
      case "search":
        if (
          moment()
            .startOf("day")
            .diff(moment(startDate).startOf("day"), "days") === 0 &&
          moment()
            .startOf("day")
            .diff(moment(endDate).startOf("day"), "days") === 0
        ) {
          s_datetime = setResetTimeVal(moment().local().format());
          e_datetime = setResetTimeVal(moment().local().format());
          selected = 1;
        } else selected = 5;
        break;
      default:
        s_datetime = setResetTimeVal(moment().local().format());
        e_datetime = setResetTimeVal(moment().local().format());
        selected = 1;
        break;
    }

    if (s_datetime) setStartDate(s_datetime);
    else s_datetime = startDate;
    if (e_datetime) setEndDate(e_datetime);
    else e_datetime = endDate;

    setSelectDate(selected);
  };

  const onSellerGridReady = (params) => {
    setSellerGridApi(params.api);
  };

  const handleSellerSearch = (event) => {
    setSellerSearch(event.target.value);
    sellerGridApi.setQuickFilter(event.target.value);
  };

  const handleSellerSearchClear = () => {
    setSellerSearch("");
    sellerGridApi.setQuickFilter("");
  };

  const onSellerRowDoubleClicked = (params) => {
    if (params) setContentsInfo(params.data);
  };

  const onBannedGridReady = (params) => {
    setBannedGridApi(params.api);
  };

  const handleBannedSearch = (event) => {
    setBannedSearch(event.target.value);
    bannedGridApi.setQuickFilter(event.target.value);
  };

  const handleBannedSearchClear = () => {
    setBannedSearch("");
    bannedGridApi.setQuickFilter("");
  };

  const handleDefaultCols = (event) => {
    setDefaultColMenuRef(event.currentTarget);
  };

  const onCheckDefaultCols = (defaultCols, checkedCols) => {
    const newDefaultCols = [];
    for (let loop1 = 0; loop1 < defaultCols.length; loop1++) {
      if (
        checkedCols.findIndex(
          (item) => item.name === defaultCols[loop1].name
        ) !== -1
      ) {
        newDefaultCols.push(defaultCols[loop1]);
      }
    }
    setCheckedHypeCols(newDefaultCols);
    return newDefaultCols;
  };

  const onCheckedAllDefaultCols = () => {
    let newChecked;
    if (checkedHypeCols.length === originHypeCols.length) {
      newChecked = [];
    } else {
      newChecked = [...originHypeCols];
    }

    setCheckedHypeCols(newChecked);
  };

  const onCheckedDefaultCols = (defaultCol) => {
    const currentIndex = checkedHypeCols.findIndex(
      (item) => item.name === defaultCol.name
    );
    let newChecked = [...checkedHypeCols];

    if (currentIndex === -1) {
      newChecked.push(defaultCol);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    onCheckDefaultCols(originHypeCols, newChecked);
  };

  const onCloseCols = () => {
    setDefaultColMenuRef(null);
  };

  const handleContentsListCancel = () => {
    setContentsInfo(undefined);
  };

  const onCheckedAllCols = () => {
    const labelId_all = "default-column-check-all";
    let isChecked = false;
    let checkBoxIcon = <CheckBoxIcon fontSize="small" />;

    if (checkedHypeCols.length === originHypeCols.length) {
      isChecked = true;
      if (originHypeCols.length === 0) isChecked = false;
    } else {
      if (checkedHypeCols.length > 0) {
        checkBoxIcon = <IndeterminateCheckBoxIcon fontSize="small" />;
        isChecked = true;
      }
    }

    return (
      <div key={labelId_all}>
        <ListItem
          role={undefined}
          dense
          button
          onClick={() => onCheckedAllDefaultCols()}
          style={{ width: "148px" }}
        >
          <ListItemIcon
            style={{
              minWidth: "0px",
              marginRight: "10px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          >
            <ColorCheckbox
              edge="start"
              checked={isChecked}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId_all }}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={checkBoxIcon}
            />
          </ListItemIcon>
          <ListItemText id={labelId_all} primary={"전체 선택"}></ListItemText>
        </ListItem>
      </div>
    );
  };

  const handleChangeDataType = (event) => {
    setDataType(parseInt(event.target.value));
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      style={{
        padding: "10px 0px 10px 10px",
      }}
    >
      {isOpenContentsList && (
        <ConfirmContentsListDialog
          cancelName="확인"
          isOpen={isOpenContentsList}
          s_datetime={startDate.format("YYYY-MM-DD")}
          e_datetime={endDate.format("YYYY-MM-DD")}
          dataType={dataType}
          selected={contentsInfo}
          databaseSend={databaseSend}
          handleCancel={handleContentsListCancel}
        />
      )}
      {defaultColMenuOpen && (
        <StyledMenu
          id="default-column-menu"
          anchorEl={defaultColMenuRef}
          keepMounted
          open={defaultColMenuOpen}
          onClose={onCloseCols}
          disableScrollLock={true}
          style={{ padding: 0, margin: 0 }}
        >
          <List style={{ margin: "0px", padding: "0px" }}>
            {onCheckedAllCols()}
            {originHypeCols &&
              originHypeCols.map((defaultCol) => {
                const idx = checkedHypeCols.findIndex(
                  (item) => item.name === defaultCol.name
                );
                const isChecked = idx !== -1;
                const labelId = `default-column-check-${defaultCol.name}`;

                return (
                  <div key={labelId}>
                    <Divider style={{ backgroundColor: "#e2e2e2" }} />
                    <ListItem
                      role={undefined}
                      dense
                      button
                      onClick={() => {
                        onCheckedDefaultCols(defaultCol);
                      }}
                      style={{
                        minWidth: "148px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <ListItemIcon style={{ minWidth: 0, marginLeft: "5px" }}>
                        <ColorCheckbox
                          edge="start"
                          checked={isChecked}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={defaultCol.name}
                        className={global_s.comboBoxLabel}
                      />
                    </ListItem>
                  </div>
                );
              })}
          </List>
        </StyledMenu>
      )}
      {isBackdrop && (
        <Backdrop className={contents_s.backdrop} open={isBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="stretch"
        style={{ marginBottom: "10px" }}
      >
        <Grid item sm container direction="row" alignItems="center">
          <Grid item>
            <Typography
              className={global_s.defaultText}
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#3c8dbc",
                marginRight: "10px",
              }}
            >
              {"Dashboard"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={global_s.defaultText}
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#77BBD4",
              }}
            >
              {"전체"}
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={dataType}
                onChange={handleChangeDataType}
                row
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      classes={{ label: global_s.defaultText }}
                      value={0}
                      control={<ColorRadio />}
                      label={
                        <Typography className={global_s.radioLabel}>
                          {`실시간`}
                        </Typography>
                      }
                      style={{ padding: 0, margin: 0 }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      classes={{ label: global_s.defaultText }}
                      value={1}
                      control={<ColorRadio />}
                      label={
                        <Typography className={global_s.radioLabel}>
                          {`테스트`}
                        </Typography>
                      }
                      style={{ padding: 0, margin: "0px 0px 0px 10px" }}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          sm
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ paddingRight: "10px" }}
        >
          <Grid item>
            <Button1
              id="today"
              className={
                selectDate === 1
                  ? contents_s.selectDate
                  : contents_s.defaultDate
              }
              onClick={handleDateChange}
            >
              {"오늘"}
            </Button1>
          </Grid>
          <Grid item>
            <Button1
              id="daily"
              className={
                selectDate === 2
                  ? contents_s.selectDate
                  : contents_s.defaultDate
              }
              onClick={handleDateChange}
            >
              {"일간"}
            </Button1>
          </Grid>
          <Grid item>
            <Button1
              id="weekly"
              className={
                selectDate === 3
                  ? contents_s.selectDate
                  : contents_s.defaultDate
              }
              onClick={handleDateChange}
            >
              {"주간"}
            </Button1>
          </Grid>
          <Grid item>
            <Button1
              id="monthly"
              className={
                selectDate === 4
                  ? contents_s.selectDate
                  : contents_s.defaultDate
              }
              onClick={handleDateChange}
            >
              {"월간"}
            </Button1>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            style={{
              width: "345px",
              padding: "0px 5px 0px 10px",
            }}
          >
            <Grid item>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <ThemeProvider theme={global_t.keyboardDatePickerTheme}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    autoOk
                    minDate="2020-01-01"
                    maxDate="2100-12-31"
                    value={startDate}
                    onChange={handleStartDateChange}
                    format="YYYY-MM-DD"
                    margin="none"
                    helperText={null}
                    KeyboardButtonProps={{
                      classes: {
                        root: global_s.keyboardDatePickerBtnRoot,
                      },
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      classes: {
                        root: global_s.keyboardDatePickerInputRoot,
                        input: global_s.keyboardDatePickerInput,
                      },
                    }}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item>
              <Typography
                className={global_s.defaultText}
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  margin: "0px 10px",
                }}
              >
                {"-"}
              </Typography>
            </Grid>
            <Grid item>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <ThemeProvider theme={global_t.keyboardDatePickerTheme}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    autoOk
                    minDate="2020-01-01"
                    maxDate="2100-12-31"
                    value={endDate}
                    onChange={handleEndDateChange}
                    format="YYYY-MM-DD"
                    margin="none"
                    helperText={null}
                    KeyboardButtonProps={{
                      classes: {
                        root: global_s.keyboardDatePickerBtnRoot,
                      },
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      classes: {
                        root: global_s.keyboardDatePickerInputRoot,
                        input: global_s.keyboardDatePickerInput,
                      },
                    }}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton1
              id="search"
              className={contents_s.searchBtn}
              onClick={handleDateChange}
              size="small"
            >
              <SearchIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item sm container direction="row" className={contents_s.cardRoot}>
          <Grid
            item
            xs
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ padding: "10px" }}
          >
            <Grid item xs>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#A48BF9",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    {totalCount}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#A48BF9",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {"개"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ padding: "2px" }}>
                <Divider
                  style={{
                    backgroundColor: "#DEE8EB",
                    padding: "0.5px",
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item container justify="center" alignItems="center">
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#8F8F8F",
                    }}
                  >
                    {"전체 등록 글"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ padding: "10px 0px" }}>
            <img src="/img/line.png" />
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ padding: "10px" }}
          >
            <Grid item xs>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#78D679",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    {eventCount}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#78D679",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {"개"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ padding: "2px" }}>
                <Divider
                  style={{
                    backgroundColor: "#DEE8EB",
                    padding: "0.5px",
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item container justify="center" alignItems="center">
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#8F8F8F",
                    }}
                  >
                    {"이벤트 발생 글"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ padding: "10px 0px" }}>
            <img src="/img/line.png" />
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ padding: "10px" }}
          >
            <Grid item xs>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#1A2226",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    {eventRatio}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#1A2226",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {"%"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ padding: "2px" }}>
                <Divider
                  style={{
                    backgroundColor: "#DEE8EB",
                    padding: "0.5px",
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item container justify="center" alignItems="center">
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#8F8F8F",
                    }}
                  >
                    {"전체 대비 비율"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm
          container
          direction="row"
          className={contents_s.cardRoot}
        />
        <Grid
          item
          sm
          container
          direction="row"
          className={contents_s.cardRoot}
        />
      </Grid>
      <Grid item container direction="row" justify="flex-start">
        <Grid
          item
          sm
          container
          direction="row"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            marginRight: "10px",
            marginBottom: "10px",
            borderRadius: "15px",
          }}
        >
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography
                className={global_s.defaultText}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#4FACCF",
                }}
              >
                {"이벤트 발생 변동 추이"}
              </Typography>
            </Grid>
            {/* <Grid item>
            <IconButton1 className={contents_s.shortcutsBtn} size="small">
              <ArrowForwardIcon />
            </IconButton1>
          </Grid> */}
          </Grid>
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              style={{
                width: "calc(100% - 100px)",
              }}
            >
              <Grid
                item
                xs
                style={{
                  height: "170px",
                  width: "calc(100% - 100px)",
                  paddingRight: "50px",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width="100%"
                    height="100%"
                    data={originGraph}
                    margin={{ bottom: 30 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name">
                      <Label value="날짜 및 시간" position="bottom" />
                    </XAxis>
                    <YAxis padding={{ top: 40 }}>
                      <Label value="개수" position="insideTopLeft" />
                    </YAxis>
                    <Tooltip />
                    {originEventCols.map((item) => {
                      return (
                        <Bar
                          dataKey={item.name}
                          stackId="a"
                          fill={item.color}
                        />
                      );
                    })}
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item>
                {Array.isArray(originEventCols) &&
                  originEventCols.map((defaultCol) => {
                    return (
                      <Grid
                        item
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item style={{ margin: "5px" }}>
                          <StopIcon style={{ color: defaultCol.color }} />
                        </Grid>
                        <Grid item>
                          <Typography
                            className={global_s.defaultText}
                            style={{ color: defaultCol.color }}
                          >
                            {defaultCol.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm
          container
          direction="column"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            marginRight: "10px",
            marginBottom: "10px",
            maxWidth: "700px",
            borderRadius: "15px",
          }}
        >
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography
                className={global_s.defaultText}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#4FACCF",
                }}
              >
                {"이벤트 비중"}
              </Typography>
            </Grid>
            {/* <Grid item>
            <IconButton1 className={contents_s.shortcutsBtn} size="small">
              <ArrowForwardIcon />
            </IconButton1>
          </Grid> */}
          </Grid>
          <Grid
            item
            xs
            container
            justify="center"
            alignItems="center"
            style={{ padding: "10px" }}
          >
            <Grid
              item
              xs
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                maxWidth: "400px",
              }}
            >
              <Grid
                item
                xs
                style={{
                  height: "170px",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width="100%" height="100%">
                    <Pie
                      fill="#8884d8"
                      dataKey="value"
                      outerRadius={80}
                      data={originPie_E}
                      label={PieLabel}
                      labelLine={false}
                      animationBegin={0}
                      animationDuration={1000}
                    >
                      {originPie_E.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs container direction="column">
                {originEventCols.map((temp) => {
                  return (
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item style={{ margin: "5px" }}>
                        <FiberManualRecordIcon style={{ color: temp.color }} />
                      </Grid>
                      <Grid item>
                        <Typography
                          className={global_s.defaultText}
                          style={{ color: temp.color }}
                        >
                          {temp.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="row" justify="flex-start">
        <Grid
          item
          sm
          container
          direction="column"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            marginRight: "10px",
            marginBottom: "10px",
            borderRadius: "15px",
          }}
        >
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography
                className={global_s.defaultText}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#4FACCF",
                }}
              >
                {"과장성 문장 추이"}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              container
              direction="row"
              justify="flex-end"
              alignItem="center"
            >
              <Grid item>
                <Button1
                  id="default-column-combo"
                  className={global_s.comboBoxRoot}
                  aria-controls="default-column-menu"
                  aria-haspopup="true"
                  endIcon={
                    <ArrowDropDownIcon
                      style={{
                        fontSize: "18px",
                        marginRight: "5px",
                        color: "#b9b9b9",
                      }}
                    />
                  }
                  onClick={handleDefaultCols}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    style={{ width: "85%" }}
                  >
                    <Grid item>
                      <Typography
                        className={global_s.comboBoxLabel}
                        noWrap={true}
                        style={{ padding: "0px 0px 0px 10px" }}
                      >
                        {"목록"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        className={global_s.comboBoxSubLabel}
                        noWrap={true}
                        style={{ marginLeft: "5px" }}
                      >
                        {checkedHypeCols.length}
                        &nbsp;/&nbsp;
                        {originHypeCols.length}
                      </Typography>
                    </Grid>
                  </Grid>
                </Button1>
              </Grid>
              {/* <Grid item>
              <IconButton1 className={contents_s.shortcutsBtn} size="small">
                <ArrowForwardIcon />
              </IconButton1>
            </Grid> */}
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              style={{
                width: "calc(100% - 100px)",
              }}
            >
              <Grid
                item
                xs
                style={{
                  height: "170px",
                  width: "calc(100% - 100px)",
                  paddingRight: "50px",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={checkedHype} margin={{ bottom: 30 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name">
                      <Label value="날짜 및 시간" position="bottom" />
                    </XAxis>
                    <YAxis padding={{ top: 40 }}>
                      <Label value="개수" position="insideTopLeft" />
                    </YAxis>
                    <Tooltip />
                    {originHypeCols.map((item) => {
                      return (
                        <Line
                          type="monotone"
                          dataKey={item.name}
                          stroke={item.color}
                          strokeWidth={2}
                        />
                      );
                    })}
                  </LineChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item>
                {Array.isArray(checkedHypeCols) &&
                  checkedHypeCols.map((defaultCol) => {
                    return (
                      <Grid
                        item
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item style={{ margin: "5px" }}>
                          <LinearScaleIcon
                            style={{ color: defaultCol.color }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            className={global_s.defaultText}
                            style={{ color: defaultCol.color }}
                          >
                            {defaultCol.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm
          container
          direction="column"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            marginRight: "10px",
            marginBottom: "10px",
            maxWidth: "700px",
            borderRadius: "15px",
          }}
        >
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography
                className={global_s.defaultText}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#4FACCF",
                }}
              >
                {"과장성 문장 비중"}
              </Typography>
            </Grid>
            {/* <Grid item>
            <IconButton1 className={contents_s.shortcutsBtn} size="small">
              <ArrowForwardIcon />
            </IconButton1>
          </Grid> */}
          </Grid>
          <Grid
            item
            xs
            container
            justify="center"
            alignItems="center"
            style={{ padding: "10px" }}
          >
            <Grid
              item
              xs
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                maxWidth: "400px",
              }}
            >
              <Grid
                item
                xs
                style={{
                  height: "170px",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={originPie_H}
                      innerRadius={50}
                      outerRadius={80}
                      paddingAngle={5}
                      fill="#8884d8"
                      dataKey="value"
                      label={PieLabel}
                      labelLine={false}
                      animationBegin={0}
                      animationDuration={1000}
                    >
                      {originPie_H.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs container direction="column">
                {originHypeCols.map((temp) => {
                  return (
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item style={{ margin: "5px" }}>
                        <FiberManualRecordIcon style={{ color: temp.color }} />
                      </Grid>
                      <Grid item>
                        <Typography
                          className={global_s.defaultText}
                          style={{ color: temp.color }}
                        >
                          {temp.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justify="space-between"
        direction="row"
        justify="flex-start"
      >
        <Grid
          item
          sm
          container
          direction="column"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            marginRight: "10px",
            marginBottom: "10px",
            minWidth: "400px",
            borderRadius: "15px",
          }}
        >
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography
                className={global_s.defaultText}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#4FACCF",
                }}
              >
                {"금칙어 기준 이벤트 발생"}
              </Typography>
            </Grid>
            <Grid item>
              <OutlinedInput1
                className={contents_s.searchText}
                value={bannedSearch}
                onChange={handleBannedSearch}
                placeholder={"조회"}
                endAdornment={
                  <InputAdornment position="end">
                    {bannedSearch.length > 0 ? ( // 입력 값의 길이에 따른 표시
                      <IconButton1 // 입력 값의 길이가 0보다 크다.
                        size="small"
                        onClick={handleBannedSearchClear}
                      >
                        <CloseIcon
                          className={contents_s.intputIcon}
                          style={{ color: "#a9a9a9" }}
                        />
                      </IconButton1>
                    ) : (
                      <SearchIcon // 입력 값의 길이가 0보다 작거나 같다.
                        className={contents_s.intputIcon}
                        style={{ color: "#a9a9a9" }}
                      />
                    )}
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              height: "172px",
              width: "100%",
              padding: "0px 10px 10px",
            }}
          >
            <div
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%" }}
            >
              <AgGridReact
                headerHeight={36}
                rowData={bannedRows}
                suppressMovableColumns={true} // columns 이동 금지
                overlayLoadingTemplate={"<span></span>"} // 데이터 로딩시 표시 화면
                overlayNoRowsTemplate={"<span></span>"} // 데이터 없을 경우 표시 화면
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  cellClass: global_s.defaultGridCell,
                  headerClass: global_s.defaultGridHeader,
                }}
                onGridReady={onBannedGridReady}
              >
                {bannedColumnDef.map((columnItem) => {
                  return (
                    <AgGridColumn
                      key={columnItem.field}
                      headerName={columnItem.headerName}
                      field={columnItem.field}
                      hide={columnItem.hide}
                      width={columnItem.width}
                      tooltipField={columnItem.tooltipField}
                      sortable={columnItem.sortable}
                      resizable={columnItem.resizable}
                      checkboxSelection={columnItem.checkboxSelection}
                      cellRenderer={columnItem.cellRenderer}
                    />
                  );
                })}
              </AgGridReact>
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          sm
          container
          direction="column"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            marginRight: "10px",
            marginBottom: "10px",
            borderRadius: "15px",
            maxWidth: "700px",
          }}
        >
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography
                className={global_s.defaultText}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#4FACCF",
                }}
              >
                {"판매자 기준 이벤트 발생"}
              </Typography>
            </Grid>
            <Grid item>
              <OutlinedInput1
                className={contents_s.searchText}
                value={sellerSearch}
                onChange={handleSellerSearch}
                placeholder={"조회"}
                endAdornment={
                  <InputAdornment position="end">
                    {sellerSearch.length > 0 ? ( // 입력 값의 길이에 따른 표시
                      <IconButton1 // 입력 값의 길이가 0보다 크다.
                        size="small"
                        onClick={handleSellerSearchClear}
                      >
                        <CloseIcon
                          className={contents_s.intputIcon}
                          style={{ color: "#a9a9a9" }}
                        />
                      </IconButton1>
                    ) : (
                      <SearchIcon // 입력 값의 길이가 0보다 작거나 같다.
                        className={contents_s.intputIcon}
                        style={{ color: "#a9a9a9" }}
                      />
                    )}
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              height: "172px",
              width: "100%",
              padding: "0px 10px 10px",
            }}
          >
            <div
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%" }}
            >
              <AgGridReact
                headerHeight={36}
                rowData={sellerRows}
                rowStyle={{ cursor: "pointer" }}
                suppressMovableColumns={true} // columns 이동 금지
                overlayLoadingTemplate={"<span></span>"} // 데이터 로딩시 표시 화면
                overlayNoRowsTemplate={"<span></span>"} // 데이터 없을 경우 표시 화면
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  cellClass: global_s.defaultGridCell,
                  headerClass: global_s.defaultGridHeader,
                }}
                onGridReady={onSellerGridReady}
                onRowDoubleClicked={onSellerRowDoubleClicked}
              >
                {sellerColumnDef.map((columnItem) => {
                  return (
                    <AgGridColumn
                      key={columnItem.field}
                      headerName={columnItem.headerName}
                      field={columnItem.field}
                      hide={columnItem.hide}
                      width={columnItem.width}
                      tooltipField={columnItem.tooltipField}
                      sortable={columnItem.sortable}
                      resizable={columnItem.resizable}
                      checkboxSelection={columnItem.checkboxSelection}
                    />
                  );
                })}
              </AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TotalDashboard;
