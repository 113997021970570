import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import { Button1, IconButton1 } from "../components";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: 'bold',
    color: "#ffffff",
  },
  content2: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    color: "#ffffff",
    backgroundColor: "#999999",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#565656",
    },
  },
  btn_label: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#message-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function MessageDialog(props) {
  const s_header = headerStyles();
  const s_content = contentStyles();

  const handleClose = () => {
    props.onCloseMessageDlg();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={props.isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="message-dialog-title"
    >
      <DialogTitle className={s_header.titlebar} id="message-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "36px" }}
        >
          <Grid item xs={10} container direction="row" alignItems="center">
            <Typography className={s_header.title}>{props.title}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <IconButton1
              size="small"
              aria-label="close"
              onClick={handleClose}
              style={{ color: "#ffffff" }}
            >
              <CloseIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginTop: "10px" }}
            >
              <Typography className={s_header.content2}>
                {props.content}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              style={{ marginTop: "10px" }}
            >
              <Button1 className={s_content.btn} onClick={handleClose}>
                <Typography className={s_content.btn_label}>
                  {props.closeName}
                </Typography>
              </Button1>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default MessageDialog;
