import { createMuiTheme } from "@material-ui/core";
import { lightBlue } from "@material-ui/core/colors";

const keyboardDatePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: lightBlue.A200,
        outline: "none !important",
        boxShadow: "none",
      },
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: lightBlue["400"],
        },
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          " border-bottom": "2px solid " + lightBlue["400"],
        },
      },
    },
    MuiIconButton: {
      root: {
        outline: "none !important",
        boxShadow: "none",
      },
    },
    MuiPickersYear: {
      root: {
        "&:focus": {
          color: lightBlue["400"],
        },
      },
    },
    MuiTypography: {
      subtitle1: {
        outline: "none !important",
        boxShadow: "none",
      },
      colorPrimary: {
        color: lightBlue["400"],
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        outline: "none !important",
        boxShadow: "none",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        outline: "none !important",
        boxShadow: "none",
      },
    },
    MuiPickersDay: {
      day: {
        color: lightBlue.A700,
        outline: "none !important",
        boxShadow: "none",
      },
      daySelected: {
        "&:hover": {
          backgroundColor: lightBlue["400"],
        },
        backgroundColor: lightBlue["400"],
        outline: "none !important",
        boxShadow: "none",
      },
      dayDisabled: {
        color: lightBlue["400"],
      },
      current: {
        color: lightBlue["400"],
        outline: "none !important",
        boxShadow: "none",
      },
    },
    MuiPickersClock: {
      pin: {
        "background-color": lightBlue["400"],
      },
    },
    MuiPickersClockPointer: {
      thumb: {
        border: "14px solid " + lightBlue["400"],
      },
      pointer: {
        "background-color": lightBlue["400"],
      },
      noPoint: {
        "background-color": lightBlue["400"],
      },
    },
  },
});

export const global_theme = {
  keyboardDatePickerTheme: keyboardDatePickerTheme,
};
