import React, { useEffect, useState } from "react";
import { useStyles } from "../containers/style.js";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Paper,
  Backdrop,
  Typography,
  DialogActions,
  InputAdornment,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import Draggable from "react-draggable";

import { Button1, IconButton1, OutlinedInput1, MessageDialog } from ".";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content1: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#565656",
    padding: "20px 0px 20px",
  },
  content2: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    border: "1px solid #999999",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#999999",
  },
  label1: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#292929",
  },
  label2: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#3C8DBC",
  },
  label3: {
    fontSize: "24px",
    fontFamily: "Noto Sans",
    color: "#3C8DBC",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#confirm-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ConfirmHypeStandardDialog(props) {
  const header_s = headerStyles();
  const global_s = useStyles();
  const contents_s = contentStyles();

  const [isBackdrop, setIsBackdrop] = useState(false);

  const [hypeStandard, setHypeStandard] = useState(0);

  const [messageContent, setMessageContent] = useState("");
  const [isOpenMessage, setIsOpenMessage] = useState(false);

  useEffect(() => {
    if (props.databaseSend) databaseSearch();
  }, []);

  const databaseSearch = () => {
    setIsBackdrop(true);
    props.databaseSend(
      "api/header/hypeStandard",
      {
        type_of_information: "hype_standard",
      },
      (res) => {
        setIsBackdrop(false);
        setHypeStandard(res.hype_standard ? res.hype_standard : 0);
      }
    );
  };

  const handleHypeStandard = (event) => {
    setHypeStandard(event.target.value.replace(/[^0-9]/g, "").substr(0, 3));
  };

  const handleSave = () => {
    let hype_standard = parseInt(hypeStandard);
    if (hype_standard < 0 || hype_standard > 100) {
      setMessageContent("0 ~ 100 사이의 정수만 입력바랍니다.");
      setIsOpenMessage(true);
      return;
    }

    setIsBackdrop(true);
    props.databaseSend(
      "hypeStandardUpdate",
      {
        type_of_information: "hype_standard_update",
        hype_standard: hype_standard,
      },
      (res) => {
        setIsBackdrop(false);
        if (!res) {
          setMessageContent("과장성 기준 업데이트를 실패했습니다.");
          setIsOpenMessage(true);
          return;
        }
        props.handleCancel();
      }
    );
  };

  const handleCancel = () => {
    props.handleCancel();
  };

  const handleMessageCancel = () => {
    setIsOpenMessage(false);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth={true}
      open={props.isOpen}
      onClose={handleCancel}
      PaperComponent={PaperComponent}
      aria-labelledby="confirm-dialog-title"
    >
      {isOpenMessage && (
        <MessageDialog
          closeName={"확인"}
          content={messageContent}
          isOpen={isOpenMessage}
          onCloseMessageDlg={handleMessageCancel}
        />
      )}
      {isBackdrop && (
        <Backdrop className={contents_s.backdrop} open={isBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <DialogTitle className={header_s.titlebar} id="confirm-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "36px" }}
        >
          <Grid item xs={10} container direction="row" alignItems="center">
            <Typography className={header_s.title}>{props.title}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <IconButton1
              size="small"
              aria-label="close"
              onClick={handleCancel}
              style={{ color: "#ffffff" }}
            >
              <CloseIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: "0px 10px 10px" }}>
        <Grid container direction="column">
          <Grid
            item
            container
            direction="column"
            style={{ margin: "10px 0px 0px" }}
          >
            <Grid item>
              <Typography className={contents_s.label2}>
                {"과장성 기준"}
              </Typography>
            </Grid>
            <Grid item style={{ margin: "5px 10px 0px" }}>
              <Typography className={contents_s.label1}>
                {"※ 과장성 확률이 설정된 기준보다 크거나 같으면 과장으로 판단"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs
            alignItems="center"
            style={{ margin: "10px 0px 0px" }}
          >
            <Grid item xs>
              <OutlinedInput1
                value={hypeStandard}
                onChange={handleHypeStandard}
                placeholder={"(0 ~ 100) 숫자만 입력 가능"}
                style={{
                  fontSize: 16,
                  padding: "5px",
                  maxWidth: "100%",
                  minWidth: "100%",
                  backgroundColor: "white",
                }}
              />
            </Grid>
            <Grid item style={{ marginLeft: "10px" }}>
              <Typography className={contents_s.label3}>{"%"}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button1 className={contents_s.btn} onClick={handleSave}>
              <Typography className={contents_s.btn_label}>
                {props.okName}
              </Typography>
            </Button1>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <Button1 className={contents_s.btn} onClick={handleCancel}>
              <Typography className={contents_s.btn_label}>
                {props.cancelName}
              </Typography>
            </Button1>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmHypeStandardDialog;
