import React, { useState, useEffect, createRef } from "react";
import { useDispatch } from "react-redux";

import { Grid, Grow, Typography } from "@material-ui/core";
import { useStyles } from "../style.js";
import {
  Button1,
  IconButton1,
  SnackMessage,
  ConfirmContentsListDetailDialog,
} from "../../components";
import { makeStyles } from "@material-ui/core/styles";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { SendQuery } from "../../actions/send";
import { LogOut, Update_Alarm } from "../../actions/auth";

import moment from "moment-timezone";

import { setResetTimeVal, onEventRenderer } from "../utils";
import { useSnackbar } from "notistack";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const contents_Styles = makeStyles((theme) => ({
  arrowIcon: {
    fontSize: "24px",
    color: "white",
  },
  warningBtn: {
    padding: "2px",
    margin: "0px 10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  warningIcon: {
    fontSize: "20px",
    color: "#f6bf49",
  },
  warningLabel: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    marginLeft: "5px",
    color: "white",
  },
  warningText: {
    fontFamily: "Noto Sans",
    fontSize: "12px",
    marginLeft: "5px",
    color: "white",
  },
  constentsBtn: {
    minWidth: "100%",
    padding: "5px",
  },
  contentsText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    color: "black",
  },
  defaultGridCell: {
    "justify-content": "flex-start",
    "align-items": "left",
    border: "none !important",
    fontFamily: "Noto Sans",
    fontSize: "13px",
    "text-align": "left",
  },
}));

let refresh = false;
let old_event = undefined;
let old_notice = undefined;
let last_check_date = undefined;
let connect_time = undefined;
function Console(props) {
  const dispatch = useDispatch();
  const global_s = useStyles();
  const contents_s = contents_Styles();
  const { enqueueSnackbar } = useSnackbar();

  const consoleURL = "api/console";
  const [isHidden, setIsHidden] = useState(true);
  const [newEvent, setNewEvent] = useState("");
  const [selected, setSelectData] = useState(undefined);
  const [backTimer, setBackTimer] = useState(1000);

  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [noticeInfo, setNoticeInfo] = useState([]);

  const [eventRows, setEventRows] = useState([]);
  const [eventGridApi, setEventGridApi] = useState(undefined);
  const [eventGridColumnApi, setEventGridColumnApi] = useState(undefined);
  const eventColumnDef = [
    {
      field: "contents",
      headerName: "내용",
      width: 1630,
      sortable: true,
      resizable: true,
      cellRenderer: onEventRenderer,
    },
  ];

  useEffect(() => {
    const start = setInterval(() => {
      if (parseInt(backTimer) > 0) {
        setBackTimer(parseInt(backTimer) - 1);
      }
      if (parseInt(backTimer) === 0) {
        setBackTimer(1000);
      }

      if (!refresh) search_event();
    }, 10000);
    return () => clearInterval(start);
  }, [backTimer]);

  useEffect(() => {
    dispatch(Update_Alarm([]));
    search_event();
    connect_time = moment().local();
  }, []);

  useEffect(() => {
    if (!selected) setIsDetailOpen(false);
    else setIsDetailOpen(true);
  }, [selected]);

  useEffect(() => {
    if (!props.auth.isDesignated) {
      showSnackBar([...noticeInfo]);

      let alarm_info = [...props.auth.alarm_info];

      noticeInfo.forEach((notice) => {
        let find = alarm_info.find((alarm) => {
          if (
            notice.pkey === alarm.pkey &&
            notice.parents_pkey === alarm.parents_pkey
          )
            return alarm;
        });

        if (!find) alarm_info.push(notice);
      });

      alarm_info = alarm_info.sort(
        (a, b) => b.created_time.valueOf() - a.created_time.valueOf()
      );

      dispatch(Update_Alarm(alarm_info));
    }
  }, [noticeInfo]);

  const databaseSend = (location, data, callback) => {
    SendQuery(location, props.auth.user_info, data, (err, res) => {
      if (err || !res) return callback(null);

      if (res.data && res.data.code === 500) {
        return dispatch(LogOut());
      }

      return callback(res.data);
    });
  };

  const showSnackBar = (designated_list) => {
    if (designated_list.length < 1) return;
    let designated = designated_list.shift();

    enqueueSnackbar("Your notification here", {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      TransitionComponent: Grow,
      content: (key) => (
        <SnackMessage
          id={key}
          designated={designated}
          onOpenConfirmDlg={onOpenConfirmDlg}
        />
      ),
    });

    if (designated_list.length > 0) return showSnackBar(designated_list);
  };

  const search_event = () => {
    refresh = true;
    if (!last_check_date)
      last_check_date = setResetTimeVal(moment().local().format())
        .tz("UTC")
        .format("YYYY-MM-DD HH:mm:ss.SSS");

    SendQuery(
      "api/dashboard/event",
      props.auth.user_info,
      { start_date: last_check_date },
      (err, res) => {
        if (err || !res) {
          refresh = false;
          return;
        }

        if (res.data && res.data.code === 500) {
          refresh = false;
          return dispatch(LogOut());
        }

        let notice_info = [];
        let event_info = old_event ? [...old_event] : [];
        res.data.event_list.forEach((event) => {
          let idx = event_info.findIndex((val) => {
            if (
              val.pkey === event.pkey &&
              val.parents_pkey === event.parents_pkey &&
              val.t_updated_time === event.t_updated_time
            )
              return val;
          });

          if (idx !== -1) return;

          event.created_time = moment.utc(event.created_time).local();
          event.seller = `${event.user_id}(${event.name ? event.name : "-"})`;
          event.updated_time = moment.utc(event.t_updated_time).local();

          event_info.push(event);
          if (
            event.designated &&
            connect_time.valueOf() <= event.updated_time.valueOf()
          )
            notice_info.push(event);
        });

        notice_info = notice_info.sort(
          (a, b) => b.updated_time.valueOf() - a.updated_time.valueOf()
        );

        event_info = event_info.sort(
          (a, b) => b.updated_time.valueOf() - a.updated_time.valueOf()
        );

        if (event_info.length > 0) {
          let recent_date = event_info[0].t_updated_time;
          if (last_check_date !== recent_date) last_check_date = recent_date;

          let event_txt = `[${moment
            .utc(event_info[0].t_updated_time)
            .local()
            .format("YYYY-MM-DD HH:mm:ss")}] ${event_info[0].user_id}(${
            event_info[0].name ? event_info[0].name : "-"
          })님이 작성한 ${event_info[0].parents_pkey ? "댓글" : "게시글"}에서 ${
            event_info[0].t_hype > 0 ? `과장성` : ""
          }${
            event_info[0].t_banned > 0 && event_info[0].t_hype > 0 ? "과 " : ""
          }${event_info[0].t_banned > 0 ? `금칙어가` : "이"} 발견되었습니다.`;

          if (!newEvent) setNewEvent(event_txt);
          else {
            if (
              event_info[0].pkey !== newEvent.pkey &&
              event_info[0].parents_pkey !== newEvent.parents_pkey &&
              event_info[0].t_updated_time !== newEvent.t_updated_time
            )
              setNewEvent(event_txt);
          }
        }

        old_event = event_info;
        old_notice = notice_info;
        setEventRows([...old_event]);
        setNoticeInfo([...old_notice]);
        refresh = false;
      }
    );
  };

  const handleHidden = (event) => {
    setIsHidden(!isHidden);
    props.handleConsole(!isHidden);
  };

  const onEventGridReady = (params) => {
    setEventGridApi(params.api);
    setEventGridColumnApi(params.columnApi);
  };

  const onEventRowDoubleClicked = (params) => {
    if (params) setSelectData(params.data);
  };

  const handleDetailCancel = () => {
    setSelectData(undefined);
  };

  const onOpenConfirmDlg = (event) => {
    if (Array.isArray(props.auth.alarm_info)) {
      let alarm_info = [...props.auth.alarm_info];
      let idx = alarm_info.findIndex((alarm) => {
        if (
          alarm.created_time === event.created_time &&
          alarm.pkey === event.pkey &&
          alarm.parents_pkey === event.parents_pkey
        )
          return alarm;
      });

      if (idx !== -1) alarm_info.slice(idx, 1);

      dispatch(Update_Alarm(alarm_info));
    }

    setSelectData(event);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={{
        padding: "5px 10px",
        borderRadius: "20px 20px 0px 0px",
        backgroundColor: "#5C809C",
      }}
    >
      {isDetailOpen && (
        <ConfirmContentsListDetailDialog
          cancelName="확인"
          isOpen={isDetailOpen}
          selected={selected}
          databaseSend={databaseSend}
          handleCancel={handleDetailCancel}
        />
      )}
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography className={contents_s.warningLabel}>
                {"이벤트 창"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid container justify="center">
            <Grid item>
              {isHidden ? (
                <Button1
                  className={contents_s.warningBtn}
                  onClick={handleHidden}
                  style={{ maxWidth: "calc(100vw - 500px)" }}
                >
                  <Typography className={contents_s.warningText} noWrap={true}>
                    {newEvent}
                  </Typography>
                </Button1>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton1
            size="small"
            onClick={handleHidden}
            style={{ padding: "0px" }}
          >
            {isHidden ? (
              <KeyboardArrowUpIcon className={contents_s.arrowIcon} />
            ) : (
              <KeyboardArrowDownIcon className={contents_s.arrowIcon} />
            )}
          </IconButton1>
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          maxHeight: "200px",
          overflow: "auto",
          backgroundColor: "#f9f9f9",
          borderRadius: "10px",
        }}
      >
        <div
          className="ag-theme-alpine"
          style={{ height: isHidden ? "0px" : "200px", width: "100%" }}
        >
          <AgGridReact
            rowData={eventRows}
            rowStyle={{ cursor: "pointer" }}
            defaultColDef={{
              sortable: true,
              resizable: true,
              cellClass: contents_s.defaultGridCell,
              headerClass: global_s.defaultGridHeader,
            }}
            overlayLoadingTemplate={"<span></span>"}
            overlayNoRowsTemplate={"<span></span>"}
            headerHeight={0}
            suppressMovableColumns={true} // columns 이동 금지
            suppressRowClickSelection={true}
            onGridReady={onEventGridReady}
            onRowDoubleClicked={onEventRowDoubleClicked}
          >
            {eventColumnDef.map((columnItem) => {
              return (
                <AgGridColumn
                  key={columnItem.field}
                  headerName={columnItem.headerName}
                  field={columnItem.field}
                  hide={columnItem.hide}
                  width={columnItem.width}
                  tooltipField={columnItem.tooltipField}
                  sortable={columnItem.sortable}
                  resizable={columnItem.resizable}
                  checkboxSelection={columnItem.checkboxSelection}
                  cellRenderer={columnItem.cellRenderer}
                />
              );
            })}
          </AgGridReact>
        </div>
      </Grid>
    </Grid>
  );
}

export default Console;
