import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "../../../style.js";

import { Grid, Backdrop, Typography, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Button1, IconButton1, OutlinedInput1 } from "../../../../components";

import { handleSearch, wordValidator, beforeChange } from "../../../utils";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.css";

import moment from "moment-timezone";
import { SendQuery } from "../../../../actions/send";
import { LogOut } from "../../../../actions/auth";

import "../index.css";
import * as _ from "lodash";
import * as excel from "exceljs";

const contentStyles = makeStyles((theme) => ({
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#999999",
    margin: "3px 8px",
  },
  refreshLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  menuBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#62A4BC",
    "&:hover": {
      backgroundColor: "#62A4BC",
    },
    "&:disabled": {
      backgroundColor: "#999999",
    },
  },
  csvLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  editLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  editBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#62A4BC",
    "&:hover": {
      backgroundColor: "#62A4BC",
    },
  },
  saveBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#00CC00",
    "&:hover": {
      backgroundColor: "#009C00",
    },
  },
  disableCell: {
    color: "#ffffff",
    backgroundColor: "#a9a9a9",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let hotTableRef = undefined;
let hiddenFileInput = undefined;
function TotalManagement(props) {
  const dispatch = useDispatch();
  const global_s = useStyles();
  const contents_s = contentStyles();

  const bannedURL = "api/administer/banned";
  const [csvLabel, setCSVLabel] = useState("내보내기");
  const [isEdit, setIsEdit] = useState(false);
  const [search, setSearch] = useState("");

  const tableHeaders = [
    "고유키",
    "등록일",
    "등록 아이디",
    "수정일",
    "수정 아이디",
    "종류",
    "단어1",
    "단어2",
    "단어3",
    "단어4",
    "단어5",
  ];
  const [tableData, setTableData] = useState([]);
  const [tableSettings, setTableSettings] = useState({
    colHeaders: tableHeaders,
    colWidths: 150,
    editor: false,
    readOnly: true,
    autoWrapRow: true,
    columnSorting: true,
    manualRowResize: true,
    manualColumnResize: true,
    stretchH: "last",
    className: "htCenter htMiddle",
    licenseKey: "f828d-946f3-731a2-84125-6903b",
    hiddenColumns: {
      columns: [0],
    },
    hiddenRows: {
      copyPasteEnabled: true,
    },
    search: {
      callback: handleSearch,
    },
    cells: (row, col) => {
      const cellProperties = {};
      if (col < 5) cellProperties.className = "disable-cell";
      return cellProperties;
    },
  });

  const [isBackdrop, setIsBackdrop] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);

  if (!hotTableRef) hotTableRef = React.createRef();

  if (!hiddenFileInput) hiddenFileInput = React.createRef();

  if (props.getSidebarState() !== openSidebar)
    setOpenSidebar(props.getSidebarState());

  useEffect(() => {
    databaseSearch();
  }, []);

  useEffect(() => {
    onRefreshTable(400);
  }, [openSidebar]);

  const onRefreshTable = (delayTime) => {
    setTimeout(() => {
      if (hotTableRef && hotTableRef.current) {
        hotTableRef.current.hotInstance.getPlugin("search").query(search);
        hotTableRef.current.hotInstance.render();
      }
    }, delayTime);
  };

  const range = (size, start) => {
    return Array(size)
      .fill(start)
      .map((x, y) => x + y);
  };

  useEffect(() => {
    if (hotTableRef) {
      hotTableRef.current.hotInstance.getPlugin("search").query(search);
      hotTableRef.current.hotInstance.render();
    }
  }, [search]);

  const updateTable = (data) => {
    data = JSON.parse(JSON.stringify(data));
    if (isEdit) data.push({});
    setTableSettings((prevState) => ({
      ...prevState,
      ["data"]: data,
      ["columns"]: [
        {
          data: "pkey",
        },
        {
          data: "created_time",
        },
        {
          data: "created_user",
        },
        {
          data: "updated_time",
        },
        {
          data: "updated_user",
        },
        {
          data: "kind",
        },
        {
          data: "word1",
        },
        {
          data: "word2",
        },
        {
          data: "word3",
        },
        {
          data: "word4",
        },
        {
          data: "word5",
        },
      ],
      ["beforeChange"]: beforeChange,
    }));
  };

  const databaseSend = (location, data, callback) => {
    SendQuery(
      `${bannedURL}/${location}`,
      props.auth.user_info,
      data,
      (err, res) => {
        if (err || !res) return callback(null);

        if (res.data && res.data.code === 500) {
          return dispatch(LogOut());
        }

        switch (data.type_of_information) {
          case "search":
          case "update":
            return callback(res.data);
        }
      }
    );
  };

  const databaseSearch = () => {
    setIsBackdrop(true);
    databaseSend(
      "search",
      { type_of_information: "search", sort: 0 },
      // sort : 0 - 전체 / 1 - 금지어 / 2 - 주의어 / 3 - 조합어
      (res) => {
        setIsBackdrop(false);
        if (!res) {
          return;
        }

        let new_words = res.words.map((word) => {
          switch (word.sort) {
            case 1:
              word.kind = "금지어";
              break;
            case 2:
              word.kind = "주의어";
              break;
            case 3:
              word.kind = "조합어";
              break;
            default:
              word.kind = "-";
              break;
          }
          if (word.created_time)
            word.created_time = moment
              .utc(word.created_time)
              .local()
              .format("YYYY-MM-DD HH:mm:ss");
          if (word.updated_time)
            word.updated_time = moment
              .utc(word.updated_time)
              .local()
              .format("YYYY-MM-DD HH:mm:ss");

          return word;
        });

        new_words = new_words.sort((a, b) => {
          return (
            moment(a.created_time).valueOf() - moment(b.created_time).valueOf()
          );
        });

        setTableData(new_words);
        if (new_words.length > 0) updateTable(new_words);
        else updateTable([]);
      }
    );
  };

  const handleSearchChange = (event) => {
    if (hotTableRef) {
      if (event.target.value && event.target.value.length > 0)
        setTableSettings({
          ...tableSettings,
          ["hiddenRows"]: {
            copyPasteEnabled: true,
            rows: range(hotTableRef.current.hotInstance.countRows(), 0),
          },
        });
      else
        setTableSettings({
          ...tableSettings,
          ["hiddenRows"]: {
            copyPasteEnabled: true,
            rows: [],
          },
        });
    }
    setSearch(event.target.value);
  };

  const handleSearchClear = () => {
    if (hotTableRef) {
      setTableSettings({
        ...tableSettings,
        ["hiddenRows"]: {
          copyPasteEnabled: true,
          rows: [],
        },
      });
    }
    setSearch("");
  };

  const handleCSV = () => {
    if (isEdit) {
      if (hiddenFileInput) hiddenFileInput.current.click();
    } else {
      setIsBackdrop(true);
      let workbook = new excel.Workbook();
      let sheet = workbook.addWorksheet("조합어");

      sheet.columns = [
        { header: "종류", key: "kind" },
        { header: "단어1", key: "word1" },
        { header: "단어2", key: "word2" },
        { header: "단어3", key: "word3" },
        { header: "단어4", key: "word4" },
        { header: "단어5", key: "word5" },
      ];
      hotTableRef.current.hotInstance.getData().forEach((val) => {
        if (val[5] && val[6])
          sheet.addRow({
            kind: val[5],
            word1: val[6],
            word2: val[7],
            word3: val[8],
            word4: val[9],
            word5: val[10],
          });
      });

      workbook.xlsx
        .writeBuffer()
        .then((data) => {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${moment().local().format("YYYY-MM-DD HH:mm:ss")}_전체.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          setIsBackdrop(false);
        })
        .catch((e) => {
          setIsBackdrop(false);
        });
    }
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      style={{ padding: "70px" }}
    >
      {isBackdrop && (
        <Backdrop className={contents_s.backdrop} open={isBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid
        item
        xs
        container
        alignItems="center"
        style={{ margin: "0px 0px 5px" }}
      >
        <Grid item>
          <Typography
            className={global_s.defaultText}
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#3c8dbc",
              marginRight: "10px",
            }}
          >
            {"금칙어 관리"}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={global_s.defaultText}
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "#77BBD4",
            }}
          >
            {"전체"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ margin: "0px 0px 5px" }}
      >
        <Grid item xs container direction="row" alignItems="center">
          <Grid item>
            <Button1 className={contents_s.menuBtn} onClick={databaseSearch}>
              <Typography className={contents_s.refreshLabel}>
                {"새로고침"}
              </Typography>
            </Button1>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <Button1 className={contents_s.menuBtn} onClick={handleCSV}>
              <Typography className={contents_s.csvLabel}>
                {csvLabel}
              </Typography>
            </Button1>
          </Grid>
        </Grid>
        <Grid
          item
          xs
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item xs style={{ maxWidth: "500px" }}>
            <OutlinedInput1
              value={search}
              onChange={handleSearchChange}
              placeholder={"조회"}
              style={{
                fontSize: 12,
                backgroundColor: "white",
                padding: "5px",
                height: "32px",
                borderRadius: "0px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  {search.length > 0 ? (
                    <IconButton1 size="small" onClick={handleSearchClear}>
                      <CloseIcon style={{ color: "#a9a9a9" }} />
                    </IconButton1>
                  ) : (
                    <SearchIcon style={{ color: "#a9a9a9" }} />
                  )}
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs style={{ margin: "2px 0px" }}>
        <div style={{ height: "100%", width: "100%" }}>
          <HotTable
            id="banned-total-table"
            ref={hotTableRef}
            settings={tableSettings}
            width={"100%"}
            height={662}
            style={{
              border: "1px solid #a9a9a9",
              backgroundColor: "#ffffff",
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default TotalManagement;
