import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

export const IconButton1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
  },
}))(IconButton);
