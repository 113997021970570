import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export const TextField1 = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        margin: "0px",
      },
      "&:hover fieldset": {
        borderColor: "#7ec5f8",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#7ec5f8",
      },
      "& input": {
        fontSize: "14px",
        margin: " 0px",
      },
      height: "32px",
      color: "#696969",
      padding: "0px",
      margin: " 0px",
    },
  },
}))(TextField);
