import { Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const ColorRadio = withStyles((theme) => ({
  root: {
    color: "#00b5ef",
    "&$checked": {
      color: "#00b5ef",
    },
    width: "22px",
    height: "22px",
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);
