import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from "../../style.js";
import { Grid, Typography, InputAdornment, Icon } from "@material-ui/core";

import {
  Button1,
  IconButton1,
  OutlinedInput1,
  ConfirmDialog,
} from "../../../components";
import { range, handleSearch, wordValidator, beforeChange } from "../../utils";

import SearchIcon from "@material-ui/icons/Search";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.css";

const contentStyles = makeStyles((theme) => ({
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#999999",
    margin: "3px 8px",
  },
  refreshLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  menuBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#62A4BC",
    "&:hover": {
      backgroundColor: "#62A4BC",
    },
    "&:disabled": {
      backgroundColor: "#999999",
    },
  },
  csvLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  editLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  editBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#62A4BC",
    "&:hover": {
      backgroundColor: "#62A4BC",
    },
  },
  saveBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#00CC00",
    "&:hover": {
      backgroundColor: "#009C00",
    },
  },
}));

let hotTableRef = undefined;
function AIManagement(props) {
  const global_s = useStyles();
  const contents_s = contentStyles();

  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editLabel, setEditLabel] = useState("편집 시작");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isOkCancelOpen, setIsOkCancelOpen] = useState(false);

  const tableHeaders = [
    "등록일",
    "등록 아이디",
    "수정일",
    "수정 아이디",
    "단어",
  ];
  const [tableSettings, setTableSettings] = useState({
    colHeaders: tableHeaders,
    colWidths: 170,
    autoWrapRow: true,
    columnSorting: true,
    manualRowResize: true,
    manualColumnResize: true,
    stretchH: "last",
    className: "htCenter htMiddle",
    licenseKey: "f828d-946f3-731a2-84125-6903b",
    hiddenColumns: {
      columns: [0],
    },
    hiddenRows: {
      copyPasteEnabled: true,
    },
    search: {
      callback: handleSearch,
    },
    cells: (row, col) => {
      const cellProperties = {};
      if (col < 5) cellProperties.className = "disable-cell";
      return cellProperties;
    },
  });

  if (!hotTableRef) hotTableRef = React.createRef();

  useEffect(() => {
    setEditLabel(isEdit ? "편집 완료" : "편집 시작");

    handleSearchClear();
  }, [isEdit]);

  const updateTable = (data) => {
    data = JSON.parse(JSON.stringify(data));
    if (isEdit) data.push({});
    setTableSettings((prevState) => ({
      ...prevState,
      ["data"]: data,
      ["columns"]: [
        {
          data: "pkey",
          editor: false,
          readOnly: true,
        },
        {
          data: "created_time",
          editor: false,
          readOnly: true,
        },
        {
          data: "created_user",
          editor: false,
          readOnly: true,
        },
        {
          data: "updated_time",
          editor: false,
          readOnly: true,
        },
        {
          data: "updated_user",
          editor: false,
          readOnly: true,
        },
        {
          data: "word1",
          validator: wordValidator,
          readOnly: isEdit ? false : true,
        },
      ],
      ["beforeChange"]: beforeChange,
      ["minSpareRows"]: isEdit ? 1 : null,
    }));
  };

  const databaseSearch = () => {};
  const handleCSV = () => {};

  const handleEdit = () => {
    if (isEdit) setIsOkCancelOpen(true);
    else setIsEdit(!isEdit);
  };

  const handleSearchChange = (event) => {
    if (hotTableRef && hotTableRef.current) {
      if (event.target.value && event.target.value.length > 0)
        setTableSettings({
          ...tableSettings,
          ["hiddenRows"]: {
            copyPasteEnabled: true,
            rows: range(
              isEdit
                ? hotTableRef.current.hotInstance.countRows() - 1
                : hotTableRef.current.hotInstance.countRows(),
              0
            ),
          },
        });
      else
        setTableSettings({
          ...tableSettings,
          ["hiddenRows"]: {
            copyPasteEnabled: true,
            rows: [],
          },
        });
    }
    setSearch(event.target.value);
  };

  const handleSearchClear = () => {
    if (hotTableRef) {
      setTableSettings({
        ...tableSettings,
        ["hiddenRows"]: {
          copyPasteEnabled: true,
          rows: [],
        },
      });
    }
    setSearch("");
  };

  const onOkConfirmDlg = () => {
    setIsOkCancelOpen(false);
  };

  const onCancelConfirmDlg = () => {
    setIsEdit(false);
    setIsOkCancelOpen(false);
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      style={{ padding: "70px" }}
    >
      {isOkCancelOpen && (
        <ConfirmDialog
          isOpen={isOkCancelOpen}
          okName={"예"}
          cancelName={"아니요"}
          content={"편집 내용을 저장하시겠습니까?"}
          onOkConfirmDlg={onOkConfirmDlg}
          onCancelConfirmDlg={onCancelConfirmDlg}
        />
      )}
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ margin: "0px 0px 5px" }}
      >
        <Grid item xs container alignItems="center">
          <Grid item>
            <Typography
              className={global_s.defaultText}
              style={{
                fontSize: "20px",
                color: "#262626",
              }}
            >
              {"AI 관리"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={contents_s.label}>{"검증 결과"}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button1
              className={contents_s.menuBtn}
              hidden={isEdit ? true : false}
              onClick={databaseSearch}
            >
              <Typography className={contents_s.refreshLabel}>
                {"새로고침"}
              </Typography>
            </Button1>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <Button1 className={contents_s.menuBtn} onClick={handleCSV}>
              <Typography className={contents_s.csvLabel}>
                {"내보내기"}
              </Typography>
            </Button1>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <Button1
              className={isEdit ? contents_s.saveBtn : contents_s.editBtn}
              onClick={handleEdit}
            >
              <Typography className={contents_s.editLabel}>
                {editLabel}
              </Typography>
            </Button1>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="row" alignItems="center">
        <Grid item xs>
          <OutlinedInput1
            value={search}
            onChange={handleSearchChange}
            placeholder={"조회"}
            style={{
              fontSize: 12,
              backgroundColor: "white",
              padding: "5px",
              height: "32px",
              borderRadius: "0px",
            }}
            endAdornment={
              <InputAdornment position="end">
                {search.length > 0 ? (
                  <IconButton1 size="small" onClick={handleSearchClear}>
                    <CloseIcon style={{ color: "#a9a9a9" }} />
                  </IconButton1>
                ) : (
                  <SearchIcon style={{ color: "#a9a9a9" }} />
                )}
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
      <Grid item xs style={{ margin: "2px 0px" }}>
        <HotTable
          id="banned-total-table"
          ref={hotTableRef}
          settings={tableSettings}
          width={"100%"}
          height={673}
          style={{ border: "1px solid #a9a9a9", backgroundColor: "#ffffff" }}
        />
      </Grid>
    </Grid>
  );
}

export default AIManagement;
