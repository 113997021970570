import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

export const ColorCheckbox = withStyles({
  root: {
    color: "#00b5ef",
    "&$checked": {
      color: "#00b5ef",
    },
    width: "20px",
    height: "20px",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
