import React, { useState, useEffect } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import {
  Grid,
  Button,
  Typography,
  OutlinedInput,
  createMuiTheme,
  InputAdornment,
} from "@material-ui/core";

import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { SendQuery } from "../../actions/send";
import { IconButton1, MessageDialog } from "../../components";

import { useStyles } from "../style";
import { Update_User, IsDesignated } from "../../actions/auth";

const contentStyles = makeStyles((theme) => ({
  comboBoxRoot: {
    minHeight: "28px",
    margin: "0px",
    padding: "0px 10px 0px",
    color: "#ffffff",
    backgroundColor: "#3fa9f5",
    "&:hover": {
      backgroundColor: "#3f8ef5",
    },
    borderRadius: "3px",
    border: "1px solid #3fa9f5",
    justifyContent: "flex-start",
  },
  comboBoxLabel: {
    fontSize: "13px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
    textAlign: "left",
    width: "100%",
  },
  btnIcon: {
    fontSize: "18px",
  },
  colorText: {
    fontFamily: "Noto Sans",
    color: "#00B5EF",
    fontWeight: "bold",
    fontSize: "20px",
    margin: "40px 0px 60px",
  },
  defaultText: {
    fontFamily: "Noto Sans",
  },
  radioLabel: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#707070",
    margin: "0px 10px 0px 5px",
  },
  inputText: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#565656",
  },
  inputBtn: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
    outlineOffset: "none !important",
    boxShadow: "none",
  },
  intputIcon: {
    fontSize: "32px",
    color: "#c9c9c9",
  },
  btnRoot: {
    "& > *": {
      margin: "0px",
    },
    color: "#ffffff",
    backgroundColor: "#3fa9f5",
    "&:hover": {
      backgroundColor: "#3f8ef5",
    },
    outline: "none !important",
    outlineOffset: "none !important",
    boxShadow: "none",
  },
}));

const materialTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&.Mui-focused $notchedOutline": {
          "border-color": "#7ec5f8",
          "border-width": "2px",
        },
      },
    },
  },
});

function Login(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const global_s = useStyles();
  const contents_s = contentStyles();

  const [user_id, setUserID] = useState("");
  const [user_pw, setUserPW] = useState("");
  const [visibility, setVisibility] = useState(false);

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] =
    useState("ID와 PW를 확인바랍니다.");
  const [messageDlgCloseName, setMessageDlgCloseName] = useState("확인");

  const auth = useSelector((state) => state.auth, shallowEqual);

  const loginURL = "api/auth/login";
  const handleLogin = () => {
    SendQuery(loginURL, {}, { user_id, password: user_pw }, (err, res) => {
      if (err || !res) {
        setOpenMessageDlg(true);
        return;
      }

      dispatch(Update_User(res.data));
    });
  };

  useEffect(() => {
    props.handleHeader(false);
    props.handleSidebar(false);

    dispatch(IsDesignated(false));
  }, []);

  if (auth.isLogin) {
    return <Redirect to="/dashboard" />;
  }

  const handleUserID = (event) => {
    setUserID(event.target.value);
  };

  const handleUserPW = (event) => {
    setUserPW(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleVisibility = (event) => {
    setVisibility(!visibility);
  };

  if (auth.isLogin) {
    props.handleHeader(true);
    props.handleSidebar(true);
    history.push("/Dashboard");
  }

  const onCloseMessageDlg = () => {
    setOpenMessageDlg(false);
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{
        height: "100vh",
        width: "100%",
        backgroundColor: "#f4f6f9",
      }}
    >
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{
          borderRadius: "20px",
          backgroundColor: "#ffffff",
          padding: "40px",
          maxWidth: "500px",
        }}
      >
        <Grid item>
          <Link to="/">
            <img
              src="/img/atomyaza_ci.jpg"
              alt="Atomy"
              style={{
                height: "45px",
                marginTop: "20px",
                backgroundColor: "transparent",
              }}
            />
          </Link>
        </Grid>

        <Typography className={contents_s.colorText}>
          {"과장광고 검지 시스템"}
        </Typography>
        <ThemeProvider theme={materialTheme}>
          <OutlinedInput
            style={{ marginTop: "-30px" }}
            className={contents_s.inputText}
            value={user_id}
            type={"text"}
            fullWidth
            placeholder={"ID"}
            onChange={handleUserID}
            startAdornment={
              <InputAdornment position="start">
                <PersonIcon className={contents_s.intputIcon} />
              </InputAdornment>
            }
          />
          <OutlinedInput
            className={contents_s.inputText}
            value={user_pw}
            type={visibility ? "text" : "password"}
            fullWidth
            placeholder={"Password"}
            onChange={handleUserPW}
            onKeyPress={handleKeyPress}
            startAdornment={
              <InputAdornment position="start">
                <LockIcon className={contents_s.intputIcon} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton1 size="small" onClick={handleVisibility}>
                  {visibility ? (
                    <VisibilityIcon className={contents_s.intputIcon} />
                  ) : (
                    <VisibilityOffIcon className={contents_s.intputIcon} />
                  )}
                </IconButton1>
              </InputAdornment>
            }
            style={{ margin: "20px 0px 20px" }}
          />
        </ThemeProvider>
        {/* <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <FormControlLabel
            className={contents_s.defaultText}
            control={
              <ColorCheckbox
                checked={isRemember}
                onClick={onCheckRemember}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ margin: "10px" }}
              />
            }
            label={
              <Typography className={global_s.defaultText}>
                {"ID 기억하기"}
              </Typography>
            }
          />
          <Button
            className={contents_s.btnRoot}
            variant="contained"
            onClick={onClickFindBtn}
            disabled
          >
            {"ID/PW 찾기"}
          </Button>
        </Grid> */}
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            className={contents_s.btnRoot}
            variant="contained"
            onClick={handleLogin}
            style={{ marginTop: "20px" }}
          >
            {"로그인"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Login;
