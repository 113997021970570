import React, { useState, useEffect, createRef } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useStyles } from "../../../style.js";
import { global_theme } from "../../../theme.js";

import {
  Grid,
  List,
  Divider,
  ListItem,
  ListItemText,
  Backdrop,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

import {
  Button1,
  IconButton1,
  OutlinedInput1,
  ConfirmDialog,
  MessageDialog,
  ConfirmSellerDialog,
  ConfirmContentsListDetailDialog,
} from "../../../../components";

import { onDateCellRenderer, setResetTimeVal } from "../../../utils";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";

import { SendQuery } from "../../../../actions/send";
import { LogOut, IsDesignated, Update_Alarm } from "../../../../actions/auth";

import * as _ from "lodash";

const contentStyles = makeStyles((theme) => ({
  defaultDate: {
    color: "#3c8dbc",
    backgroundColor: "white",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    fontSize: "14px",
    height: "30px",
    margin: "5px",
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#999999",
    margin: "3px 8px",
  },
  menuLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  menuBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#62A4BC",
    "&:hover": {
      backgroundColor: "#62A4BC",
    },
    "&:disabled": {
      backgroundColor: "#999999",
    },
  },
  csvLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  editLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  editBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#62A4BC",
    "&:hover": {
      backgroundColor: "#62A4BC",
    },
  },
  saveBtn: {
    padding: "3px 8px 2px",
    backgroundColor: "#00CC00",
    "&:hover": {
      backgroundColor: "#009C00",
    },
  },
  disableCell: {
    color: "#ffffff",
    backgroundColor: "#a9a9a9",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  searchBtn: {
    "&:hover": {
      backgroundColor: "#62A4BC",
    },
    color: "white",
    backgroundColor: "#62A4BC",
    height: "28px",
    marginLeft: "10px",
  },
  defaultGridHeader: {
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    backgroundColor: "rgba(124, 174, 202, 0.2)",
  },
  listLabel: {
    fontFamily: "Noto Sans",
    color: "#505050",
  },
}));

let manual = false;
let refresh = 0;
let old_event = undefined;
let old_designated = undefined;
let last_check_date = undefined;
function Designated(props) {
  const dispatch = useDispatch();
  const global_s = useStyles();
  const global_t = global_theme;
  const contents_s = contentStyles();

  const [isOpenSeller, setIsOpenSeller] = useState(false);
  const [designated_list, setDesignatedList] = useState([]);

  const [sellerSearch, setSellerSearch] = useState("");
  const [sellerRows, setSellerRows] = useState([]);
  const [sellerGridApi, setSellerGridApi] = useState(undefined);
  const columnDef = [
    {
      field: "Check",
      headerName: "",
      width: 50,
      sortable: false,
      resizable: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: "seller",
      headerName: "회원번호(이름)",
      tooltipField: "seller",
      width: 200,
      sortable: true,
      resizable: true,
    },
    {
      field: "totalCount",
      headerName: "등록글(개)",
      tooltipField: "totalCount",
      width: 300,
      sortable: true,
      resizable: true,
    },
    {
      field: "hypeCount",
      headerName: "과장성 글(개)",
      tooltipField: "hypeCount",
      width: 300,
      sortable: true,
      resizable: true,
    },
    {
      field: "bannedCount",
      headerName: "금칙어 글(개)",
      tooltipField: "bannedCount",
      width: 300,
      sortable: true,
      resizable: true,
    },
    {
      field: "designated_time",
      headerName: "지정된 판매자 등록일",
      width: 300,
      sortable: true,
      resizable: true,
      cellRenderer: onDateCellRenderer,
    },
  ];

  const [eventRows, setEventRows] = useState([]);

  const errMessage1 = "판매자를 선택바랍니다.";
  const [messageContents, setMessageContents] = useState("");
  const [isOpenMessage, setIsOpenMessage] = useState(false);

  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmContents, setConfirmContents] = useState("");
  const [isOkCancelOpen, setIsOkCancelOpen] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);

  const [backTimer, setBackTimer] = useState(1000);
  const [selected, setSelectData] = useState(undefined);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const [startDate, setStartDate] = useState(
    setResetTimeVal(moment().local().format())
  );
  const [endDate, setEndDate] = useState(
    setResetTimeVal(moment().local().format())
  );

  useEffect(() => {
    dispatch(IsDesignated(true));
    dispatch(Update_Alarm([]));
    search_designated(false);
    search_event();
  }, []);

  useEffect(() => {
    const start = setInterval(() => {
      if (parseInt(backTimer) > 0) {
        setBackTimer(parseInt(backTimer) - 1);
      }
      if (parseInt(backTimer) === 0) {
        setBackTimer(1000);
      }

      if (!refresh) {
        search_event();
      }
    }, 10000);
    return () => clearInterval(start);
  }, [backTimer]);

  useEffect(() => {
    if (!selected) setIsDetailOpen(false);
    else setIsDetailOpen(true);
  }, [selected]);

  if (
    props.auth &&
    props.auth.user_info &&
    props.auth.user_info.auth_type % 2 === 0
  ) {
    return <Redirect to="/dashboard" />;
  }

  const databaseSend = (location, data, callback) => {
    SendQuery(location, props.auth.user_info, data, (err, res) => {
      if (err || !res) return callback(null);

      if (res.data && res.data.code === 500) {
        return dispatch(LogOut());
      }

      return callback(res.data);
    });
  };

  const search_designated = (check_manual = false) => {
    refresh++;
    if (!last_check_date)
      last_check_date = setResetTimeVal(moment().local().format())
        .tz("UTC")
        .format("YYYY-MM-DD HH:mm:ss.SSS");

    SendQuery(
      "api/dashboard/designated",
      props.auth.user_info,
      { isDesignated: true },
      (err, res) => {
        if (err || !res) {
          refresh--;
          return;
        }

        if (res.data && res.data.code === 500) {
          refresh--;
          return dispatch(LogOut());
        }

        if (!check_manual && manual) {
          refresh--;
          return;
        }

        let designated_info = old_designated ? [...old_designated] : [];
        res.data.designated_list.forEach((designated) => {
          designated.seller = `${designated.user_id}(${
            designated.name ? designated.name : "-"
          })`;
          designated.designated_time = moment
            .utc(designated.designated_time)
            .local();
          designated_info.push(designated);
        });

        designated_info = designated_info.sort(
          (a, b) => b.designated_time.valueOf() - a.designated_time.valueOf()
        );

        setSellerRows(designated_info);
        refresh--;
      }
    );
  };

  const search_event = () => {
    refresh++;
    if (!last_check_date)
      last_check_date = setResetTimeVal(moment().local().format())
        .tz("UTC")
        .format("YYYY-MM-DD HH:mm:ss.SSS");

    SendQuery(
      "api/dashboard/event",
      props.auth.user_info,
      { start_date: last_check_date, isDesignated: true },
      (err, res) => {
        if (err || !res) {
          refresh--;
          return;
        }

        if (res.data && res.data.code === 500) {
          refresh--;
          return dispatch(LogOut());
        }

        let event_info = old_event ? [...old_event] : [];
        res.data.event_list.forEach((event) => {
          let idx = event_info.findIndex((val) => {
            if (
              val.pkey === event.pkey &&
              val.parents_pkey === event.parents_pkey &&
              val.t_updated_time === event.t_updated_time
            )
              return val;
          });

          if (idx !== -1) return;

          event.created_time = moment.utc(event.created_time).local();
          event.seller = `${event.user_id}(${event.name ? event.name : "-"})`;
          event.updated_time = moment.utc(event.t_updated_time).local();

          event_info.push(event);
        });

        event_info = event_info.sort(
          (a, b) => b.updated_time.valueOf() - a.updated_time.valueOf()
        );

        if (event_info.length > 0) {
          let recent_date = event_info[0].t_updated_time;
          if (last_check_date !== recent_date) last_check_date = recent_date;
        }

        old_event = event_info;
        setEventRows([...old_event]);
        refresh--;
      }
    );
  };

  const onGridReady = (params) => {
    setSellerGridApi(params.api);
  };

  const onSelectionChanged = () => {
    setDesignatedList(sellerGridApi.getSelectedRows());
  };

  const onCellClicked = (event) => {
    event.node.setSelected(!event.node.selected);
  };

  const handleSellerSearchChange = (event) => {
    setSellerSearch(event.target.value);
    sellerGridApi.setQuickFilter(event.target.value);
  };

  const handleSellerSearchClear = () => {
    setSellerSearch("");
    sellerGridApi.setQuickFilter("");
  };

  const onEventClicked = (event) => {
    setSelectData(event);
  };

  const onOkConfirmDlg = () => {
    if (designated_list.length < 0) setIsOkCancelOpen(false);

    let designated = undefined;
    switch (confirmTitle) {
      case "추가":
        designated = 1;
        break;
      case "삭제":
        designated = 0;
        break;
      default:
        return;
    }

    setIsBackdrop(true);
    databaseSend(
      "api/dashboard/designated/update",
      {
        designated: designated,
        designated_list: designated_list,
      },
      (res) => {
        if (!res) {
          setMessageContents("지정된 판매자 업데이트를 실패했습니다.");
          setIsOpenMessage(true);
          return;
        }

        setIsBackdrop(false);
        setIsOkCancelOpen(false);
        setIsOpenSeller(false);

        manual = true;
        search_designated(true);
        setDesignatedList([]);
      }
    );
  };

  const onCancelConfirmDlg = () => {
    setIsOkCancelOpen(false);
  };

  const onCloseMessageDlg = () => {
    setIsOpenMessage(false);
  };

  const handleInsert = () => {
    sellerGridApi.deselectAll();
    setDesignatedList([]);
    setIsOpenSeller(true);
  };

  const handleRemove = () => {
    if (designated_list.length < 1) {
      setMessageContents(errMessage1);
      return setIsOpenMessage(true);
    }

    let designated_text = "";
    designated_list.forEach((designated) => {
      if (designated_text.length > 0) designated_text += ", ";
      designated_text += designated.user_id;
    });

    setConfirmTitle("삭제");
    setConfirmContents(`'${designated_text}'를 삭제하시겠습니까?`);
    setIsOkCancelOpen(true);
  };

  const handleEnd = (designated_info = undefined) => {
    if (!Array.isArray(designated_info)) return setIsOpenSeller(false);
    if (designated_info.length < 1) return setIsOpenSeller(false);

    let designated_text = "";
    designated_info.forEach((designated) => {
      if (designated_text.length > 0) designated_text += ", ";
      designated_text += designated.user_id;
    });

    setDesignatedList(designated_info);
    setConfirmTitle("추가");
    setConfirmContents(`'${designated_text}'를 추가하시겠습니까?`);
    setIsOkCancelOpen(true);
  };

  const handleDetailCancel = () => {
    setSelectData(undefined);
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      style={{ padding: "50px" }}
    >
      {isDetailOpen && (
        <ConfirmContentsListDetailDialog
          cancelName="확인"
          isOpen={isDetailOpen}
          selected={selected}
          databaseSend={databaseSend}
          handleCancel={handleDetailCancel}
        />
      )}
      {isOpenSeller && (
        <ConfirmSellerDialog
          title={"판매자 정보"}
          isOpen={isOpenSeller}
          okName={"확인"}
          cancelName={"취소"}
          databaseSend={databaseSend}
          handleEnd={handleEnd}
        />
      )}
      {isOpenMessage && (
        <MessageDialog
          isOpen={isOpenMessage}
          closeName={"확인"}
          content={messageContents}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}
      {isOkCancelOpen && (
        <ConfirmDialog
          isOpen={isOkCancelOpen}
          title={confirmTitle}
          okName={"예"}
          cancelName={"아니요"}
          content={confirmContents}
          onOkConfirmDlg={onOkConfirmDlg}
          onCancelConfirmDlg={onCancelConfirmDlg}
        />
      )}
      {isBackdrop && (
        <Backdrop className={contents_s.backdrop} open={isBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid item>
        <Typography
          className={global_s.defaultText}
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#3c8dbc",
            marginRight: "10px",
          }}
        >
          {"지정된 판매자"}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className={global_s.defaultText}
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            color: "#77BBD4",
            margin: "10px 10px 0px",
          }}
        >
          {"이벤트 목록"}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          marginTop: "10px",
          padding: "5px 10px",
          borderRadius: "10px",
          backgroundColor: "rgba(124, 174, 202, 0.3)",
        }}
      >
        <List
          style={{
            margin: "0px",
            padding: "0px",
            minHeight: "200px",
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          {Array.isArray(eventRows) &&
            eventRows.map((event, idx) => {
              const labelId = `default-column-check-${idx}`;
              return (
                <div key={labelId}>
                  {idx > 0 ? (
                    <Divider style={{ backgroundColor: "#e2e2e2" }} />
                  ) : null}
                  <ListItem
                    role={undefined}
                    dense
                    button
                    onClick={() => {
                      onEventClicked(event);
                    }}
                    style={{
                      minWidth: "148px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    {event.hype || event.banned ? (
                      <ListItemText>
                        <span className={contents_s.listLabel}>
                          {`[${event.updated_time.format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}] ${event.user_id}(${
                            event.name ? event.name : "-"
                          })님이 작성한 ${
                            event.parents_pkey ? "댓글" : "게시글"
                          }에서 `}
                        </span>
                        {event.hype ? (
                          <span
                            className={contents_s.listLabel}
                            style={{
                              backgroundColor: "#F6B2B2",
                              border: "1px solid #F6B2B2",
                            }}
                          >
                            {"과장성"}
                          </span>
                        ) : null}
                        <span className={contents_s.listLabel}>
                          {event.hype && event.banned
                            ? "과 "
                            : event.hype
                            ? "이 "
                            : " "}
                        </span>
                        {event.banned ? (
                          <span
                            className={contents_s.listLabel}
                            style={{
                              backgroundColor: "#FCFFB1",
                              border: "1px solid #FCFFB1",
                            }}
                          >
                            {"금칙어"}
                          </span>
                        ) : null}
                        <span className={contents_s.listLabel}>
                          {event.banned ? "가 " : ""}
                        </span>
                        <span className={contents_s.listLabel}>
                          {"발견되었습니다."}
                        </span>
                      </ListItemText>
                    ) : (
                      <ListItemText>
                        <span className={contents_s.listLabel}>
                          {`[${event.updated_time.format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}] ${event.user_id}(${
                            event.name ? event.name : "-"
                          })님이 새로운 ${
                            event.parents_pkey ? "댓글" : "게시글"
                          }을 작성했습니다.`}
                        </span>
                      </ListItemText>
                    )}
                  </ListItem>
                </div>
              );
            })}
        </List>
      </Grid>
      <Grid item>
        <Typography
          className={global_s.defaultText}
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            color: "#77BBD4",
            margin: "10px",
          }}
        >
          {"지정된 판매자 목록"}
        </Typography>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        style={{
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.6)",
        }}
      >
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs container direction="row" alignItems="center">
            <Grid item>
              <Button1 className={contents_s.menuBtn} onClick={handleInsert}>
                <Typography className={contents_s.menuLabel}>
                  {"추가"}
                </Typography>
              </Button1>
            </Grid>
            <Grid item style={{ margin: "0px 5px" }}>
              <Button1 className={contents_s.menuBtn} onClick={handleRemove}>
                <Typography className={contents_s.menuLabel}>
                  {"삭제"}
                </Typography>
              </Button1>
            </Grid>
          </Grid>
          <Grid
            item
            xs
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item xs style={{ maxWidth: "500px" }}>
              <OutlinedInput1
                value={sellerSearch}
                onChange={handleSellerSearchChange}
                placeholder={"조회"}
                style={{
                  fontSize: 12,
                  backgroundColor: "white",
                  padding: "5px",
                  height: "32px",
                  borderRadius: "0px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {sellerSearch.length > 0 ? (
                      <IconButton1
                        size="small"
                        onClick={handleSellerSearchClear}
                      >
                        <CloseIcon style={{ color: "#a9a9a9" }} />
                      </IconButton1>
                    ) : (
                      <SearchIcon style={{ color: "#a9a9a9" }} />
                    )}
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs style={{ margin: "5px 0px" }}>
          <div
            className="ag-theme-alpine"
            style={{ height: "380px", width: "100%" }}
          >
            <AgGridReact
              rowData={sellerRows}
              rowStyle={{ cursor: "pointer" }}
              rowSelection="multiple"
              defaultColDef={{
                sortable: true,
                resizable: true,
                cellClass: global_s.defaultGridCell,
                headerClass: global_s.defaultGridHeader,
              }}
              overlayLoadingTemplate={"<span></span>"}
              overlayNoRowsTemplate={"<span></span>"}
              headerHeight={36}
              suppressMovableColumns={true} // columns 이동 금지
              suppressRowClickSelection={true}
              onGridReady={onGridReady}
              onSelectionChanged={onSelectionChanged}
              onCellClicked={onCellClicked}
            >
              {columnDef.map((columnItem) => {
                return (
                  <AgGridColumn
                    key={columnItem.headerName}
                    headerName={columnItem.headerName}
                    field={columnItem.field}
                    hide={columnItem.hide}
                    type={columnItem.type}
                    width={columnItem.width}
                    tooltipField={columnItem.tooltipField}
                    sortable={columnItem.sortable}
                    resizable={columnItem.resizable}
                    checkboxSelection={columnItem.checkboxSelection}
                    valueGetter={columnItem.valueGetter}
                    cellRenderer={columnItem.cellRenderer}
                    headerCheckboxSelection={columnItem.headerCheckboxSelection}
                  />
                );
              })}
            </AgGridReact>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Designated;
