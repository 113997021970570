import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import { NotFound } from "../../containers";
import TotalDashboard from "./Total";
import AIAnalyzer from "./AI";
import SellerAnalyzer from "./Seller";
import SynthesisAnalyzer from "./Synthesis";
import BannedAnalyzer from "./Banned";

function Dashboard(props) {
  const { match } = props;
  const auth = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (auth.isLogin) {
      props.handleHeader(true);
      props.handleSidebar(true);
    } else {
      return <Redirect to="/login" />;
    }
  }, []);

  if (!auth.isLogin) {
    return <Redirect to="/login" />;
  }

  const handleHeader = () => {
    props.handleHeader(true);
  };

  const handleSidebar = () => {
    props.handleSidebar(true);
  };

  const getSidebarState = () => {
    return props.getSidebarState();
  };

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={(props) => (
            <TotalDashboard
              {...props}
              auth={auth}
              handleHeader={handleHeader}
              handleSidebar={handleSidebar}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route
          path={`${match.url}/total`}
          render={(props) => (
            <TotalDashboard
              {...props}
              auth={auth}
              handleHeader={handleHeader}
              handleSidebar={handleSidebar}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route
          path={`${match.url}/ai`}
          render={(props) => (
            <AIAnalyzer
              {...props}
              auth={auth}
              handleHeader={handleHeader}
              handleSidebar={handleSidebar}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route
          path={`${match.url}/seller`}
          render={(props) => (
            <SellerAnalyzer
              {...props}
              auth={auth}
              handleHeader={handleHeader}
              handleSidebar={handleSidebar}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route
          path={`${match.url}/synthesis`}
          render={(props) => (
            <SynthesisAnalyzer
              {...props}
              auth={auth}
              handleHeader={handleHeader}
              handleSidebar={handleSidebar}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route
          path={`${match.url}/banned`}
          render={(props) => (
            <BannedAnalyzer
              {...props}
              auth={auth}
              handleHeader={handleHeader}
              handleSidebar={handleSidebar}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route path="*" render={(props) => <NotFound {...props} />} />
      </Switch>
    </>
  );
}

export default Dashboard;
