import { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Grid, Typography } from "@material-ui/core";
import { Button1 } from ".";

import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import StopIcon from "@material-ui/icons/Stop";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    width: drawerWidth,
    flexShrink: 0,
  },
  paper: {
    marginTop: "48px",
    width: drawerWidth,
    height: "100%",
    zIndex: "1036",
    backgroundColor: "#99c3d3",
  },
  defaultText: {
    fontFamily: "Noto Sans",
  },
}));

function Sidebar(props) {
  const classes = useStyles();
  const history = useHistory();

  const [bannedOpen, setBannedOpen] = useState(true);

  const handlBtn = (event) => {
    switch (event.currentTarget.name) {
      case "total":
        return history.push("/dashboard/total");
      case "synthesis":
        return history.push("/dashboard/synthesis");
      case "ai":
        return history.push("/dashboard/ai");
      case "banned":
        return history.push("/dashboard/banned");
      case "seller-detail":
        return history.push("/dashboard/seller/detail");
      case "seller-designated":
        return history.push("/dashboard/seller/designated");
      case "ai-management":
        return history.push("/administer/ai");
      case "banned-management":
        return setBannedOpen(!bannedOpen);
      case "banned-total":
        return history.push("/administer/banned");
      case "banned-forbidden":
        return history.push("/administer/banned/forbidden");
      case "banned-caution":
        return history.push("/administer/banned/caution");
      case "banned-combination":
        return history.push("/administer/banned/combination");
      case "user-management":
        return history.push("/administer/user");
    }
  };

  return (
    <Drawer
      className={classes.root}
      variant="persistent"
      anchor="left"
      open={props.isOpen}
      classes={{
        paper: classes.paper,
      }}
    >
      <Grid container direction="column" alignItems="stretch">
        {props.auth &&
        props.auth.user_info &&
        props.auth.user_info.auth_type > 0 ? (
          <Grid
            item
            xs
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{
              backgroundColor: "#62a4bc",
              padding: "5px",
            }}
          >
            <Grid item style={{ padding: "5px 0px " }}>
              <img src="/img/setting.png" />
            </Grid>
            <Grid item style={{ padding: "0px 10px" }}>
              <Typography
                className={classes.defaultText}
                style={{ fontSize: "16px", fontWeight: "bold", color: "white" }}
              >
                {"관리자 전용"}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs>
          {props.auth &&
          props.auth.user_info &&
          props.auth.user_info.auth_type % 2 > 0 ? (
            <Button1
              name="user-management"
              onClick={handlBtn}
              style={{ width: "100%" }}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  padding: "0px 30px",
                }}
              >
                <Grid item style={{ padding: "0px 10px 0px" }}>
                  <FiberManualRecordIcon
                    style={{ color: "white", fontSize: "8px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.defaultText}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {"사용자 관리"}
                  </Typography>
                </Grid>
              </Grid>
            </Button1>
          ) : null}
        </Grid>
        {/* <Grid item xs>
          <Button1
            name="ai-management"
            onClick={handlBtn}
            style={{ width: "100%" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{
                padding: "0px 30px",
              }}
            >
              <Grid item style={{ padding: "0px 10px 0px" }}>
                <FiberManualRecordIcon
                  style={{ color: "white", fontSize: "8px" }}
                />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.defaultText}
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {"AI 관리"}
                </Typography>
              </Grid>
            </Grid>
          </Button1>
        </Grid> */}
        <Grid item xs>
          {props.auth &&
          props.auth.user_info &&
          (props.auth.user_info.auth_type === 2 ||
            props.auth.user_info.auth_type === 3 ||
            props.auth.user_info.auth_type === 6 ||
            props.auth.user_info.auth_type === 7) ? (
            <Button1
              name="banned-management"
              onClick={handlBtn}
              style={{ width: "100%" }}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  padding: "0px 30px",
                }}
              >
                <Grid item style={{ padding: "0px 10px 0px" }}>
                  <FiberManualRecordIcon
                    style={{ color: "white", fontSize: "8px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.defaultText}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {"금칙어 관리"}
                  </Typography>
                </Grid>
                <Grid item style={{ marginLeft: "8px" }}>
                  {bannedOpen ? (
                    <ArrowDropDownIcon style={{ color: "#ffffff" }} />
                  ) : (
                    <ArrowLeftIcon style={{ color: "#ffffff" }} />
                  )}
                </Grid>
              </Grid>
            </Button1>
          ) : null}
        </Grid>
        {props.auth &&
        props.auth.user_info &&
        (props.auth.user_info.auth_type === 2 ||
          props.auth.user_info.auth_type === 3 ||
          props.auth.user_info.auth_type === 6 ||
          props.auth.user_info.auth_type === 7) &&
        bannedOpen ? (
          <Grid item xs container direction="column" alignItems="stretch">
            <Grid item xs>
              <Button1
                name="banned-total"
                onClick={handlBtn}
                style={{ width: "100%" }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{
                    padding: "0px 50px",
                  }}
                >
                  <Grid item style={{ padding: "0px 10px 0px" }}>
                    <StopIcon style={{ color: "white", fontSize: "8px" }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.defaultText}
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {"전체"}
                    </Typography>
                  </Grid>
                </Grid>
              </Button1>
            </Grid>
            <Grid item xs>
              <Button1
                name="banned-forbidden"
                onClick={handlBtn}
                style={{ width: "100%" }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{
                    padding: "0px 50px",
                  }}
                >
                  <Grid item style={{ padding: "0px 10px 0px" }}>
                    <StopIcon style={{ color: "white", fontSize: "8px" }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.defaultText}
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {"금지어"}
                    </Typography>
                  </Grid>
                </Grid>
              </Button1>
            </Grid>
            <Grid item xs>
              <Button1
                name="banned-caution"
                onClick={handlBtn}
                style={{ width: "100%" }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{
                    padding: "0px 50px",
                  }}
                >
                  <Grid item style={{ padding: "0px 10px 0px" }}>
                    <StopIcon style={{ color: "white", fontSize: "8px" }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.defaultText}
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {"주의어"}
                    </Typography>
                  </Grid>
                </Grid>
              </Button1>
            </Grid>
            <Grid item xs>
              <Button1
                name="banned-combination"
                onClick={handlBtn}
                style={{ width: "100%" }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{
                    padding: "0px 50px",
                  }}
                >
                  <Grid item style={{ padding: "0px 10px 0px" }}>
                    <StopIcon style={{ color: "white", fontSize: "8px" }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.defaultText}
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {"조합어"}
                    </Typography>
                  </Grid>
                </Grid>
              </Button1>
            </Grid>
          </Grid>
        ) : null}
        <Grid
          item
          xs
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{
            backgroundColor: "#62a4bc",
            padding: "5px 0px",
          }}
        >
          <Grid item style={{ padding: "5px 0px ", marginLeft: "-18px" }}>
            <img src="/img/dashboard.png" />
          </Grid>
          <Grid item style={{ padding: "0px 10px" }}>
            <Typography
              className={classes.defaultText}
              style={{ fontSize: "16px", fontWeight: "bold", color: "white" }}
            >
              {"대시보드"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs>
          <Button1 name="total" onClick={handlBtn} style={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                padding: "0px 30px",
              }}
            >
              <Grid item style={{ padding: "0px 10px 0px" }}>
                <FiberManualRecordIcon
                  style={{ color: "white", fontSize: "8px" }}
                />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.defaultText}
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {"전체"}
                </Typography>
              </Grid>
            </Grid>
          </Button1>
        </Grid>
        <Grid item xs>
          <Button1
            name="synthesis"
            onClick={handlBtn}
            style={{ width: "100%" }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                padding: "0px 30px",
              }}
            >
              <Grid item style={{ padding: "0px 10px 0px" }}>
                <FiberManualRecordIcon
                  style={{ color: "white", fontSize: "8px" }}
                />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.defaultText}
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {"종합 분석"}
                </Typography>
              </Grid>
            </Grid>
          </Button1>
        </Grid>
        <Grid item xs>
          <Button1 name="ai" onClick={handlBtn} style={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                padding: "0px 30px",
              }}
            >
              <Grid item style={{ padding: "0px 10px 0px" }}>
                <FiberManualRecordIcon
                  style={{ color: "white", fontSize: "8px" }}
                />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.defaultText}
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {"AI 분석"}
                </Typography>
              </Grid>
            </Grid>
          </Button1>
        </Grid>
        <Grid item xs>
          <Button1 name="banned" onClick={handlBtn} style={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                padding: "0px 30px",
              }}
            >
              <Grid item style={{ padding: "0px 10px 0px" }}>
                <FiberManualRecordIcon
                  style={{ color: "white", fontSize: "8px" }}
                />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.defaultText}
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {"금칙어 분석"}
                </Typography>
              </Grid>
            </Grid>
          </Button1>
        </Grid>
        <Grid item xs>
          <Button1
            name="seller-detail"
            onClick={handlBtn}
            style={{ width: "100%" }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                padding: "0px 30px",
              }}
            >
              <Grid item style={{ padding: "0px 10px 0px" }}>
                <FiberManualRecordIcon
                  style={{ color: "white", fontSize: "8px" }}
                />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.defaultText}
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {"판매자 분석"}
                </Typography>
              </Grid>
            </Grid>
          </Button1>
        </Grid>
        <Grid
          item
          xs
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{
            backgroundColor: "#62a4bc",
            padding: "5px 0px",
          }}
        >
          <Grid item style={{ padding: "5px 0px ", marginLeft: "-18px" }}>
            <img src="/img/alarm_menu_icon.svg" />
          </Grid>
          <Grid item style={{ padding: "0px 10px" }}>
            <Typography
              className={classes.defaultText}
              style={{ fontSize: "16px", fontWeight: "bold", color: "white" }}
            >
              {"알림"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs>
          <Button1
            name="seller-designated"
            onClick={handlBtn}
            style={{ width: "100%" }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                padding: "0px 30px",
              }}
            >
              <Grid item style={{ padding: "0px 10px 0px" }}>
                <FiberManualRecordIcon
                  style={{ color: "white", fontSize: "8px" }}
                />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.defaultText}
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {"지정된 판매자"}
                </Typography>
              </Grid>
            </Grid>
          </Button1>
        </Grid>
      </Grid>
    </Drawer>
  );
}

export default Sidebar;
