import React, { useState, useEffect } from "react";

import { useStyles } from "../containers/style.js";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Backdrop,
  Typography,
  DialogActions,
  InputAdornment,
  Divider,
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import {
  Button1,
  IconButton1,
  OutlinedInput1,
  ColorCheckbox,
  StyledMenu,
} from ".";

import moment from "moment-timezone";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import { onHyypeRenderer, onSentenceRenderer } from "../containers/utils";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content1: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#565656",
    padding: "20px 0px 20px",
  },
  content2: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    border: "1px solid #999999",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#999999",
  },
  label1: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#292929",
  },
  label2: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#3C8DBC",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  label3: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    color: "#3C8DBC",
  },
  back_old_data: {
    padding: "5px",
    marginLeft: "20px",
    borderRadius: "10px",
    backgroundColor: "#BCBCBC",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#confirm-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ConfirmContentsHypeDetailDialog(props) {
  const header_s = headerStyles();
  const global_s = useStyles();
  const contents_s = contentStyles();

  const [search, setSearch] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const [rows, setRows] = useState([]);
  const columnDef = [
    {
      field: "type",
      headerName: "위치",
      width: 120,
      sortable: true,
      resizable: true,
    },
    {
      field: "sentence",
      headerName: "문장",
      width: 860,
      sortable: true,
      resizable: true,
      cellRenderer: onSentenceRenderer,
    },
    {
      field: "hype",
      headerName: "과장성",
      width: 120,
      sortable: true,
      resizable: true,
      cellRenderer: onHyypeRenderer,
    },
    {
      field: "hype_value",
      headerName: "과장성 확률",
      width: 120,
      sortable: true,
      resizable: true,
    },
  ];

  const [isBackdrop, setIsBackdrop] = useState(false);
  const [hypeCount, setHypeCount] = useState(0);

  useEffect(() => {
    if (props.selected) {
      setIsBackdrop(true);
      props.databaseSend(
        "detail",
        {
          type_of_information: "detail",
          isTest: props.dataType,
          selected: props.selected,
        },
        (res) => {
          setIsBackdrop(false);
          if (!res) return;
          if (!Array.isArray(res.sentence_info)) return;

          let hype_count = 0;
          let sentence_info = [];
          res.sentence_info.forEach((sentence) => {
            if (sentence.hype) hype_count++;
            sentence.created_time = moment.utc(sentence.created_time).local();
            sentence.update_time = moment.utc(sentence.update_time).local();

            let type = "";
            if (props.selected.parents_pkey) type += "댓글";
            else type += "게시글";

            if (sentence.type === 0) type += " > 제목";
            else type += " > 내용";

            sentence.type = type;
            sentence.hype_value = sentence.hype_value
              ? sentence.hype_value + " %"
              : "";
            sentence_info.push(sentence);
          });

          sentence_info = sentence_info.sort(
            (a, b) => b.update_time.valueOf() - a.update_time.valueOf()
          );

          setHypeCount(hype_count);
          setRows(sentence_info);
        }
      );
    }
  }, []);

  const handleCancel = () => {
    props.onCancelConfirmDlg();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    gridApi.setQuickFilter(event.target.value);
  };

  const handleSearchClear = () => {
    setSearch("");
    gridApi.setQuickFilter("");
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      fullWidth={true}
      open={props.isOpen}
      onClose={handleCancel}
      PaperComponent={PaperComponent}
      aria-labelledby="confirm-dialog-title"
    >
      {isBackdrop && (
        <Backdrop className={contents_s.backdrop} open={isBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <DialogTitle className={header_s.titlebar} id="confirm-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "36px" }}
        >
          <Grid item xs={10} container direction="row" alignItems="center">
            <Typography className={header_s.title}>{props.title}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <IconButton1
              size="small"
              aria-label="close"
              onClick={handleCancel}
              style={{ color: "#ffffff" }}
            >
              <CloseIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: "0px 10px 10px" }}>
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ margin: "10px 0px 0px" }}
          >
            <Grid item xs container direction="row" alignItems="center">
              <Grid item>
                <Typography className={contents_s.label1}>
                  {"회원번호(이름) : "}
                </Typography>
              </Grid>
              {props.selected ? (
                <Grid item style={{ paddingLeft: "5px", marginRight: "10px" }}>
                  <Typography className={contents_s.label2}>
                    {props.selected.seller}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Grid
              item
              xs
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid item style={{ marginLeft: "10px" }}>
                <Typography className={contents_s.label2}>
                  {"과장성 기준 : "}
                </Typography>
              </Grid>
              <Grid item style={{ paddingLeft: "5px", marginRight: "10px" }}>
                <Typography className={contents_s.label2}>
                  {Array.isArray(rows) && rows.length > 0
                    ? `${rows[0].hype_standard} %`
                    : "0 %"}
                </Typography>
              </Grid>
              <Grid item style={{ marginLeft: "10px" }}>
                <Typography className={contents_s.label1}>
                  {"등록 일자 : "}
                </Typography>
              </Grid>
              {props.selected ? (
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography className={contents_s.label1}>
                    {`${moment
                      .utc(props.selected.created_time)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss")}`}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs style={{ margin: "10px 0px 0px" }}>
            <Divider />
          </Grid>
          <Grid item xs style={{ margin: "10px 0px -15px" }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs container direction="row" alignItems="center">
                <Grid item>
                  <Typography className={contents_s.label1}>
                    {"문장 정보"}
                  </Typography>
                </Grid>
                <Grid item style={{ marginLeft: "20px" }}>
                  <Typography className={contents_s.label2}>
                    {`과장성 : ${hypeCount} 개`}
                  </Typography>
                </Grid>
                <Grid item className={contents_s.back_old_data}>
                  <Typography className={contents_s.label3}>
                    {`과거 정보`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs style={{ marginLeft: "100px", maxWidth: "500px" }}>
                <OutlinedInput1
                  value={search}
                  onChange={handleSearch}
                  placeholder={"조회"}
                  style={{
                    padding: "5px",
                    maxWidth: "100%",
                    minWidth: "100%",
                    backgroundColor: "white",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {search.length > 0 ? ( // 입력 값의 길이에 따른 표시
                        <IconButton1 // 입력 값의 길이가 0보다 크다.
                          size="small"
                          onClick={handleSearchClear}
                        >
                          <CloseIcon
                            className={contents_s.intputIcon}
                            style={{ color: "#a9a9a9" }}
                          />
                        </IconButton1>
                      ) : (
                        <SearchIcon // 입력 값의 길이가 0보다 작거나 같다.
                          className={contents_s.intputIcon}
                          style={{ color: "#a9a9a9" }}
                        />
                      )}
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs style={{ margin: "10px 0px 0px" }}>
            <div
              className="ag-theme-alpine"
              style={{ height: "300px", width: "100%" }}
            >
              <AgGridReact
                headerHeight={36}
                rowData={rows}
                enableCellTextSelection={true}
                suppressMovableColumns={true} // columns 이동 금지
                overlayLoadingTemplate={"<span></span>"} // 데이터 로딩시 표시 화면
                overlayNoRowsTemplate={"<span></span>"} // 데이터 없을 경우 표시 화면
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  wrapText: true,
                  autoHeight: true,
                  cellClass: global_s.defaultGridCell,
                  headerClass: global_s.defaultGridHeader,
                }}
                onGridReady={onGridReady}
                onColumnResized={(params) => {
                  params.api.resetRowHeights();
                }}
              >
                {columnDef.map((columnItem) => {
                  return (
                    <AgGridColumn
                      key={columnItem.field}
                      headerName={columnItem.headerName}
                      field={columnItem.field}
                      hide={columnItem.hide}
                      width={columnItem.width}
                      tooltipField={columnItem.tooltipField}
                      sortable={columnItem.sortable}
                      resizable={columnItem.resizable}
                      checkboxSelection={columnItem.checkboxSelection}
                      valueGetter={columnItem.valueGetter}
                      cellRenderer={columnItem.cellRenderer}
                    />
                  );
                })}
              </AgGridReact>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      {/* <DialogActions>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button1 className={contents_s.btn} onClick={handleCancel}>
              <Typography className={contents_s.btn_label}>
                {props.cancelName}
              </Typography>
            </Button1>
          </Grid>
        </Grid>
      </DialogActions> */}
    </Dialog>
  );
}

export default ConfirmContentsHypeDetailDialog;
