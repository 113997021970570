export function LogOut(user_info = null) {
  return (dispatch) => {
    dispatch({
      type: "AUTH_LOGOUT_SUCCESS",
      user_info,
    });
  };
}

export function Update_User(user_info = null) {
  return (dispatch) => {
    dispatch({
      type: "AUTH_LOGIN_SUCCESS",
      user_info,
    });
  };
}

export function IsDesignated(status = false) {
  return (dispatch) => {
    dispatch({
      type: "AUTH_IS_DESIGNATED",
      status,
    });
  };
}

export function Update_Designated(designated_info = []) {
  return (dispatch) => {
    dispatch({
      type: "AUTH_DESIGNATED",
      designated_info,
    });
  };
}

export function Update_Alarm(alarm_info = []) {
  return (dispatch) => {
    dispatch({
      type: "AUTH_ALARM",
      alarm_info,
    });
  };
}

export function Update_Notice(notice_info = []) {
  return (dispatch) => {
    dispatch({
      type: "AUTH_NOTICE",
      notice_info,
    });
  };
}
