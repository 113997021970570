import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import { NotFound } from "../../../containers";
import TotalManagement from "./Total";
import CautionManagement from "./Caution";
import CombinationManagement from "./Combination";
import ForbiddenManagement from "./Forbidden";

function BannedManagement(props) {
  const { match } = props;
  const auth = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (auth.isLogin) {
      props.handleHeader();
      props.handleSidebar();
    } else {
      return <Redirect to="/login" />;
    }
  }, []);

  if (!auth.isLogin) {
    return <Redirect to="/login" />;
  }

  if (
    auth &&
    auth.user_info &&
    !(
      auth.user_info.auth_type === 2 ||
      auth.user_info.auth_type === 3 ||
      auth.user_info.auth_type === 6 ||
      auth.user_info.auth_type === 7
    )
  ) {
    return <Redirect to="/dashboard" />;
  }

  const getSidebarState = () => {
    return props.getSidebarState();
  };

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={(props) => (
            <TotalManagement
              {...props}
              auth={auth}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route
          path={`${match.url}/total`}
          render={(props) => (
            <TotalManagement
              {...props}
              auth={auth}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route
          path={`${match.url}/caution`}
          render={(props) => (
            <CautionManagement
              {...props}
              auth={auth}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route
          path={`${match.url}/combination`}
          render={(props) => (
            <CombinationManagement
              {...props}
              auth={auth}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route
          path={`${match.url}/forbidden`}
          render={(props) => (
            <ForbiddenManagement
              {...props}
              auth={auth}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route path="*" render={(props) => <NotFound {...props} />} />
      </Switch>
    </>
  );
}

export default BannedManagement;
