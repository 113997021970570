import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";

export const StyledMenu2 = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
  list: {
    padding: "0px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));
