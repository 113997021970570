import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";

import { NotFound } from "../../containers";
import AIManagement from "./AIManagement";
import BannedManagement from "./BannedManagement";
import UserManagement from "./UserManagement";

import { IsDesignated } from "../../actions/auth";

function Administer(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (auth.isLogin) {
      props.handleHeader(true);
      props.handleSidebar(true);
    } else {
      return <Redirect to="/login" />;
    }

    dispatch(IsDesignated(false));
  }, []);

  if (!auth.isLogin) {
    return <Redirect to="/login" />;
  }

  const handleHeader = () => {
    props.handleHeader(true);
  };

  const handleSidebar = () => {
    props.handleSidebar(true);
  };

  const getSidebarState = () => {
    return props.getSidebarState();
  };

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={(props) => (
            <UserManagement
              {...props}
              auth={auth}
              handleHeader={handleHeader}
              handleSidebar={handleSidebar}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route
          path={`${match.url}/ai`}
          render={(props) => <AIManagement {...props} />}
        />
        <Route
          path={`${match.url}/banned`}
          render={(props) => (
            <BannedManagement
              {...props}
              auth={auth}
              handleHeader={handleHeader}
              handleSidebar={handleSidebar}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route
          path={`${match.url}/user`}
          render={(props) => (
            <UserManagement
              {...props}
              auth={auth}
              handleHeader={handleHeader}
              handleSidebar={handleSidebar}
              getSidebarState={getSidebarState}
            />
          )}
        />
        <Route path="*" render={(props) => <NotFound {...props} />} />
      </Switch>
    </>
  );
}

export default Administer;
