import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { IconButton1, ConfirmContentsListDetailDialog } from ".";

import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const headerStyles = makeStyles((theme) => ({
  title: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#303030",
  },
  titlebar: {
    backgroundColor: "#7ec5f8",
    minHeight: "28px",
    maxHeight: "28px",
    padding: "0 15px 0",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    border: "1px solid #999999",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#999999",
  },
  label1: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#292929",
  },
  label2: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    minHeight: "28px",
    maxHeight: "28px",
    margin: "0 15px 5px",
  },
}));

const SnackMessage = (props) => {
  const header_s = headerStyles();
  const contents_s = contentStyles();
  const { closeSnackbar } = useSnackbar();

  const handleCancel = () => {
    closeSnackbar(props.id);
  };

  const handleShow = () => {
    props.onOpenConfirmDlg(props.designated);
  };

  useEffect(() => {
    if (!props.designated) handleCancel();
  }, []);

  return (
    <Grid
      container
      direction="row"
      style={{
        opacity: 0.9,
        maxWidth: "400px",
        minWidth: "400px",
        backgroundColor: "#A8C5D5",
      }}
    >
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid
          className={header_s.titlebar}
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs container direction="row">
            <Grid item style={{ marginLeft: "10px" }}>
              <Typography className={contents_s.label2}>
                {`판매자 : ${props.designated.user_id}`}
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: "20px" }}>
              <Typography className={contents_s.label2}>
                {`과장성 : ${props.designated.hype ? "O" : "-"}`}
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: "20px" }}>
              <Typography className={contents_s.label2}>
                {`금칙어 : ${props.designated.banned ? "O" : "-"}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Tooltip title="닫기">
              <IconButton1
                size="small"
                aria-label="close"
                onClick={handleCancel}
                style={{ color: "#ffffff" }}
              >
                <CloseIcon />
              </IconButton1>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          className={contents_s.paper}
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs container direction="row" justify="center">
            <Grid item style={{ textAlign: "center" }}>
              <Typography className={header_s.title}>
                {`새로운 ${
                  props.designated.type === 1
                    ? "게시글"
                    : props.designated.type === 2
                    ? "댓글"
                    : "글"
                }을 작성했습니다.`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Tooltip title="보기">
              <IconButton1
                size="small"
                aria-label="close"
                onClick={handleShow}
                style={{ color: "#ffffff" }}
              >
                <ArrowForwardIcon />
              </IconButton1>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SnackMessage;
