import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import {
  Grid,
  List,
  Badge,
  Paper,
  Avatar,
  Divider,
  ListItem,
  Popover,
  Typography,
} from "@material-ui/core";

import {
  IconButton1,
  ConfirmPasswordDialog,
  ConfirmHypeStandardDialog,
  ConfirmContentsListDetailDialog,
} from "../../components";

import PersonIcon from "@material-ui/icons/Person";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsIcon from "@material-ui/icons/Notifications";

import { makeStyles } from "@material-ui/core/styles";
import { Button1 } from "../../components";
import { LogOut, Update_Alarm } from "../../actions/auth";
import { SendQuery } from "../../actions/send";

import moment from "moment-timezone";

const contentStyles = makeStyles((theme) => ({
  profilePaper: {
    width: "300px",
    height: "100%",
    padding: "15px 5px 10px",
  },
  notiPaper: {
    width: "330px",
    height: "100%",
  },
  profileGridItem: {
    padding: "5px 0 5px",
  },
  label1: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#292929",
  },
  subLabel1: {
    fontSize: "13px",
    fontFamily: "Noto Sans",
    color: "#292929",
  },
  subLabel2: {
    fontSize: "12px",
    fontFamily: "Noto Sans",
    color: "#292929",
    opacity: "0.6",
  },
  subLabel4: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#00b5ef",
  },
  profileGridItem2: {
    padding: "4px 5px 0",
  },
  notiList: {
    minWidth: "330px",
    maxWidth: "330px",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: "204px",
    borderBottom: "1px solid #c9c9c9",
    padding: 0,
  },
  notiDivider: {
    height: "1px",
    backgroundColor: "#c9c9c9",
  },
  notiListItem: {
    width: "100%",
    maxWidth: "330px",
    position: "relative",
    minHeight: "60px",
    padding: "3px 8px 3px",
  },
  listLabel1: {
    fontSize: "13px",
    fontFamily: "Noto Sans",
    color: "#707070",
  },
  listLabel2: {
    fontSize: "12px",
    fontFamily: "Noto Sans",
    color: "#707070",
    opacity: "0.8",
  },
  divider: {
    width: "90%",
    height: "1px",
    backgroundColor: "#cbcbcb",
  },
  profileGridItem3: {
    maxWidth: "80%",
  },
}));

function Header(props) {
  const dispatch = useDispatch();
  const contents_s = contentStyles();

  const headerURL = "api/header";
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const profileOpen = Boolean(profileAnchorEl);
  const profile_id = profileOpen ? "profile-popover" : undefined;

  const seeAllMessageTitle = "모든 알람 보기";
  const [notiAnchorEl, setNotiAnchorEl] = useState(null);
  const notiOpen = Boolean(notiAnchorEl);
  const noti_id = notiOpen ? "notification-popover" : undefined;

  const [userID, setUserID] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userDepartment, setUserDepartment] = useState(null);
  const [userDescription, setUserDescription] = useState(null);

  const [openConfirmPasswordDlg, setOpenConfirmPasswordDlg] = useState(false);
  const [isOpenHypeStandard, setIsOpenHypeStandard] = useState(false);

  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selected, setSelectData] = useState(undefined);

  const [redirectPage, setRedirectPage] = useState({
    isRedirect: false,
    pathname: "",
    search: "",
    state: {},
  });

  useEffect(() => {
    props.handleSidebar(true);
  }, []);

  useEffect(() => {
    if (redirectPage.isRedirect) {
      setRedirectPage({
        isRedirect: false,
        pathname: "",
        search: "",
        state: {},
      });
    }
  }, [redirectPage]);

  useEffect(() => {
    if (!selected) setIsDetailOpen(false);
    else setIsDetailOpen(true);
  }, [selected]);

  const databaseSend = (location, data, callback) => {
    SendQuery(location, props.auth.user_info, data, (err, res) => {
      if (err || !res) return callback(null);

      if (res.data && res.data.code === 500) {
        return dispatch(LogOut());
      }

      return callback(res.data);
    });
  };

  const handleSidebar = () => {
    props.handleSidebar(!props.openSidebar);
  };

  const onOpenProfile = (event) => {
    if (props.auth) {
      setUserID(props.auth.user_info.user_id);
      setUserEmail(props.auth.user_info.email);
      setUserName(props.auth.user_info.name);
      setUserDepartment(props.auth.user_info.department);
      setUserDescription(props.auth.user_info.description);
    }
    setProfileAnchorEl(event.currentTarget);
  };

  const onCloseProfile = () => {
    setProfileAnchorEl(null);
  };

  const onLogoutClick = () => {
    onCloseProfile();
    dispatch(LogOut());
  };

  const handleHypeStandard = () => {
    setIsOpenHypeStandard(true);
  };

  const onCloseHypeStandard = () => {
    setIsOpenHypeStandard(false);
  };

  const handleAlarm = (event) => {
    handleShowAlarm(event.currentTarget);
  };

  const handleShowAlarm = (currentTarget) => {
    if (currentTarget) {
      setNotiAnchorEl(currentTarget);
    }
  };

  const onSeeAllMessageClick = () => {
    setRedirectPage({
      isRedirect: true,
      pathname: "/dashboard/seller/designated",
      state: { seeAllMessage: true },
    });
    onCloseNotification();
  };

  const onCloseNotification = () => {
    setNotiAnchorEl(null);
  };

  const renderRedirect = () => {
    if (redirectPage.isRedirect) {
      return (
        <Redirect
          to={{
            pathname: redirectPage.pathname,
            state: redirectPage.state,
          }}
        />
      );
    }
  };

  const handleDetailCancel = () => {
    setSelectData(undefined);
  };

  const onOpenConfirmDlg = (event, idx) => {
    setSelectData(event);
    let alarm_info = [...props.auth.alarm_info];
    alarm_info.splice(idx, 1);
    dispatch(Update_Alarm(alarm_info));
    onCloseNotification();
  };

  const onChangePasswordClick = () => {
    // 패스워드 변경 버튼 클릭
    setOpenConfirmPasswordDlg(true);
    return;
  };

  const onOkConfirmPasswordDlg = () => {
    setOpenConfirmPasswordDlg(false);
  };

  const onCancelConfirmPasswordDlg = () => {
    setOpenConfirmPasswordDlg(false);
    return;
  };

  const noticeItem = (event, idx) => {
    let user_text = `판매자: ${event.user_id}`;
    let hype_text = `과장성: ${event.hype ? "O" : "X"}`;
    let banned_text = `금칙어: ${event.banned ? "O" : "X"}`;
    let created_time = `[${moment
      .utc(event.created_time)
      .local()
      .format("YYYY-MM-DD HH:mm:ss")}]`;
    let contents = `새로운 ${
      event.parents_pkey ? "댓글" : "게시글"
    }을 작성했습니다.`;
    return (
      <>
        {idx > 0 && (
          <Divider variant="middle" className={contents_s.notiDivider} />
        )}
        <ListItem
          button
          alignItems="flex-start"
          className={contents_s.notiListItem}
          onClick={() => {
            onOpenConfirmDlg(event, idx);
          }}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={1} style={{ padding: "5px 0 0" }}>
              <NotificationsIcon
                style={{ color: "#b9b9b9", fontSize: "28px" }}
              />
            </Grid>
            <Grid
              item
              xs={10}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              style={{ padding: "2px 15px 2px 10px" }}
            >
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    className={contents_s.listLabel1}
                    title={user_text}
                    noWrap={true}
                  >
                    {user_text}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={contents_s.listLabel1}
                    title={hype_text}
                    noWrap={true}
                  >
                    {hype_text}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={contents_s.listLabel1}
                    title={banned_text}
                    noWrap={true}
                  >
                    {banned_text}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={contents_s.listLabel2}
                  title={created_time}
                  noWrap={true}
                >
                  {created_time}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={contents_s.listLabel2}
                  title={contents}
                  noWrap={true}
                >
                  {contents}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      </>
    );
  };

  return (
    <>
      {renderRedirect()}
      {openConfirmPasswordDlg && (
        <ConfirmPasswordDialog
          isOpen={openConfirmPasswordDlg}
          user_id={props.auth.user_info.user_id}
          itemName={"비밀번호 변경"}
          itemName1={"최소( 4자 )"}
          okName={"확인"}
          cancelName={"취소"}
          databaseSend={databaseSend}
          onOkConfirmPasswordDlg={onOkConfirmPasswordDlg}
          onCancelConfirmPasswordDlg={onCancelConfirmPasswordDlg}
        />
      )}
      {isDetailOpen && (
        <ConfirmContentsListDetailDialog
          cancelName="확인"
          isOpen={isDetailOpen}
          selected={selected}
          databaseSend={databaseSend}
          handleCancel={handleDetailCancel}
        />
      )}
      {isOpenHypeStandard && (
        <ConfirmHypeStandardDialog
          okName="저장"
          cancelName="취소"
          isOpen={isOpenHypeStandard}
          handleCancel={onCloseHypeStandard}
          databaseSend={databaseSend}
        />
      )}
      {notiOpen && (
        <Popover
          id={noti_id}
          open={notiOpen}
          anchorEl={notiAnchorEl}
          onClose={onCloseNotification}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          disableScrollLock={true}
        >
          <Paper className={contents_s.notiPaper}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={12}>
                <List className={contents_s.notiList}>
                  {Array.isArray(props.auth.alarm_info) &&
                    props.auth.alarm_info.map((alarm, idx) => {
                      return noticeItem(alarm, idx);
                    })}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Button1
                  size="small"
                  style={{
                    minWidth: "330px",
                    maxWidth: "330px",
                    padding: "5px 0 5px",
                  }}
                  onClick={onSeeAllMessageClick}
                >
                  <Typography
                    className={contents_s.subLabel1}
                    title={seeAllMessageTitle}
                    noWrap={true}
                  >
                    {seeAllMessageTitle}
                  </Typography>
                </Button1>
              </Grid>
            </Grid>
          </Paper>
        </Popover>
      )}
      {profileOpen && (
        <Popover
          id={profile_id}
          open={profileOpen}
          anchorEl={profileAnchorEl}
          onClose={onCloseProfile}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          disableScrollLock={true}
        >
          <Paper className={contents_s.profilePaper}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                className={contents_s.profileGridItem}
              >
                <Grid item>
                  <Avatar
                    variant="square"
                    style={{ width: "70px", height: "70px" }}
                    src="/img/profile_icon.svg"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                className={contents_s.profileGridItem}
              >
                <Grid item>
                  <Typography
                    className={contents_s.label1}
                    title={userID}
                    noWrap={true}
                  >
                    {userID}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Divider
                  variant="middle"
                  style={{ height: "1px", backgroundColor: "#cbcbcb" }}
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={contents_s.profileGridItem2}
              >
                <Grid
                  item
                  xs={4}
                  container
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      className={contents_s.subLabel1}
                      title={"이름"}
                      noWrap={true}
                    >
                      {"이름"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={8}
                  container
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      className={contents_s.subLabel2}
                      title={userName}
                      noWrap={true}
                    >
                      {userName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={contents_s.profileGridItem2}
              >
                <Grid
                  item
                  xs={4}
                  container
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      className={contents_s.subLabel1}
                      title={"이메일"}
                      noWrap={true}
                    >
                      {"이메일"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={8}
                  container
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      className={contents_s.subLabel2}
                      title={userEmail}
                      noWrap={true}
                    >
                      {userEmail}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid
                item
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={contents_s.profileGridItem2}
              >
                <Grid
                  item
                  xs={4}
                  container
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      className={contents_s.subLabel1}
                      title={"부서"}
                      noWrap={true}
                    >
                      {"부서"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={8}
                  container
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      className={contents_s.subLabel2}
                      title={userDepartment}
                      noWrap={true}
                    >
                      {userDepartment}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid
                item
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={contents_s.profileGridItem2}
                style={{ paddingBottom: "4px" }}
              >
                <Grid
                  item
                  xs={4}
                  container
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      className={contents_s.subLabel1}
                      title={"설명"}
                      noWrap={true}
                    >
                      {"설명"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={8}
                  container
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      className={contents_s.subLabel2}
                      title={userDescription}
                      noWrap={true}
                    >
                      {userDescription}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider variant="middle" className={contents_s.divider} />
              <Grid item container direction="row" justify="center">
                <Grid item className={contents_s.profileGridItem3}>
                  <Button1
                    size="small"
                    style={{ minWidth: "240px", maxWidth: "240px" }}
                    onClick={onChangePasswordClick}
                  >
                    <Typography
                      className={contents_s.subLabel1}
                      title={"비밀번호 변경"}
                      noWrap={true}
                    >
                      {"비밀번호 변경"}
                    </Typography>
                  </Button1>
                </Grid>
              </Grid>
              <Grid item>
                <Divider
                  variant="middle"
                  style={{ height: "1px", backgroundColor: "#cbcbcb" }}
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
              >
                <Button1
                  size="small"
                  onClick={onLogoutClick}
                  style={{ margin: "10px 15px 0px" }}
                >
                  <Typography
                    className={contents_s.subLabel4}
                    title={"로그아웃"}
                    noWrap={true}
                  >
                    {"로그아웃"}
                  </Typography>
                </Button1>
              </Grid>
            </Grid>
          </Paper>
        </Popover>
      )}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{
          position: "fixed",
          height: "50px",
          backgroundColor: "white",
          zIndex: "1100", // app bar z Index : 1100
        }}
      >
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="stretch"
          style={{
            width: props.openSidebar && !props.isSmall ? "240px" : "35px",
          }}
        >
          <Grid item></Grid>
          <Grid
            item
            xs
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <Link
                to="/dashboard"
                hidden={!props.openSidebar || props.isSmall}
              >
                <img
                  src="/img/atomyaza_ci.jpg"
                  alt="Atomy"
                  style={{ height: "20px" }}
                />
              </Link>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton1
              size="small"
              onClick={handleSidebar}
              style={{
                color: "#00b5ef",
              }}
            >
              {props.openSidebar && !props.isSmall ? (
                <ArrowLeftIcon fontSize="large" />
              ) : (
                <MenuIcon fontSize="large" />
              )}
            </IconButton1>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <Link
                to="/dashboard"
                hidden={props.openSidebar && !props.isSmall}
              >
                <img
                  src="/img/atomyaza_ci.jpg"
                  alt="Atomy"
                  style={{ height: "20px" }}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ marginRight: "10px" }}>
          <Grid container direction="row">
            <Grid item>
              <IconButton1
                size="small"
                style={{
                  color: "#a9a9a9",
                  height: "32px",
                  width: "32px",
                }}
                onClick={handleAlarm}
              >
                <Badge
                  color="error"
                  variant="dot"
                  invisible={
                    Array.isArray(props.auth.alarm_info) &&
                    props.auth.alarm_info.length > 0
                      ? false
                      : true
                  }
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton1>
            </Grid>
            <Grid item style={{ marginLeft: "10px" }}>
              <IconButton1
                size="small"
                style={{
                  color: "#a9a9a9",
                  height: "32px",
                  width: "32px",
                }}
                onClick={handleHypeStandard}
              >
                <SettingsIcon />
              </IconButton1>
            </Grid>
            <Grid item style={{ marginLeft: "10px" }}>
              <IconButton1
                size="small"
                style={{
                  backgroundColor: "#e9e9e9",
                  color: "#a9a9a9",
                  height: "32px",
                  width: "32px",
                }}
                onClick={onOpenProfile}
              >
                <PersonIcon />
              </IconButton1>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Header;
