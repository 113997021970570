import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  defaultText: {
    fontFamily: "Noto Sans",
  },
  radioLabel: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#77BBD4",
    margin: "0px 0px 0px 5px",
  },
  dateBtn: {
    color: "#3c8dbc",
    backgroundColor: "white",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    fontSize: "14px",
    height: "30px",
    margin: "5px",
  },
  searchBtn: {
    "&:hover": {
      backgroundColor: "#3c8dbc",
    },
    color: "white",
    backgroundColor: "#3c8dbc",
    height: "30px",
    marginLeft: "10px",
  },
  shortcutsBtn: {
    color: "#b9b9b9",
    backgroundColor: "white",
    height: "30px",
    marginLeft: "10px",
  },
  defaultGridCell: {
    "justify-content": "flex-start",
    "align-items": "center",
    border: "none !important",
    fontFamily: "Noto Sans",
    fontSize: "13px",
    "text-align": "center",
  },
  defaultGridHeader: {
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    backgroundColor: "#ffffff",
  },
  keyboardDatePickerBtnRoot: {
    outline: "none !important",
    padding: "2px !important",
    margin: "2px 5px 0px -10px !important",
  },
  keyboardDatePickerInputRoot: {
    fontSize: "16px",
    color: "#262626",
    height: "100%",
    width: "150px",
    backgroundColor: "white",
    padding: "5px 0px !important",
    border: "1px solid #e0e0e0",
    borderRadius: 5,
  },
  keyboardDatePickerInput: {
    padding: "0px !important",
    textAlign: "center",
    fontFamily: "Noto Sans",
  },
  comboBoxLabel: {
    fontSize: "13px",
    fontFamily: "Noto Sans",
    color: "#656565",
    textAlign: "left",
    padding: "0px 10px 0px",
  },
  comboBoxSubLabel: {
    fontSize: "12px",
    fontFamily: "Noto Sans",
    color: "#656565",
    textAlign: "left",
  },
  comboBoxRoot: {
    minHeight: "28px",
    width: "150px",
    margin: "0px",
    padding: "0px",
    background: "#ffffff",
    borderRadius: "3px",
    border: "1px solid #b9b9b9",
    justifyContent: "flex-start",
  },
}));
