import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "../../../style.js";
import { global_theme } from "../../../theme.js";
import {
  Grid,
  Backdrop,
  Divider,
  Typography,
  InputAdornment,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

import {
  Button1,
  IconButton1,
  OutlinedInput1,
  TextField1,
  ColorRadio,
  ConfirmContentsListDetailDialog,
} from "../../../../components";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import StopIcon from "@material-ui/icons/Stop";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Label,
} from "recharts";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

// import { ConfirmDialog, ConfirmDialog2 } from "../Dialog";
import {
  PieLabel,
  getLocale,
  setResetTimeVal,
  resetEventGraph,
  onHyypeRenderer,
  onDateCellRenderer,
  onWordsCellRenderer,
  onBannedRenderer,
} from "../../../utils";

import { SendQuery } from "../../../../actions/send";
import { LogOut, IsDesignated } from "../../../../actions/auth";
moment.locale(getLocale());

const contentStyles = makeStyles((theme) => ({
  defaultDate: {
    color: "#3c8dbc",
    backgroundColor: "white",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    fontSize: "14px",
    height: "30px",
    margin: "5px",
  },
  selectDate: {
    "&:hover": {
      backgroundColor: "#3cadbc",
    },
    color: "white",
    backgroundColor: "#3c8dbc",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    fontSize: "14px",
    height: "30px",
    margin: "5px",
  },
  searchBtn: {
    "&:hover": {
      backgroundColor: "#3c8dbc",
    },
    color: "white",
    backgroundColor: "#3c8dbc",
    height: "30px",
    marginLeft: "10px",
  },
  shortcutsBtn: {
    color: "#b9b9b9",
    backgroundColor: "white",
    height: "30px",
    marginLeft: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  cardRoot: {
    backgroundColor: "#ffffff",
    marginRight: "10px",
    marginBottom: "10px",
    borderRadius: "15px",
    maxWidth: "650px",
  },
  comboBoxLabel: {
    fontSize: "13px",
    fontFamily: "Noto Sans",
    color: "#656565",
    textAlign: "center",
    padding: "0px 10px 0px",
  },
  comboBoxRoot: {
    minHeight: "24px",
    width: "150px",
    margin: "0px",
    padding: "0px 5px",
    background: "#ffffff",
    borderRadius: "3px",
    border: "1px solid #c9c9c9",
    justifyContent: "flex-start",
  },
  iconSize: {
    fontSize: "14px",
  },
}));

let old_date = 0;
let date_type = false;
let selectedDate = 0;
let backdrop_count = 0;
function Detail(props) {
  const dispatch = useDispatch();
  const global_s = useStyles();
  const global_t = global_theme;
  const contents_s = contentStyles();

  const sellerURL = "api/dashboard/seller/detail";
  const normalLabel = "정상";
  const eventLabel = "이벤트";
  const forbiddenLabel = "금지어";
  const cautionLabel = "주의어";
  const combinationLabel = "조합어";
  const bannedSentenceLabel = "금칙어 문장";
  const hypeSentenceLabel = "과장성 문장";

  const originContentsCols = [
    { name: normalLabel, color: "#007FAA" },
    { name: eventLabel, color: "#80BFEA" },
  ];

  const originSentenceCols = [
    { name: hypeSentenceLabel, color: "#007FAA" },
    { name: bannedSentenceLabel, color: "#80BFEA" },
  ];

  const [sellerSearch, setSellerSearch] = useState("");
  const [sellerList, setSellerList] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState(undefined);

  const [selectDate, setSelectDate] = useState(1);
  const [startDate, setStartDate] = useState(
    setResetTimeVal(moment().local().format())
  );
  const [endDate, setEndDate] = useState(
    setResetTimeVal(moment().local().format())
  );

  // dataType 0 - real / 1 - test
  const [dataType, setDataType] = useState(0);

  const [totalCount, setTotalCount] = useState(0);
  const [eventCount, setEventCount] = useState(0);
  const [eventRatio, setEventRatio] = useState(0);
  const [totalSentenceCount, setTotalSentenceCount] = useState(0);
  const [eventSentenceCount, setEventSentenceCount] = useState(0);
  const [eventSentenceRatio, setEventSentenceRatio] = useState(0);

  const [originGraph, setOriginGraph] = useState([]);
  const [originContentsPie, setOriginContentsPie] = useState([]);
  const [originSentencePie, setOriginSentencePie] = useState([]);

  const [isOpenContentsList, setIsOpenContentsList] = useState(false);

  const [isBackdrop, setIsBackdrop] = useState(false);

  // Grid 사용 부분
  const [contentsSearch, setContentsSearch] = useState("");
  const [contentsGridApi, setContentsGridApi] = useState(null);
  const [contentsRows, setContentsRows] = useState([]);
  const contentsColumnDef = [
    {
      field: "updated_time",
      headerName: "수정일",
      width: 160,
      sortable: true,
      resizable: true,
      cellRenderer: onDateCellRenderer,
    },
    {
      field: "sort",
      headerName: "종류",
      width: 80,
      sortable: true,
      resizable: true,
    },
    {
      field: "title",
      headerName: "제목",
      width: 370,
      sortable: true,
      resizable: true,
    },

    {
      field: "contents",
      headerName: "내용",
      width: 730,
      sortable: true,
      resizable: true,
    },
    {
      field: "hype",
      headerName: "과장성",
      width: 120,
      sortable: true,
      resizable: true,
      cellRenderer: onHyypeRenderer,
    },
    {
      field: "banned",
      headerName: "금칙어",
      width: 120,
      sortable: true,
      resizable: true,
      cellRenderer: onBannedRenderer,
    },
  ];

  const [bannedSearch, setBannedSearch] = useState("");
  const [bannedGridApi, setBannedGridApi] = useState(null);
  const [bannedRows, setBannedRows] = useState([]);
  const bannedColumnDef = [
    {
      field: "word1",
      hide: true,
    },
    {
      field: "word2",
      hide: true,
    },
    {
      field: "word3",
      hide: true,
    },
    {
      field: "word4",
      hide: true,
    },
    {
      field: "word5",
      hide: true,
    },
    {
      field: "words",
      headerName: "단어",
      tooltipField: "words",
      width: 260,
      sortable: true,
      resizable: true,
    },
    {
      field: "sortLabel",
      headerName: "종류",
      width: 260,
      sortable: true,
      resizable: true,
    },
    {
      field: "count",
      headerName: "횟수(개)",
      width: 260,
      sortable: true,
      resizable: true,
    },
  ];

  const [contentsInfo, setContentsInfo] = useState(undefined);
  const [backTimer, setBackTimer] = useState(1000);

  useEffect(() => {
    dispatch(IsDesignated(false));
  }, []);

  useEffect(() => {
    if (selectedSeller && selectDate !== -1 && selectDate > 0) {
      date_type = dataType;
      selectedDate = selectDate;
      search_statistics(true);
      search_contents(true);
    }
  }, [selectedSeller]);

  useEffect(() => {
    const start = setInterval(() => {
      if (parseInt(backTimer) > 0) {
        setBackTimer(parseInt(backTimer) - 1);
      }
      if (parseInt(backTimer) === 0) {
        setBackTimer(1000);
      }

      if (!check_today()) return;

      if (selectDate === -1) return setSelectDate(old_date);

      if (selectDate > 0 && selectDate < 5 && selectedSeller) {
        search_statistics(false);
        search_contents(false);
      }
    }, 10000);
    return () => clearInterval(start);
  }, [backTimer]);

  useEffect(() => {
    if (contentsInfo) setIsOpenContentsList(true);
    else setIsOpenContentsList(false);
  }, [contentsInfo]);

  useEffect(() => {
    search_seller();
    if (selectedSeller && selectDate !== -1 && selectDate > 0) {
      date_type = dataType;
      selectedDate = selectDate;
      search_statistics(true);
      search_contents(true);
    }
  }, [dataType]);

  useEffect(() => {
    if (selectedSeller && selectDate !== -1 && selectDate > 0) {
      date_type = dataType;
      selectedDate = selectDate;
      search_statistics(true);
      search_contents(true);
    }
  }, [selectDate]);

  const check_today = () => {
    let datetime = moment(startDate);
    let s_datetime = undefined;
    let e_datetime = undefined;

    switch (selectDate) {
      case 1:
        if (datetime.format("dd") === moment().local().format("dd"))
          return true;

        s_datetime = setResetTimeVal(moment().local().format());
        e_datetime = setResetTimeVal(moment().local().format());
        break;
      case 2:
        if (datetime.format("mm") !== moment().local().format("mm"))
          return true;

        s_datetime = setResetTimeVal(moment().format("YYYY-MM"));
        e_datetime = setResetTimeVal(moment().format("YYYY-MM"), 1);
        break;
      case 3:
        if (datetime.format("mm") !== moment().local().format("mm"))
          return true;

        s_datetime = setResetTimeVal(moment().format("YYYY-MM"));
        e_datetime = setResetTimeVal(moment().format("YYYY-MM"), 1);
        break;
      case 4:
        if (datetime.format("yyyy") !== moment().local().format("yyyy"))
          return true;

        s_datetime = setResetTimeVal(moment().format("YYYY"));
        e_datetime = setResetTimeVal(moment().format("YYYY"), 2);
        break;
      default:
        return true;
    }

    old_date = selectDate;
    setStartDate(s_datetime);
    setEndDate(e_datetime);
    setSelectDate(-1);
    return false;
  };

  const databaseSend = (location, data, callback) => {
    SendQuery(location, props.auth.user_info, data, (err, res) => {
      if (err || !res) return callback(null);

      if (res.data && res.data.code === 500) {
        return dispatch(LogOut());
      }

      return callback(res.data);
    });
  };

  const search_seller = () => {
    backdrop_count++;
    setIsBackdrop(true);

    SendQuery(
      "api/dashboard/seller",
      props.auth.user_info,
      {
        isTest: dataType,
      },
      (err, res) => {
        if (err || !res) return setIsBackdrop(false);

        if (res.data && res.data.code === 500) {
          setIsBackdrop(false);
          return dispatch(LogOut());
        }

        res.data.seller_list = res.data.seller_list.map((info) => {
          info.seller = `${info.user_id}(${info.name ? info.name : "-"})`;
          return info;
        });

        setSellerList([...res.data.seller_list]);

        if (backdrop_count) backdrop_count--;
        if (backdrop_count === 0) return setIsBackdrop(false);
      }
    );
  };

  const search_statistics = (selected = false) => {
    if (selected) {
      backdrop_count++;
      setIsBackdrop(true);
    }

    SendQuery(
      "api/dashboard/statistics",
      props.auth.user_info,
      {
        isTest: dataType,
        isBanned: true,
        user_id: selectedSeller.user_id,
        start_date: setResetTimeVal(startDate)
          .tz("UTC")
          .format("YYYY-MM-DD HH:mm:ss.SSS"),
        end_date: setResetTimeVal(endDate, -1)
          .tz("UTC")
          .format("YYYY-MM-DD HH:mm:ss.SSS"),
      },
      (err, res) => {
        if (err || !res) return setIsBackdrop(false);

        if (res.data && res.data.code === 500) {
          setIsBackdrop(false);
          return dispatch(LogOut());
        }

        if (selectedDate !== selectDate || date_type !== dataType)
          return setIsBackdrop(false);

        let custom_time = false;
        let graph_option = {};
        switch (selectDate) {
          case 1:
            graph_option.start = 0;
            graph_option.end = 24;
            graph_option.label = "시";
            break;
          case 2:
            graph_option.start = 1;
            graph_option.end =
              parseInt(setResetTimeVal(endDate, true).tz("UTC").format("D")) +
              2;
            graph_option.label = "일";
            break;
          case 3:
            graph_option.start = 1;
            graph_option.end =
              parseInt(setResetTimeVal(endDate, true).tz("UTC").format("D")) +
              2;
            graph_option.end =
              graph_option.end % 7 > 0
                ? graph_option.end / 7 + 1
                : graph_option.end / 7;
            graph_option.label = "주";
            break;
          case 4:
            graph_option.start = 1;
            graph_option.end = 13;
            graph_option.label = "월";
            break;
          case 5:
            graph_option.s_datetime = startDate;
            graph_option.e_datetime = endDate;
            if (
              moment(endDate)
                .startOf("day")
                .diff(moment(startDate).startOf("day"), "days") !== 0
            )
              custom_time = true;
            break;
          default:
            return;
        }

        graph_option.labels = [normalLabel, eventLabel];
        let graph_data = resetEventGraph(graph_option);

        let total_count = 0;
        let total_event = 0;
        let total_sentence_count = 0;
        let event_sentence_count = 0;
        let total_sentence_banned = 0;
        let total_sentence_hype = 0;
        let banned_row = [];

        res.data.statistics_list.forEach((statistics) => {
          total_count += statistics.totalCount;
          total_event += statistics.eventCount;
          total_sentence_count += statistics.sentenceCount;
          event_sentence_count += statistics.sentenceEvent;
          total_sentence_banned += statistics.sentenceBannedCount;
          total_sentence_hype += statistics.sentenceHypeCount;

          let idx = 0;
          let datetime = moment.utc(statistics.created_time).local();
          switch (selectDate) {
            case 1:
              idx = datetime.format("H");
              break;
            case 2:
              idx = datetime.format("D") - 1;
              break;
            case 3:
              idx = datetime.format("D") - 1;
              idx = idx !== 0 ? parseInt(idx / 7) : 0;
              break;
            case 4:
              idx = datetime.format("M") - 1;
              break;
            case 5:
              if (!custom_time) idx = datetime.format("H");
              else idx = datetime.diff(moment(startDate), "days");
              break;
            default:
              return;
          }

          graph_data[idx][normalLabel] +=
            statistics.totalCount - statistics.eventCount;
          graph_data[idx][eventLabel] += statistics.eventCount;
        });

        res.data.statistics_banned_list.forEach((banned) => {
          let idx = banned_row.findIndex((val) => {
            if (
              val.sort === banned.sort &&
              val.standard === banned.standard &&
              val.word1 === banned.word1 &&
              val.word2 === banned.word2 &&
              val.word3 === banned.word3 &&
              val.word4 === banned.word4 &&
              val.word5 === banned.word5
            )
              return val;
          });

          if (idx !== -1) banned_row[idx].count += banned.count;
          else {
            let words = "";
            if (banned.word1) words += `${banned.word1}; `;
            if (banned.word2) words += `${banned.word2}; `;
            if (banned.word3) words += `${banned.word3}; `;
            if (banned.word4) words += `${banned.word4}; `;
            if (banned.word5) words += `${banned.word5}; `;

            banned_row.push({
              sort: banned.sort,
              sortLabel:
                banned.sort === 1
                  ? forbiddenLabel
                  : banned.sort === 2
                  ? cautionLabel
                  : combinationLabel,
              standard: banned.standard,
              words: words,
              word1: banned.word1,
              word2: banned.word2,
              word3: banned.word3,
              word4: banned.word4,
              word5: banned.word5,
              count: banned.count,
            });
          }
        });

        setTotalCount(total_count);
        setEventCount(total_event);
        setTotalSentenceCount(total_sentence_count);
        setEventSentenceCount(event_sentence_count);

        setEventRatio(
          total_count > 0
            ? total_event > 0
              ? ((total_event / total_count) * 100).toFixed(2)
              : (0).toFixed(2)
            : (0).toFixed(2)
        );
        setEventSentenceRatio(
          total_sentence_count > 0
            ? event_sentence_count > 0
              ? ((event_sentence_count / total_sentence_count) * 100).toFixed(2)
              : (0).toFixed(2)
            : (0).toFixed(2)
        );

        setOriginGraph([...graph_data]);
        setBannedRows([...banned_row]);

        let normalCount = total_count - total_event;
        setOriginContentsPie([
          {
            name: normalLabel,
            value: normalCount < 0 ? 0 : normalCount,
            color: "#007FAA",
          },
          {
            name: eventLabel,
            value: total_event,
            color: "#80BFEA",
          },
        ]);
        setOriginSentencePie([
          {
            name: bannedSentenceLabel,
            value: total_sentence_banned,
            color: "#80BFEA",
          },
          {
            name: hypeSentenceLabel,
            value: total_sentence_hype,
            color: "#007FAA",
          },
        ]);

        if (selected) {
          if (backdrop_count) backdrop_count--;
        }

        if (backdrop_count === 0) return setIsBackdrop(false);
      }
    );
  };

  const search_contents = (selected = false) => {
    if (selected) {
      backdrop_count++;
      setIsBackdrop(true);
    }

    SendQuery(
      "api/dashboard/contents",
      props.auth.user_info,
      {
        isTest: dataType,
        user_id: selectedSeller.user_id,
        start_date: setResetTimeVal(startDate)
          .tz("UTC")
          .format("YYYY-MM-DD HH:mm:ss.SSS"),
        end_date: setResetTimeVal(endDate, -1)
          .tz("UTC")
          .format("YYYY-MM-DD HH:mm:ss.SSS"),
      },
      (err, res) => {
        if (err || !res) return setIsBackdrop(false);

        if (res.data && res.data.code === 500) {
          setIsBackdrop(false);
          return dispatch(LogOut());
        }

        if (selectedDate !== selectDate || date_type !== dataType)
          return setIsBackdrop(false);

        let banned_count = 0;
        let hype_count = 0;
        let contents_row = [];
        res.data.contents_info.forEach((info) => {
          if (info.hype_t) {
            hype_count++;
            info.hype = true;
          }
          if (info.banned_t) {
            banned_count++;
            info.banned = true;
          }

          info.sort = info.parents_pkey ? "댓글" : "게시글";
          info.type = info.parents_pkey ? 2 : 1;
          info.created_time = moment.utc(info.created_time).local();
          info.seller = `${info.user_id}(${info.name ? info.name : "-"})`;

          contents_row.push(info);
        });

        contents_row = contents_row.sort(
          (a, b) => b.created_time.valueOf() - a.created_time.valueOf()
        );

        setContentsRows([...contents_row]);

        if (selected) {
          if (backdrop_count) backdrop_count--;
        }

        if (backdrop_count === 0) return setIsBackdrop(false);
      }
    );
  };

  const handleStartDateChange = (datetime) => {
    if (datetime > endDate) {
      datetime = setResetTimeVal(datetime);
      setEndDate(datetime);
    } else datetime = setResetTimeVal(datetime);

    setStartDate(datetime);
  };

  const handleEndDateChange = (datetime) => {
    if (startDate > datetime) {
      datetime = setResetTimeVal(datetime);
      setStartDate(datetime);
    } else datetime = setResetTimeVal(datetime);
    setEndDate(datetime);
  };

  const handleDateChange = (event) => {
    let id = event && event.currentTarget ? event.currentTarget.id : undefined;
    let s_datetime = undefined;
    let e_datetime = undefined;
    let selected = undefined;
    switch (id) {
      case "today":
        s_datetime = setResetTimeVal(moment().local().format());
        e_datetime = setResetTimeVal(moment().local().format());
        selected = 1;
        break;
      case "daily":
        s_datetime = setResetTimeVal(moment().format("YYYY-MM"));
        e_datetime = setResetTimeVal(moment().format("YYYY-MM"), 1);
        selected = 2;
        break;
      case "weekly":
        s_datetime = setResetTimeVal(moment().format("YYYY-MM"));
        e_datetime = setResetTimeVal(moment().format("YYYY-MM"), 1);
        selected = 3;
        break;
      case "monthly":
        s_datetime = setResetTimeVal(moment().format("YYYY"));
        e_datetime = setResetTimeVal(moment().format("YYYY"), 2);
        selected = 4;
        break;
      case "search":
        if (
          moment()
            .startOf("day")
            .diff(moment(startDate).startOf("day"), "days") === 0 &&
          moment()
            .startOf("day")
            .diff(moment(endDate).startOf("day"), "days") === 0
        ) {
          s_datetime = setResetTimeVal(moment().local().format());
          e_datetime = setResetTimeVal(moment().local().format());
          selected = 1;
        } else selected = 5;
        break;
      default:
        s_datetime = setResetTimeVal(moment().local().format());
        e_datetime = setResetTimeVal(moment().local().format());
        selected = 1;
        break;
    }

    if (s_datetime) setStartDate(s_datetime);
    else s_datetime = startDate;
    if (e_datetime) setEndDate(e_datetime);
    else e_datetime = endDate;

    setSelectDate(selected);
  };

  const onContentsGridReady = (params) => {
    setContentsGridApi(params.api);
  };

  const handleContentsSearch = (event) => {
    setContentsSearch(event.target.value);
    contentsGridApi.setQuickFilter(event.target.value);
  };

  const handleContentsSearchClear = () => {
    setContentsSearch("");
    contentsGridApi.setQuickFilter("");
  };

  const onRowDoubleClicked = (params) => {
    if (params) setContentsInfo(params.data);
  };

  const onBannedGridReady = (params) => {
    setBannedGridApi(params.api);
  };

  const handleBannedSearch = (event) => {
    setBannedSearch(event.target.value);
    bannedGridApi.setQuickFilter(event.target.value);
  };

  const handleBannedSearchClear = () => {
    setBannedSearch("");
    bannedGridApi.setQuickFilter("");
  };

  const handleSellerSearch = (event) => {
    setSellerSearch(event.target.value);
  };

  const handleSellerClick = (event, value, string) => {
    if (selectedSeller) {
      if (selectedSeller.seller === value) return;
    }

    let idx = sellerList.findIndex((info) => {
      if (info.seller === value) return info;
    });

    if (idx !== -1) setSelectedSeller(sellerList[idx]);
    else setSelectedSeller(undefined);
  };

  const handleContentsListCancel = () => {
    setContentsInfo(undefined);
  };

  const handleChangeDataType = (event) => {
    setDataType(parseInt(event.target.value));
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      style={{
        height: "100%",
        width: "100%",

        overflow: "auto",
        padding: "10px 0px 10px 10px",
        minWidth: "470px",
        minHeight: "700px",
      }}
    >
      {isOpenContentsList && (
        <ConfirmContentsListDetailDialog
          cancelName="확인"
          isOpen={isOpenContentsList}
          s_datetime={startDate.format("YYYY-MM-DD")}
          e_datetime={endDate.format("YYYY-MM-DD")}
          dataType={dataType}
          selected={contentsInfo}
          databaseSend={databaseSend}
          handleCancel={handleContentsListCancel}
        />
      )}
      {isBackdrop && (
        <Backdrop className={contents_s.backdrop} open={isBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="stretch"
        style={{ marginBottom: "10px" }}
      >
        <Grid item sm container direction="row" alignItems="center">
          <Grid item>
            <Typography
              className={global_s.defaultText}
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#3c8dbc",
                marginRight: "10px",
              }}
            >
              {"Dashboard"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={global_s.defaultText}
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#77BBD4",
              }}
            >
              {"판매자 분석"}
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              size="small"
              options={sellerList.map((option) => option.seller)}
              onChange={handleSellerClick}
              ListboxProps={{
                style: {
                  fontFamily: "Noto Sans",
                  padding: "0px",
                  fontSize: "12px",
                },
                hidden: sellerSearch.length > 0 ? false : true,
              }}
              renderInput={(params) => (
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <TextField1
                      {...params}
                      variant="outlined"
                      placeholder={"조회"}
                      onChange={handleSellerSearch}
                      style={{
                        maxWidth: "200px",
                        minWidth: "200px",
                        backgroundColor: "white",
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={dataType}
                onChange={handleChangeDataType}
                row
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      classes={{ label: global_s.defaultText }}
                      value={0}
                      control={<ColorRadio />}
                      label={
                        <Typography className={global_s.radioLabel}>
                          {`실시간`}
                        </Typography>
                      }
                      style={{ padding: 0, margin: 0 }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      classes={{ label: global_s.defaultText }}
                      value={1}
                      control={<ColorRadio />}
                      label={
                        <Typography className={global_s.radioLabel}>
                          {`테스트`}
                        </Typography>
                      }
                      style={{ padding: 0, margin: "0px 0px 0px 10px" }}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          sm
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ paddingRight: "10px" }}
        >
          <Grid item>
            <Button1
              id="today"
              className={
                selectDate === 1
                  ? contents_s.selectDate
                  : contents_s.defaultDate
              }
              onClick={handleDateChange}
            >
              {"오늘"}
            </Button1>
          </Grid>
          <Grid item>
            <Button1
              id="daily"
              className={
                selectDate === 2
                  ? contents_s.selectDate
                  : contents_s.defaultDate
              }
              onClick={handleDateChange}
            >
              {"일간"}
            </Button1>
          </Grid>
          <Grid item>
            <Button1
              id="weekly"
              className={
                selectDate === 3
                  ? contents_s.selectDate
                  : contents_s.defaultDate
              }
              onClick={handleDateChange}
            >
              {"주간"}
            </Button1>
          </Grid>
          <Grid item>
            <Button1
              id="monthly"
              className={
                selectDate === 4
                  ? contents_s.selectDate
                  : contents_s.defaultDate
              }
              onClick={handleDateChange}
            >
              {"월간"}
            </Button1>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            style={{
              width: "345px",
              padding: "0px 5px 0px 10px",
            }}
          >
            <Grid item>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <ThemeProvider theme={global_t.keyboardDatePickerTheme}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    autoOk
                    minDate="2020-01-01"
                    maxDate="2100-12-31"
                    value={startDate}
                    onChange={handleStartDateChange}
                    format="YYYY-MM-DD"
                    margin="none"
                    helperText={null}
                    KeyboardButtonProps={{
                      classes: {
                        root: global_s.keyboardDatePickerBtnRoot,
                      },
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      classes: {
                        root: global_s.keyboardDatePickerInputRoot,
                        input: global_s.keyboardDatePickerInput,
                      },
                    }}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item>
              <Typography
                className={global_s.defaultText}
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  margin: "0px 10px",
                }}
              >
                {"-"}
              </Typography>
            </Grid>
            <Grid item>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <ThemeProvider theme={global_t.keyboardDatePickerTheme}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    autoOk
                    minDate="2020-01-01"
                    maxDate="2100-12-31"
                    value={endDate}
                    onChange={handleEndDateChange}
                    format="YYYY-MM-DD"
                    margin="none"
                    helperText={null}
                    KeyboardButtonProps={{
                      classes: {
                        root: global_s.keyboardDatePickerBtnRoot,
                      },
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      classes: {
                        root: global_s.keyboardDatePickerInputRoot,
                        input: global_s.keyboardDatePickerInput,
                      },
                    }}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton1
              id="search"
              className={contents_s.searchBtn}
              onClick={handleDateChange}
              size="small"
            >
              <SearchIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item sm container direction="row" className={contents_s.cardRoot}>
          <Grid
            item
            xs
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ padding: "10px" }}
          >
            <Grid item xs>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#A48BF9",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    {totalCount}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#A48BF9",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {"개"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ padding: "2px" }}>
                <Divider
                  style={{
                    backgroundColor: "#DEE8EB",
                    padding: "0.5px",
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item container justify="center" alignItems="center">
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#8F8F8F",
                    }}
                  >
                    {"전체 등록 글"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ padding: "10px 0px" }}>
            <img src="/img/line.png" />
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ padding: "10px" }}
          >
            <Grid item xs>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#78D679",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    {eventCount}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#78D679",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {"개"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ padding: "2px" }}>
                <Divider
                  style={{
                    backgroundColor: "#DEE8EB",
                    padding: "0.5px",
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item container justify="center" alignItems="center">
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#8F8F8F",
                    }}
                  >
                    {"이벤트 발생 글"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ padding: "10px 0px" }}>
            <img src="/img/line.png" />
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ padding: "10px" }}
          >
            <Grid item xs>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#1A2226",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    {eventRatio}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#1A2226",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {"%"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ padding: "2px" }}>
                <Divider
                  style={{
                    backgroundColor: "#DEE8EB",
                    padding: "0.5px",
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item container justify="center" alignItems="center">
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#8F8F8F",
                    }}
                  >
                    {"전체 대비 비율"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm
          container
          direction="row"
          className={contents_s.cardRoot}
        />
        <Grid
          item
          sm
          container
          direction="row"
          className={contents_s.cardRoot}
        />
      </Grid>
      <Grid item container direction="row" justify="flex-start">
        <Grid
          item
          sm
          container
          direction="row"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            marginRight: "10px",
            marginBottom: "10px",
            borderRadius: "15px",
          }}
        >
          <Grid
            item
            container
            justify="space-between"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography
                className={global_s.defaultText}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#4FACCF",
                }}
              >
                {"이벤트 발생일"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              style={{
                padding: "5px 20px",
              }}
            >
              <Grid
                item
                xs
                style={{
                  height: "230px",
                  width: "250px",
                  marginRight: "10px",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width="100%"
                    height="100%"
                    data={originGraph}
                    margin={{ bottom: 30 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name">
                      <Label value="날짜 및 시간" position="bottom" />
                    </XAxis>
                    <YAxis padding={{ top: 40 }}>
                      <Label value="개수" position="insideTopLeft" />
                    </YAxis>
                    <Tooltip />
                    {originContentsCols.map((item) => {
                      return (
                        <Bar
                          dataKey={item.name}
                          stackId="a"
                          fill={item.color}
                        />
                      );
                    })}
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item>
                {Array.isArray(originContentsCols) &&
                  originContentsCols.map((defaultCol) => {
                    return (
                      <Grid
                        item
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item style={{ margin: "5px" }}>
                          <StopIcon style={{ color: defaultCol.color }} />
                        </Grid>
                        <Grid item>
                          <Typography
                            className={global_s.defaultText}
                            style={{ color: defaultCol.color }}
                          >
                            {defaultCol.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm
          container
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            marginRight: "10px",
            marginBottom: "10px",
            borderRadius: "15px",
          }}
        >
          <Grid
            item
            container
            justify="space-between"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography
                className={global_s.defaultText}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#4FACCF",
                }}
              >
                {"이벤트 비중"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs
            container
            justify="center"
            alignItems="center"
            style={{ padding: "5px" }}
          >
            <Grid
              item
              xs
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                maxWidth: "400px",
              }}
            >
              <Grid
                item
                xs
                style={{
                  height: "230px",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width="100%" height="100%">
                    <Tooltip />
                    <Pie
                      fill="#8884d8"
                      dataKey="value"
                      outerRadius={90}
                      data={originContentsPie}
                      label={PieLabel}
                      labelLine={false}
                      animationBegin={0}
                      animationDuration={1000}
                    >
                      {originContentsPie.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs container direction="column">
                {originContentsCols.map((temp) => {
                  return (
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item style={{ margin: "5px" }}>
                        <FiberManualRecordIcon style={{ color: temp.color }} />
                      </Grid>
                      <Grid item>
                        <Typography
                          className={global_s.defaultText}
                          style={{ color: temp.color }}
                        >
                          {temp.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item sm container direction="row" className={contents_s.cardRoot}>
          <Grid
            item
            xs
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ padding: "10px" }}
          >
            <Grid item xs>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#3B7AD8",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    {totalSentenceCount}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#3B7AD8",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {"개"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ padding: "2px" }}>
                <Divider
                  style={{
                    backgroundColor: "#DEE8EB",
                    padding: "0.5px",
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item container justify="center" alignItems="center">
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#8F8F8F",
                    }}
                  >
                    {"총 등록 문장"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ padding: "10px 0px" }}>
            <img src="/img/line.png" />
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ padding: "10px" }}
          >
            <Grid item xs>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#7945D4",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    {eventSentenceCount}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#7945D4",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {"개"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ padding: "2px" }}>
                <Divider
                  style={{
                    backgroundColor: "#DEE8EB",
                    padding: "0.5px",
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item container justify="center" alignItems="center">
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#8F8F8F",
                    }}
                  >
                    {"이벤트 발생 문장"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ padding: "10px 0px" }}>
            <img src="/img/line.png" />
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ padding: "10px" }}
          >
            <Grid item xs>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#1A2226",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    {eventSentenceRatio}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "5px" }}>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      color: "#1A2226",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {"%"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ padding: "2px" }}>
                <Divider
                  style={{
                    backgroundColor: "#DEE8EB",
                    padding: "0.5px",
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item container justify="center" alignItems="center">
                <Grid item>
                  <Typography
                    className={global_s.defaultText}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#8F8F8F",
                    }}
                  >
                    {"전체 대비 비율"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm
          container
          direction="row"
          className={contents_s.cardRoot}
        />
        <Grid
          item
          sm
          container
          direction="row"
          className={contents_s.cardRoot}
        />
      </Grid>
      <Grid item container direction="row" justify="flex-start">
        <Grid
          item
          sm
          container
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            marginRight: "10px",
            marginBottom: "10px",
            borderRadius: "15px",
          }}
        >
          <Grid
            item
            container
            justify="space-between"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography
                className={global_s.defaultText}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#4FACCF",
                }}
              >
                {"이벤트 비중"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs
            container
            justify="center"
            alignItems="center"
            style={{ padding: "10px" }}
          >
            <Grid
              item
              xs
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                maxWidth: "400px",
              }}
            >
              <Grid
                item
                xs
                style={{
                  height: "230px",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width="100%" height="100%">
                    <Pie
                      fill="#8884d8"
                      dataKey="value"
                      innerRadius={60}
                      outerRadius={90}
                      data={originSentencePie}
                      label={PieLabel}
                      labelLine={false}
                      animationBegin={0}
                      animationDuration={1000}
                    >
                      {originSentencePie.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs container direction="column">
                {originSentenceCols.map((temp) => {
                  return (
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item style={{ margin: "5px" }}>
                        <FiberManualRecordIcon style={{ color: temp.color }} />
                      </Grid>
                      <Grid item>
                        <Typography
                          className={global_s.defaultText}
                          style={{ color: temp.color }}
                        >
                          {temp.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm
          container
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            marginRight: "10px",
            marginBottom: "10px",
            borderRadius: "15px",
          }}
        >
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: "10px" }}
          >
            <Grid item>
              <Typography
                className={global_s.defaultText}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#4FACCF",
                }}
              >
                {"자주 사용한 금칙어 단어"}
              </Typography>
            </Grid>
            <Grid item xs container justify="flex-end" alignItems="center">
              <Grid item xs style={{ textAlign: "right" }}>
                <OutlinedInput1
                  value={bannedSearch}
                  onChange={handleBannedSearch}
                  placeholder={"조회"}
                  style={{
                    padding: "5px",
                    maxWidth: "250px",
                    minWidth: "200px",
                    backgroundColor: "white",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {bannedSearch.length > 0 ? ( // 입력 값의 길이에 따른 표시
                        <IconButton1 // 입력 값의 길이가 0보다 크다.
                          size="small"
                          onClick={handleBannedSearchClear}
                        >
                          <CloseIcon
                            className={contents_s.intputIcon}
                            style={{ color: "#a9a9a9" }}
                          />
                        </IconButton1>
                      ) : (
                        <SearchIcon // 입력 값의 길이가 0보다 작거나 같다.
                          className={contents_s.intputIcon}
                          style={{ color: "#a9a9a9" }}
                        />
                      )}
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid
              item
              container
              justify="space-between"
              style={{ padding: "0px 10px 10px" }}
            >
              <Grid item style={{ height: "220px", width: "100%" }}>
                <div
                  className="ag-theme-alpine"
                  style={{ height: "100%", width: "100%" }}
                >
                  <AgGridReact
                    headerHeight={36}
                    rowData={bannedRows}
                    suppressMovableColumns={true} // columns 이동 금지
                    overlayLoadingTemplate={"<span></span>"} // 데이터 로딩시 표시 화면
                    overlayNoRowsTemplate={"<span></span>"} // 데이터 없을 경우 표시 화면
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      cellClass: global_s.defaultGridCell,
                      headerClass: global_s.defaultGridHeader,
                    }}
                    onGridReady={onBannedGridReady}
                  >
                    {bannedColumnDef.map((columnItem) => {
                      return (
                        <AgGridColumn
                          key={columnItem.field}
                          headerName={columnItem.headerName}
                          field={columnItem.field}
                          hide={columnItem.hide}
                          width={columnItem.width}
                          tooltipField={columnItem.tooltipField}
                          sortable={columnItem.sortable}
                          resizable={columnItem.resizable}
                          checkboxSelection={columnItem.checkboxSelection}
                          cellRenderer={columnItem.cellRenderer}
                        />
                      );
                    })}
                  </AgGridReact>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="row" justify="flex-start">
        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          style={{
            padding: "10px",
            marginRight: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            borderRadius: "15px 15px 0px 0px",
          }}
        >
          <Grid item>
            <Typography
              className={global_s.defaultText}
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#4FACCF",
              }}
            >
              {"등록 글 정보"}
            </Typography>
          </Grid>
          <Grid item xs container justify="flex-end" alignItems="center">
            <Grid item xs style={{ textAlign: "right" }}>
              <OutlinedInput1
                value={contentsSearch}
                onChange={handleContentsSearch}
                placeholder={"조회"}
                style={{
                  padding: "5px",
                  maxWidth: "250px",
                  minWidth: "200px",
                  backgroundColor: "white",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {contentsSearch.length > 0 ? (
                      <IconButton1
                        size="small"
                        onClick={handleContentsSearchClear}
                      >
                        <CloseIcon
                          className={contents_s.intputIcon}
                          style={{ color: "#a9a9a9" }}
                        />
                      </IconButton1>
                    ) : (
                      <SearchIcon
                        className={contents_s.intputIcon}
                        style={{ color: "#a9a9a9" }}
                      />
                    )}
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          style={{
            padding: "0px 10px 10px",
            height: "365px",
            marginRight: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            borderRadius: "0px 0px 15px 15px",
          }}
        >
          <div
            className="ag-theme-alpine"
            style={{ height: "100%", width: "100%" }}
          >
            <AgGridReact
              headerHeight={36}
              rowData={contentsRows}
              rowStyle={{ cursor: "pointer" }}
              suppressMovableColumns={true} // columns 이동 금지
              overlayLoadingTemplate={"<span></span>"} // 데이터 로딩시 표시 화면
              overlayNoRowsTemplate={"<span></span>"} // 데이터 없을 경우 표시 화면
              defaultColDef={{
                sortable: true,
                resizable: true,
                cellClass: global_s.defaultGridCell,
                headerClass: global_s.defaultGridHeader,
              }}
              onGridReady={onContentsGridReady}
              onRowDoubleClicked={onRowDoubleClicked}
            >
              {contentsColumnDef.map((columnItem) => {
                return (
                  <AgGridColumn
                    key={columnItem.field}
                    headerName={columnItem.headerName}
                    field={columnItem.field}
                    hide={columnItem.hide}
                    width={columnItem.width}
                    tooltipField={columnItem.tooltipField}
                    sortable={columnItem.sortable}
                    resizable={columnItem.resizable}
                    checkboxSelection={columnItem.checkboxSelection}
                    cellRenderer={columnItem.cellRenderer}
                  />
                );
              })}
            </AgGridReact>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Detail;
